import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import '../public/styles/nav.css'
import Connexion from './Connexion'

//REDUX
import { useDispatch, useSelector } from 'react-redux'

//ANTD______________
import {
    CaretDownOutlined,
    CaretRightOutlined,
    LoadingOutlined
} from '@ant-design/icons';

const Nav = ({ actualisation, ready, setReady, isDesktop, setIsDesktop }) => {
    //console.log({readyInNav: ready})

    const logoMorbihan = "https://res.cloudinary.com/asterion/image/upload/v1680002923/Logo_D%C3%A9partement_Morbihan_-_2022.svg_ja7wgk.png"
    const logoHDF = "https://res.cloudinary.com/asterion/image/upload/v1697612302/hautsdefrance_btrvsj.png"

    const testSize = () => {
        //console.log('RESIZED - TAILLE H x L : ', window.innerHeight, window.innerWidth)    
        // if ( window.innerHeight < 941 || window.innerWidth < 1892 ) { setIsDesktop(false) }
        if ( window.innerHeight >= 941 && window.innerWidth >= 1892 ) { setIsDesktop(true) }
        }
        

    useEffect(()=>{
        
        testSize()
    
         window.addEventListener('resize', testSize);
        
         return () => { window.removeEventListener('resize', testSize) };
            
    },[])
    
    const dispatch = useDispatch()
    const user = useSelector (({ user })=> user)

    const [clientSelected, setClientSelected] = useState(useSelector(({ clientSelected }) => clientSelected))
    //useEffect(()=>{console.log({clientSelectedInSuivi: clientSelected})}, [clientSelected])
    const [clients, setClients] = useState([])

    const [showConnexion, setShowConnexion] = useState(false)

    const [errors, setErrors] = useState([])

//    useEffect(() => {console.log('userInConnexion : ', user)}, [user])

    const toggleShowConnexion = () => {
        setShowConnexion(!showConnexion)
        setErrors([])
    }

    return (
        <>
            <div className="band">
            {clientSelected ?
                <Link className='linkh' to={'/true'} onClick={()=>dispatch({type: 'selectstate', state: null})}> {/* true deleted*/}
                    <div className='bubble'></div>
                    <img src={clientSelected?.logo} style={{
                        zIndex: 4, padding: 10, position: 'absolute',
                        transform: clientSelected.logo === logoHDF ? 'scale(1.4)' : 'scale(1)',
                        left: clientSelected.logo === logoMorbihan ? 60 : clientSelected.logo === logoHDF ? 60 : 10, top: clientSelected.logo === logoMorbihan ? -10 : 0, maxHeight: clientSelected.logo === logoMorbihan ? '12vh' : '10vh', maxWidth: '20vw' 
                        }}/>
                </Link>
                :
                <div></div>
                }
            {errors?.length > 0 ? <span style={{color: '#FFF', position: 'absolute', right: 400, top: 13}}>{errors}</span> : <></>}
            {user ?
                <>
                {!ready &&
                    <span className="menuitem" style={{marginLeft: '-29%'}}>Chargement des données <LoadingOutlined/></span>
                }
                <span className="menuitem" onClick={()=>toggleShowConnexion()}>
                <img className='avatar' src={user.avatar}/>
                    {user ? `${user.firstname} ${user.lastname}` : 'Connexion'}
                {showConnexion ? <CaretRightOutlined onClick={()=>toggleShowConnexion()} style={{marginLeft: 10}}/> : <CaretDownOutlined style={{marginLeft: 10}} onClick={()=>toggleShowConnexion()}/>}
                </span>
                </>
            :
                <span className="menuitem" onClick={()=>toggleShowConnexion()}>Connexion</span>
            }


            </div>
            
            <Connexion errors={errors} setErrors={setErrors} actualisation={actualisation} ready={ready} setReady={setReady} clients={clients} setClients={setClients} setClientSelected={setClientSelected} toggleShowConnexion={toggleShowConnexion} showConnexion={showConnexion}/>
        </>    

    )
    

}

export default Nav;