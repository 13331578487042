import React, { useState, useEffect } from 'react'
import '../public/styles/informations.css'

//moment
import moment from "moment/min/moment-with-locales"

//ant_____________________
import {


} from 'antd';

import {
    AuditOutlined,
    AimOutlined,
    PushpinOutlined,
    BookOutlined,
    FlagFilled,
    EyeOutlined
} from '@ant-design/icons';
import Score from './Score';
import ScoreRapport from './ScoreRapport';
import ScoreDots from './ScoreDots';
import ScoreRapportDots from './ScoreRapportDots';
import ScoreSliderParametrable from './ScoreSliderParametrable'
import ScoreSliderRapportParametrable from './ScoreSliderRapportParametrable'
import ScoreDotsParametrable from './ScoreDotsParametrable'

import { formatCodeNew, short } from "../helpers"
import { scoreTotal } from "../helpers"

moment.locale('fr')

const Actions = ({ user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected }) => {

    const [reco, setReco] = useState(null) // dernière reco trouvée

    useEffect(() => {

        const findMaitriseConstat = async () => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/get-audit-result/${stateSelected._id}`)
            const json = await data.json()
            if (json.result) {
                console.log(json.reco)
                setReco(json.reco)
            }
        }

        if (stateSelected) { findMaitriseConstat() }

    }, [stateSelected])

    //LISTENERS CHECK
    // useEffect(() => { if (user) { console.log('user in Informations : ', user) } }, [user])
    // useEffect(() => { if (clientSelected) { console.log('clientSelected in Informations : ', clientSelected) } }, [clientSelected])
    // useEffect(() => { if (listFollowed) { console.log('listFollowed in Informations : ', listFollowed) } }, [listFollowed])
    // useEffect(() => { if (listByRisks) { console.log('listByRisks in Informations : ', listByRisks) } }, [listByRisks])
    // useEffect(() => { if (listByProcesses) { console.log('listByProcesses in Informations : ', listByProcesses) } }, [listByProcesses])
    // useEffect(() => { if (stateSelected) { console.log('stateSelected in Informations : ', stateSelected) } }, [stateSelected])

    return (
        <>
            {!stateSelected &&
                <div className='containersuivi' >
                    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>Sélectionnez un risque pour l'afficher</h1>
                    </div>
                </div>
            }
            {stateSelected &&
                <>
                    <div className='containersuivi'>

                        {clientSelected?.methodo_le_ray && clientSelected?.score && stateSelected?.calcul &&
                            <>
                                <div title={`Score`} style={{ zIndex: 2, cursor: 'pointer', position: 'absolute', top: '17%', left: '19%', width: 50, height: 50, borderRadius: 3, color: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FFF' : '#222', backgroundColor: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 500 ? '#7E005D' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 50 ? '#C80094' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FF0DC0' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 2 ? '#FF9BE5' : '#FFD9F5', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 'bold' }}>
                                    {scoreTotal(clientSelected?.score, stateSelected?.calcul)}
                                </div>
                                {stateSelected?.calcul?.confiance && <span style={{ zIndex: 1, position: 'absolute', top: '21.75%', left: '19.5%', fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: '#CCC', color: '#222', borderRadius: '50%' }}>{stateSelected?.calcul?.confiance}</span>}
                            </>
                        }

                        {/* BANDEAU 1 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <h2 title={stateSelected.intitule} style={{ margin: 0 }}>{!clientSelected?.level_more && formatCodeNew(stateSelected.process.libelle, stateSelected.risk?.family.libelle, stateSelected.risk.code)} - {short(stateSelected.intitule)}</h2>
                            {stateSelected.replaced_by &&
                                <span>{`Modifié le ${moment(stateSelected.date_iteration).format('LL')} par ${stateSelected.replaced_by.firstname} ${stateSelected.replaced_by.lastname}`}<img className='avatar-right' src={stateSelected.replaced_by.avatar} /></span>
                            }
                            {stateSelected.priority && 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '7%', padding: 3, borderRadius: 15, backgroundColor: 'pink', border: '2px solid red', position: 'absolute', top: '18%', right: '19%'}}>
                                    <span style={{fontWeight: 'bold'}}> Risque prioritaire </span>
                                </div>
                            }

                        </div>

                        {/* BANDEAU 2 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <div><span>{`Propriétaire : ${stateSelected?.proprietaire?.habilitations.find(e => e.client_hab === clientSelected._id)?.type_hab} - ${stateSelected?.proprietaire?.firstname} ${stateSelected?.proprietaire?.lastname}`}</span><img className='avatar-right' src={stateSelected?.proprietaire?.avatar} alt='proprietaireAvatar' /></div>
                            <div><span>{`Risque créé le ${moment(stateSelected.date_creation).format('LL')} par ${stateSelected.creator?.firstname} ${stateSelected.creator?.lastname}`}</span><img className='avatar-right' src={stateSelected.creator?.avatar} alt='userAvatar' /></div>

                        </div>

                        {/* DEUX COLONNES EN DESSOUS */}
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', height: '100%', width: '110%' }}>
                            {/* 1st bandeau prio + maitrise cible */}
                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '85%', height: '25%', maxHeight: '30%' }}>

                                {//div avec les 3 maîtrises présentes, si renseignées
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', margin: 'auto' }}>
                                        {stateSelected.lvl_maitrise && //stateSelected.lvl_maitrise &&
                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '10px 10px 10px 0px', fontWeight: 'bolder', fontSize: 16, padding: 20, borderRadius: 10, backgroundColor: '#EEE' }}>
                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                    <PushpinOutlined style={{ fontSize: 20, marginRight: 10 }} />
                                                    Maîtrise à date : {stateSelected.lvl_maitrise}
                                                </div>
                                            </div>
                                        }
                                        {reco?.maitrise_constat && //reco.maitrise_constat &&
                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', margin: 10, fontWeight: 'bolder', fontSize: 16, padding: 20, borderRadius: 10, backgroundColor: '#EEE' }}>
                                                {/* Link to audit dans Eye */}
                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                    <EyeOutlined style={{ fontSize: 20, marginRight: 10 }} />
                                                    Maîtrise constatée : {reco?.maitrise_constat}
                                                </div>
                                                {reco?.descr_action &&
                                                    <div style={{ padding: 10, margin: 'auto', maxHeight: '100%', height: '75%', maxWidth: '100%' }}>
                                                        <span>Recommandations</span>
                                                        <p style={{ overflowY: 'auto', height: '100%', fontWeight: 'normal', width: '100%' }}> {reco?.descr_action}</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {stateSelected.lvl_target_intern && //stateSelected.lvl_target_intern &&
                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '10px 0px 10px 10px', fontWeight: 'bolder', fontSize: 16, padding: 20, borderRadius: 10, backgroundColor: '#EEE' }}>
                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                    <AimOutlined style={{ fontSize: 20, marginRight: 10 }} />
                                                    Maîtrise cible : {stateSelected.lvl_target_intern}
                                                </div>
                                                {stateSelected.descr_attentes_intern &&
                                                    <div style={{ padding: 10, margin: 'auto', maxHeight: '100%', height: '75%', maxWidth: '100%' }}>
                                                        <span>Précision des attentes</span>
                                                        <p style={{ overflowY: 'auto', height: '100%', fontWeight: 'normal', width: '100%' }}> {stateSelected.descr_attentes_intern}</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }

                                {/* {stateSelected.lvl_target_intern &&
                                <>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%', width: '30%'}} >
                                        <div title='Niveau de maîtrise attendue par la gouvernance' style={{marginBottom: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', borderRadius: 50, border: '2px solid #C36F09', marginRight: 40}}>
                                            <span className='lib-score'>Maîtrise cible</span>
                                            <span className='score' >{stateSelected.lvl_target_intern}</span>
                                        </div>
                                        <div title='Niveau de maîtrise actuelle' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', borderRadius: 50, border: '2px solid #C36F09', marginRight: 40}}>
                                            <span className='lib-score'>Maîtrise actuelle</span>
                                            <span className='score' >{stateSelected.lvl_maitrise}</span>
                                        </div>
                                    </div>
                                        <div className='field' style={{marginRight: 40, maxHeight: '100%', height: '100%', maxWidth: '80%'}}>
                                            <span className='title-field'>Descriptif des attentes</span>
                                            <p className='content' style={{height: '100%', width: '100%'}}>{stateSelected.descr_attentes_intern}</p>
                                        </div>
                                </>
                                } */}
                                {/* {!stateSelected.lvl_target_intern &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', border: '2px solid #FEF375', padding: 30, borderRadius: 3 }}>
                                    <BookOutlined style={{fontSize: 30, marginRight: 20}}/>
                                    <h5>Pas encore de maîtrise cible définie sur ce risque</h5>
                                </div>
                                } */}

                                {/* {stateSelected?.priority &&
                                    <span style={{ fontSize: 14, padding: 12, borderRadius: 10, backgroundColor: stateSelected.priority ? '#EEE' : '#FFF', width: '30%', textAlign: 'center', fontWeight: 'bolder', color: stateSelected.priority ? '#333' : '#DDD' }}>PRIORITAIRE<FlagFilled style={{ marginLeft: 10, fontSize: 20, color: stateSelected.priority ? 'red' : '#DDD' }} /></span>
                                } */}
                            </div>
                            {/* 2nd bandeau factuels */}
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '90%' }}>

                                <div style={{ marginTop: 15, height: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', backgroundColor: '#FFFEEE', border: '2px solid #FEF375' }}>
                                    <div className='subcontainersuivi' style={{ padding: 20, backgroundColor: '#FFFEEE' }}>

                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>

                                            <ScoreDotsParametrable
                                                value={stateSelected.crit}
                                                reverse={true}
                                                clientSelected={clientSelected}
                                            />

                                            <div title='Criticité' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '50%', borderRadius: 50 }}>
                                                <span>Criticité brute</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <span style={{ width: '150%', textAlign: 'center', fontWeight: 'bold' }}>Situation actuelle du risque</span>
                                        {/* <span >Maîtrise à date : {stateSelected.lvl_maitrise}</span> */}
                                    </div>
                                    <div className='subcontainersuivi' style={{ width: '40%', padding: 20, backgroundColor: '#FFFEEE' }}>

                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>

                                            <ScoreSliderParametrable
                                                value={stateSelected.vuln}
                                                reverse={true}
                                            />
                                            <div title='Vulnérabilité' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '50%', borderRadius: 50 }}>
                                                <span >Vulnérabilité</span>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* BANDEAU A 3 COLONNES DU BAS */}
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '90%' }}>

                                <div style={{ height: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', backgroundColor: '#FFFEEE', border: '2px solid #FEF375' }}>

                                    <div className='subcontainersuivi' style={{ padding: 20, backgroundColor: '#FFFEEE' }}>

                                        <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>

                                            <ScoreSliderRapportParametrable
                                                value={stateSelected.rapport}
                                                reverse={true}
                                            />
                                            <div title='Rapport gain/coût' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '70%', borderRadius: 50 }}>
                                                <span >Rapport gain · coût</span>
                                            </div>

                                        </div>

                                    </div>

                                    <span style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Indicateurs d'optimisation de la maîtrise</span>

                                    <div className='subcontainersuivi' style={{ padding: 20, backgroundColor: '#FFFEEE' }}>

                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#FFFEEE', borderRadius: 5 }}>

                                            <ScoreDotsParametrable
                                                value={stateSelected.prediction}
                                                reverse={false}
                                                clientSelected={clientSelected}
                                            />

                                            <div title='Maîtrise optimale' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '50%', borderRadius: 50 }}>
                                                <span >Maîtrise optimale</span>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default Actions;