import { FileOutlined, LoadingOutlined, PushpinOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react'
import '../public/styles/button.css'

const Button = ({user, iconPin, texte, onClick, saving, specif = '', styleAdded, iconLeft}) => {

    return (

        <>
            <div className={specif === 'light' ? 'but-light' : specif === 'cancel' ? 'but-cancel' : specif === 'dark' ? 'but-dark' : 'but'} style={{...styleAdded, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>onClick()}>
                {iconLeft && <FileOutlined style={{marginRight: 10}}/>}
                {texte}
                {iconPin && <PushpinOutlined style={{marginLeft: 10}}/>}
                {saving && <LoadingOutlined style={{marginLeft: 10}}/>}
            </div>
        </>
    )
    }
    export default Button;