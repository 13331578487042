import React, { useState, useEffect } from 'react'
import '../public/styles/sideinfos.css'

//REDUX_______________________
import { useDispatch, useSelector } from 'react-redux'

//ANTD
import {
    Input,
    Select,
    Upload,
    message
} from 'antd';

import {
    LeftOutlined,
    RightOutlined,
    SwapOutlined,
    InboxOutlined,
    CloseOutlined,
    ShrinkOutlined,
    ArrowsAltOutlined,
    VerticalRightOutlined,
    VerticalLeftOutlined,
    CaretRightOutlined,
    CaretLeftOutlined
} from '@ant-design/icons';
import Liste from './Liste';
import ListeDocs from './ListeDocs';

const SideInfos = ({ stateId, setStateId, statesWithReco, setStatesWithReco, auditSelected, setAuditSelected, setDocumentsUploaded, documentsUploaded, slotToSave, screen, side, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected }) => {
    // console.log({documentsUploaded})

    useEffect(()=>{console.log({slotToSave})}, [slotToSave])

    const planSelected = useSelector(({ planSelected }) => planSelected)
    const incidentSelected = useSelector(({ incidentSelected }) => incidentSelected)

    //setter saving to actualize data
    const [saving, setSaving] = useState(false)

    //setter toggle
    const [open, setOpen] = useState(false)

    //setter data
    const [data, setData] = useState([])
    useEffect(()=>{console.log({data})}, [data])

    //useEffect data
    useEffect(() => {
        const fetchPieces = async () => {

            const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/get-pieces/${stateSelected._id}/${slotToSave}` + `${screen === 'incidents' ? `/${incidentSelected?._id}` : screen === 'actions' ? `/${planSelected?._id}` : !screen ? '' : ''}`)
            const json = await data.json()

            if (json.result) {

                setData(json.pieces)
            }
        }
        if ((slotToSave === 'suivi' && stateSelected) || planSelected || incidentSelected) { fetchPieces() }

        const fetchPiecesAudit = async () => {

            const data = await fetch(`${process.env.REACT_APP_BACK_END}/audits/get-pieces/${auditSelected._id}/${slotToSave}`+`/${slotToSave === 'reco' ?  statesWithReco?.find(state => state._id === stateId)?.reco?._id : ''}`)
            const json = await data.json()

            if (json.result) {

                setData(json.pieces)
            }
        }
        if (slotToSave === 'reco' || slotToSave === 'audit') { fetchPiecesAudit() } //setData(statesWithReco?.find(state => state._id === stateId)?.reco?.supports_reco)} else if(slotToSave === 'audit') { setData(auditSelected?.supports_audit) }
    
    }, [saving, stateSelected, slotToSave, planSelected, incidentSelected])

    const deletePiece = async (piece) => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/delete-piece/${stateSelected._id}/${slotToSave}` + `${screen === 'incidents' ? `/${incidentSelected?._id}` : screen === 'actions' ? `/${planSelected?._id}` : !screen ? '' : ''}` + `/${piece}`)
        const json = await data.json()

        if (json.result) {
            setData(json.pieces)
        }
    }

    const deletePieceAudit = async (piece) => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/audits/delete-piece/${auditSelected._id}/${slotToSave}`+`/${slotToSave === 'reco' ?  statesWithReco?.find(state => state._id === stateId)?.reco?._id : ''}`+`/${piece}`)
        const json = await data.json()

        if (json.result) {
            setData(json.pieces)
        }
    }

    //Drag nd drop antd

    const { Dragger } = Upload;

    const props = {
        name: 'file',
        style: { padding: 10 },
        showUploadList: false,
        multiple: false,
        action: slotToSave === 'reco' || slotToSave === 'audit' ?
        `${process.env.REACT_APP_BACK_END}/upload-audit/${auditSelected?._id}/${slotToSave}`+`/${slotToSave === 'reco' ? statesWithReco?.find(state => state._id === stateId)?.reco?._id : ''}`
        :
        `${process.env.REACT_APP_BACK_END}/upload/${stateSelected?._id}/${slotToSave}` + `${screen === 'incidents' ? '/' + incidentSelected?._id : screen === 'actions' ? '/' + planSelected?._id : ''}`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (status === 'done' && info.file.response?.result === true) {
                //console.log({response: info.file.response})
                message.success(`${info.file.name} file uploaded successfully.`);
                //console.log({incidentSelected, planSelected})
                if (!stateSelected || (slotToSave === 'incident' && !incidentSelected) || (slotToSave === 'action' && !planSelected)) { setDocumentsUploaded([...documentsUploaded, { url: info.file.response.cloudResult, name: info.file.name }]); setData([...data, { url: info.file.response.cloudResult, name: info.file.name }]) }
                setSaving(!saving)
            } else if (status === 'error' || info.file.response?.result === false) {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            //console.log('Dropped files', e.dataTransfer.files);
        },
    };
    //end dragndrop

    //function toggleOpen
    const toggleOpen = () => {
        setOpen(!open)
    }


    return (
        <>

            <div className={`containersuivi-small-${side} panel${open ? '-open' : ''}`} style={{ zIndex: 1, boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)' }}>
                {open ?
                    <CaretLeftOutlined className={`languette${open ? '-open' : ''}`} style={{ borderRadius: '0px 10px 10px 0px', backgroundColor: '#FFF', opacity: 1, padding: 5, fontSize: 25, cursor: 'pointer', position: 'absolute', top: 42, right: -33 }} onClick={() => toggleOpen()} />
                    :
                    <CaretRightOutlined className={`languette${open ? '-open' : ''}`} style={{ borderRadius: '0px 10px 10px 0px', backgroundColor: '#FFF', opacity: 1, padding: 5, fontSize: 25, cursor: 'pointer', position: 'absolute', top: 42, right: -33 }} onClick={() => toggleOpen()} />
                }
                {screen === 'informations' &&
                    <>
                        <ListeDocs
                            data={data}
                            setData={setData}
                            screen={screen}
                            deletePiece={deletePiece}
                        />
                        {stateSelected ?
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon" style={{ maxWidth: '100%' }}>
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text" style={{ maxWidth: '100%' }}>Cliquer ou déposer</p>
                                <p className="ant-upload-hint" style={{ maxWidth: '100%' }}>
                                    pour importer un document
                                </p>
                            </Dragger>
                            :
                            <h4 style={{ margin: 50, textAlign: 'center' }} >Selectionner un risque pour y rattacher une pièce</h4>
                        }
                    </>
                }

                {screen === 'incidents' &&
                    <>
                        <ListeDocs
                            data={data}
                            screen={screen}
                            deletePiece={deletePiece}
                        />
                        {incidentSelected ?
                            <Dragger {...props} >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Cliquer ou déposer</p>
                                <p className="ant-upload-hint">
                                    pour importer un document
                                </p>
                            </Dragger>
                            :
                            <h4 style={{ margin: 50, textAlign: 'center' }}>Selectionner un incident pour y rattacher une pièce</h4>
                        }
                    </>
                }

                {screen === 'actions' &&
                    <>
                        <ListeDocs
                            data={data}
                            screen={screen}
                            deletePiece={deletePiece}
                        />
                        {planSelected ?
                            <Dragger {...props} style={{ padding: 10 }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Cliquer ou déposer</p>
                                <p className="ant-upload-hint">
                                    pour importer un document
                                </p>
                            </Dragger>
                            :
                            <h4 style={{ margin: 50, textAlign: 'center' }}>Selectionner un plan d'action pour y rattacher une pièce</h4>
                        }
                    </>
                }

                {screen === 'audit' &&
// Gérer le fonctionnement de l'upload et du fetch, selon audit ou reco, et en veillant à bon transfert des variables par suivi.
                    <>
                        <ListeDocs
                            slotToSave={slotToSave}
                            data={data}
                            screen={screen}
                            deletePiece={deletePiece}
                            deletePieceAudit={deletePieceAudit}
                        />
                        {(auditSelected || statesWithReco)?
                            <Dragger {...props} style={{ padding: 10 }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Cliquer ou déposer</p>
                                <p className="ant-upload-hint">
                                    pour importer un document
                                </p>
                            </Dragger>
                            :
                            <h4 style={{ margin: 50, textAlign: 'center' }}>Selectionner un audit ou un risque audité pour y rattacher un document</h4>
                        }
                    </>
                }

            </div>
        </>
    )
}
export default SideInfos;