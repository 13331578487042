import React, { useState, useEffect } from 'react'
import '../public/styles/sidelist.css'

//REDUX_______________________
import { useDispatch, useSelector } from 'react-redux'

//ANTD
import {
    Input,
    Select
} from 'antd';

import {
    FilterOutlined,
    SwapOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    LoadingOutlined
} from '@ant-design/icons';


const SideList = ({side, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected}) => {

    //redux
    const dispatch = useDispatch()

    //TOGGLE FAMILY VS PROCESS
    const [toggle, setToggle] = useState(true)

    //setter open
    const [open, setOpen] = useState(true)

    //filter by proprietaire
    const [filter, setFilter] = useState(false)
    const [listByRisksFiltered, setListByRisksFiltered] = useState([])
    const [listByProcessesFiltered, setListByProcessesFiltered] = useState([])

    //function toggleOpen
    const toggleOpen = () => {
    setOpen(!open)
}

    const toggleNum = (i) => {
        setToggle(i)
    }

    return (
        <>
            <div className={`containersuivi-small-${side} panellist${open ? '-open' : ''}`} style={{zIndex: 1, boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)'}}>

            {open?
                <CaretRightOutlined className={`languette${open ? '-open' : ''}`} style={{borderRadius: '10px 0px 0px 10px', backgroundColor: '#FFF', opacity: 1, padding: 5, fontSize: 25, cursor: 'pointer', position: 'absolute', top: 42, left: -33}} onClick={()=>toggleOpen()}/>
            :
                <CaretLeftOutlined className={`languette${open ? '-open' : ''}`} style={{borderRadius: '10px 0px 0px 10px', backgroundColor: '#FFF', opacity: 1, padding: 5, fontSize: 25, cursor: 'pointer', position: 'absolute', top: 42, left: -33}} onClick={()=>toggleOpen()}/>
            }

                    {true && // EN PREVISION D'UN CLIENT SOUHAITANT NE PAS VOIR LE SYSTEME DES PROCESS
                    <div id='type' style={{borderRadius: '5px 5px 5px 0px', padding: 10, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#554348'}} >

                        <div style={{width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} >    
                        {toggle &&
                            <span style={{fontWeight: 'bolder', color: '#FFF'}}>
                            Par familles de risques
                            </span>
                        }
                        {!toggle &&
                            <span style={{fontWeight: 'bolder', color: '#FFF'}}>
                            Par familles de process métier
                            </span>
                        }
                            <div>
                                <SwapOutlined title='Changer la disposition' style={{fontWeight: 'bold', color: '#FFF', paddingLeft: 10, cursor: 'pointer'}} onClick={()=>toggleNum(!toggle)}/>
                                <FilterOutlined title={filter ? 'Désactiver le filtre' : 'Activer le filtre'} style={{fontWeight: 'bold', marginLeft: 10, color: filter ? 'gray' : '#FFF', cursor: 'pointer'}} onClick={()=>setFilter(!filter)}/>
                            </div>
                        </div>

                         
                        {/* SELECTS POUR FILTRAGE FUTUR SI BESOIN
                        
                            {toggle &&
                        <Select value={familyRiskSelected} style={{ width: '80%', marginBottom: 30 }} onChange={(value)=>{setFamilyRiskSelected(value)}}>
                            {listByRisks &&
                                listByRisks.map((ris, i)=>{return <Option id={i} value={ris.family.id}>{ris.family.code} - {ris.family.libelle}</Option>})
                            }
                        </Select> 
                        }
                        {!toggle &&
                        <Select value={familyProcessSelected} style={{ width: '80%', marginBottom: 30 }} onChange={(value)=>{setFamilyProcessSelected(value)}}>
                            {listByProcesses &&
                                listByProcesses.map((pro, i)=>{return <Option id={i} value={pro.family.libelle}>{pro.family.libelle}</Option>})
                            }
                        </Select> 
                        } */}

                    </div>
                    }

                    <div className='containersuivi-scrollable'>
                        {toggle && listByRisks.length < 1 &&
                            <span style={{marginLeft: '20%'}}>Chargement de la liste <LoadingOutlined style={{marginLeft: 10, marginTop: 30}}/></span>
                        }
                        {!toggle && listByProcesses.length < 1 &&
                            <span style={{marginLeft: '20%'}}>Chargement de la liste <LoadingOutlined style={{marginLeft: 10, marginTop: 30}}/></span>
                        }
                        {toggle && listByRisks &&                               

                            listByRisks.map((e, i)=>{
                                let listRisks = e.family?.risks?.sort((a, b)=>{return a.code - b.code}).map((f, j)=>{
                                    let listStates = f.states?.sort((a, b)=>{return a.risk.code - b.risk.code}).map((g, k)=>{
                                        if(filter && clientSelected.type_hab === "Contribution métier") {
                                            if(g.proprietaire._id === user._id) {return <span key={k} onClick={()=>{setStateSelected(g); dispatch({ type: 'selectplan', plan: null }); dispatch({ type: 'selectincident', incident: null })}} className={stateSelected?._id === g._id ? 'tag-low-selected' : 'tag-low'}>{!clientSelected?.level_more && g.risk.code} · {g.intitule}</span>}
                                        } else {
                                        return <span key={k} onClick={()=>{setStateSelected(g); dispatch({ type: 'selectplan', plan: null }); dispatch({ type: 'selectincident', incident: null })}} className={stateSelected?._id === g._id ? 'tag-low-selected' : 'tag-low'}>{!clientSelected?.level_more && g.risk.code} · {g.intitule}</span>
                                        }
                                    })
                                return (    
                                    <div key={j} className='card'>
                                        {clientSelected?.level_more &&
                                            // f.states.some(k=>k.proprietaire._id === user._id) ?
                                            <span className='tag-middle'>{f.code} · {f.libelle}</span>
                                            // :
                                            // <></>
                                        }
                                        {listStates}
                                    </div>                                    
                                    )
                                })
                            if(filter && clientSelected.type_hab === "Contribution métier") {
                                if(e.family?.risks?.some(h=>h.states.some(k=>k.proprietaire._id === user._id)) ) {
                                    return (
                                        <div key={i} className='card'>
                                            <span className='tag-top'>{e.family.libelle}</span>
                                            {listRisks}    
                                        </div>
                                        )
                                   }
                                } else {
                                    return (
                                        <div key={i} className='card'>
                                            <span className='tag-top'>{e.family.libelle}</span>
                                            {listRisks}    
                                        </div>
                                        )   
                                }
                            })                        
                        }

                        {!toggle && listByProcesses &&                        

                            listByProcesses.map((e, i)=>{
                                let listProcesses = e.family?.processes?.map((f, j)=>{
                                    let listStates = f.states?.sort((a, b)=>{return a.risk.code - b.risk.code}).map((g, k)=>{
                                        if(filter && clientSelected.type_hab === "Contribution métier") {
                                            if(g.proprietaire._id === user._id) {return <span key={k} onClick={()=>{setStateSelected(g); dispatch({ type: 'selectplan', plan: null }); dispatch({ type: 'selectincident', incident: null })}} className={stateSelected?._id === g._id ? 'tag-low-selected' : 'tag-low'}>{g.risk.code} · {g.intitule}</span>}
                                        } else {
                                        return <span key={k} onClick={()=>{setStateSelected(g); dispatch({ type: 'selectplan', plan: null }); dispatch({ type: 'selectincident', incident: null })}} className={stateSelected?._id === g._id ? 'tag-low-selected' : 'tag-low'}>{g.risk.code} · {g.intitule}</span>
                                        }
                                    })
                                return (    
                                    <div key={j} className='card'>
                                        {true &&
                                        f.states.some(k=>k.proprietaire._id === user._id) ?
                                        <span className='tag-middle'>{f.code} · {f.libelle}</span>
                                        :
                                        <></>
                                        }
                                        {listStates}
                                    </div>                                    
                                    )
                                })
                            if(filter && clientSelected.type_hab === "Contribution métier") {
                                if(e.family?.processes?.some(h=>h.states.some(k=>k.proprietaire._id === user._id)) ) {
                                    return (
                                        <div key={i} className='card'>
                                            <span className='tag-top'>{e.family.libelle}</span>
                                            {listProcesses}    
                                        </div>
                                        )
                                   }
                                } else {
                                    return (
                                        <div key={i} className='card'>
                                            <span className='tag-top'>{e.family.libelle}</span>
                                            {listProcesses}    
                                        </div>
                                        )   
                                }
                                    })
                               
                        }

                    </div>
            </div>
        </>
    )
    }
    export default SideList;