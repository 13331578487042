import React, { useState, useEffect } from 'react'
import '../public/styles/farmer.css'
import FarmerGraph from './FarmerGraph';
import FarmerGraphRay from './FarmerGraphRay';

//ant_____________________
import {

    Select, Slider, Form

} from 'antd';
import { DatabaseTwoTone, FlagTwoTone } from '@ant-design/icons';


const Farmer = ({ toggleNum, setStateSelected, clientSelected, listFollowed, listByRisks, listByProcesses }) => {
    
    //antd
    const [form] = Form.useForm()
    const { Option } = Select;

    //setters
    const [filters, setFilters] = useState([])
    const [familyFilter, setFamilyFilter] = useState()
    const [processFilter, setProcessFilter] = useState()

    const [brut, setBrut] = useState(false)
    const [net, setNet] = useState(true)
    const [sliderBest, setSliderBest] = useState()
    //const [sliderWorst, setSliderWorst] = useState()

    const [dataSet, setDataSet] = useState(
        // [
        //     {
        //         lvl_proba: {brut: 3, net: 2},
        //         lvl_impact: {brut: 3, net: 2},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 1',
        //         objectif: 'Performance',
        //     },
        //     {
        //         lvl_proba: {brut: 3, net: 2},
        //         lvl_impact: {brut: 3, net: 2},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 2',
        //         objectif: 'Performance',
           
        //     },
        //     {
        //         lvl_proba: {brut: 3, net: 2},
        //         lvl_impact: {brut: 3, net: 2},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 3',
        //         objectif: 'Reporting'
        //     },
        //     {
        //         lvl_proba: {brut: 4, net: 2},
        //         lvl_impact: {brut: 4, net: 2},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 4',
        //         objectif: 'Reporting'
        //     },
        //     {
        //         lvl_proba: {brut: 4, net: 4},
        //         lvl_impact: {brut: 3, net: 3},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 5',
        //         objectif: 'Conformité',
        //     },
        //     {
        //         lvl_proba: {brut: 2, net: 2},
        //         lvl_impact: {brut: 3, net: 2},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 6',
        //         objectif: 'Performance',
           
        //     },
        //     {
        //         lvl_proba: {brut: 1, net: 1},
        //         lvl_impact: {brut: 1, net: 1},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 7',
        //         objectif: 'Reporting'
        //     },
        //     {
        //         lvl_proba: {brut: 6, net: 5},
        //         lvl_impact: {brut: 6, net: 5},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 8',
        //         objectif: 'Performance',
           
        //     },
        //     {
        //         lvl_proba: {brut: 6, net: 6},
        //         lvl_impact: {brut: 6, net: 6},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 9',
        //         objectif: 'Conformité',
           
        //     },
        //     {
        //         lvl_proba: {brut: 2, net: 2},
        //         lvl_impact: {brut: 2, net: 2},
        //         lvl_maitrise: 1,
        //         intitule: 'Risque 10',
        //         objectif: 'Reporting'
        //     },
        // ]
        listFollowed
    ) 

    //listeners
    // useEffect(()=>{console.log({listFollowed})}, [listFollowed])
    // useEffect(()=>{console.log({listByRisks})}, [listByRisks])
    // useEffect(()=>{console.log({listByProcesses})}, [listByProcesses])
    // useEffect(()=>{console.log({familyFilter, result: dataSet.filter(e=>e.risk?.family?.libelle === familyFilter)})}, [familyFilter])
    // useEffect(()=>{console.log({processFilter, result: dataSet.filter(e=>e.process?.libelle === processFilter)})}, [processFilter])

    //handle changes
    const handleChangeObjectif = (value) => { setFilters( {...filters, objectif: value} ) }
    const handleChangeFamily = (value) => { setFamilyFilter( value ) }
    const handleChangeProcess = (value) => { setProcessFilter( value ) }
    const handleChangeDomaine = (value) => { setFilters( {...filters, domaines_competence: value } ) }
    const handleChangeMode = (value) => { setFilters( {...filters, modes_intervention: value } ) }
    const onChangeSliderBest = (value) => { setSliderBest(value) }
    const handleChangeConfiance = (value) => setFilters( {...filters, confiance: value} )
    //const onChangeSliderWorst = (value) => { setSliderWorst(value) }

    const handleChangeDataSet = (value) => {
        setDataSet(value);
    }

return (
    <>

        <div id='reportFarmer' className='containersuivi' style={{flexDirection : 'row', justifyContent: 'center'}}>
                {/* Titre en dessous */}
                <h2 style={{position: 'absolute', top: '20%', left: '31%'}}>Identification des vulnérabilités de l'organisation</h2>
            {/* zone graphique */}
            <span style={{fontWeight: 'bolder', position: 'absolute', top: '21%', left: '21%'}}>Probabilité</span>
            <span style={{fontWeight: 'bolder', position: 'absolute', bottom: '16%', right: '37%'}}>Impact</span>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', transform: 'rotate(-90deg)', position: 'absolute', top: '51%', left: '10%', width: '24%'}}>
                <span style={{color: '#8ac926', fontWeight: 'bolder',}}>Faible</span>
                <span style={{color: '#ffb703', fontWeight: 'bolder',}}>Modérée</span>
                <span style={{color: 'orange', fontWeight: 'bolder',}}>Conséquente</span>
                <span style={{color: 'red', fontWeight: 'bolder',}}>Elevée</span>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: '16%', left: '27%', width: '31%'}}>
                <span style={{color: '#8ac926', fontWeight: 'bolder',}}>Faible</span>
                <span style={{color: '#ffb703', fontWeight: 'bolder',}}>Modéré</span>
                <span style={{color: 'orange', fontWeight: 'bolder',}}>Conséquent</span>
                <span style={{color: 'red', fontWeight: 'bolder',}}>Elevé</span>
            </div>

            <div style={{width: '65%', height: '95%'}}>

                {clientSelected?.methodo_le_ray ?
                    <FarmerGraphRay setStateSelected={setStateSelected} toggleNum={toggleNum} processFilter={processFilter} familyFilter={familyFilter} clientSelected={clientSelected} handleChangeDataSet={handleChangeDataSet} dataSet={dataSet} sliderBest={sliderBest} brut={brut} net={net} listFollowed={listFollowed} filters={filters}/>                
                    :
                    <FarmerGraph setStateSelected={setStateSelected} toggleNum={toggleNum} processFilter={processFilter} familyFilter={familyFilter} clientSelected={clientSelected} handleChangeDataSet={handleChangeDataSet} dataSet={dataSet} sliderBest={sliderBest} brut={brut} net={net} listFollowed={listFollowed} filters={filters}/>
                }
            </div>
        
            {/* zone filtres */}
            <div style={{zIndex: -1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginRight: '-5%', width: '30%', height: '95%'}}>

                    <div style={{backgroundColor: '#EEE', marginBottom: 10, width: '80%', display: 'flex', flexDirection: 'column', justifyContent:' center', alignItems: 'center'}}>
                        <span style={{marginTop: 5, textAlign: 'center', backgroundColor: '#EEE', width: '80%', borderRadius: 3, fontWeight: 'bold'}}>Filtres statistiques</span>
                        <div style={{width: '80%', display: 'flex', flexDirection: 'row', justifyContent:' center', alignItems: 'center'}}>
                            <span style={{height: '60%', lineHeight: '100%', width: '40%', maxWidth: '40%', minWidth: '15%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: brut ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: brut ? '#222' : '#CCC'}} onClick={()=>{ setNet(!net); setBrut(!brut) } }>{brut ? '' : ''} Brut</span>
                            <span style={{height: '60%', lineHeight: '100%', width: '40%', maxWidth: '40%', minWidth: '15%', cursor: 'pointer', margin: 10, padding: 10, borderRadius: 10, backgroundColor: net ? '#DDD' : '#FFF', textAlign: 'center', fontWeight: 'bolder', color: net ? '#222' : '#CCC'}} onClick={()=>{ setNet(!net); setBrut(!brut) } }>{net ? '' : ''} Net</span>
                        </div>
                    </div>

                    <div style={{backgroundColor: '#EEE', marginBottom: 10, width: '80%', display: 'flex', flexDirection: 'column', justifyContent:' center', alignItems: 'center'}}>
                        <span style={{height: '70%', lineHeight: '100%', width: '80%', maxWidth: '80%', minWidth: '15%', cursor: 'pointer', margin: 5, padding: 10, borderRadius: 10, backgroundColor: '#DDD', textAlign: 'center', fontWeight: 'bolder', color: '#222'}} onClick={()=>{handleChangeDataSet(listFollowed); onChangeSliderBest(listFollowed.length); setFilters([]); setProcessFilter(); setFamilyFilter(); form.resetFields(['family', 'process', 'objectif', 'domaine', 'name', 'confiance'])}}>Tous les risques</span>
                    </div>

                    <div style={{width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Exposition maximale : {dataSet.length} risque{dataSet.length > 1 ? 's' : ''}</span>
                        <Slider marks={{}} min={0} max={listFollowed.length} value={dataSet.length} defaultValue={dataSet.length} onChange={(e)=>{onChangeSliderBest(e)}}/>
                    </div>

                    {/* <div style={{width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Exposition minimale</span>
                        <Slider marks={{0: 0, 5: 5, 10: 10, 15: 15, 20:20}} min={0} max={20} defaultValue={dataSet.length} onChange={(e)=>{onChangeSliderWorst(e)}}/>
                    </div> */}

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Filtres métiers</span>

                    <Form form={form} style={{width: '100%'}}>
                        <Form.Item style={{margin: 0}} name='family'>
                            <Select /*mode="tags"*/ style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par famille de risques" onChange={handleChangeFamily}>
                                {listByRisks.map((e, i) => { return <Option key={e.family.libelle}>{e.family.libelle}</Option> })} 
                            </Select>
                        </Form.Item>

                        <Form.Item style={{margin: 0}} name='process'>
                            <Select /*mode="tags"*/ style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par processus métier" onChange={handleChangeProcess}>
                                {
                                listByProcesses.map((e, i) => { return e.family.processes }).flat().map((f, j)=>{return <Option key={f.libelle}>{f.libelle}</Option>})
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item style={{margin: 0}} name='objectif'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.objectif_activated ? false : true} maxTagCount={3} style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par objectif COSO" onChange={handleChangeObjectif}>
                                {['Performance', 'Conformité', 'Reporting'].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model and ManageRiskState*/}
                            </Select>
                        </Form.Item>
                        
                        <Form.Item style={{margin: 0}} name='mode'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.modes_activated ? false : true} style={{ width: '100%', marginBottom: 10 }} maxTagCount={3} placeholder="Filtrer par mode d'intervention" onChange={handleChangeMode}>
                                        {[
                                                'Octroi de subventions',
                                                'Remboursement de frais',
                                                'Attribution d’aides individuelles',
                                                'Passation de marchés',
                                                'Mise en place de régies',
                                                'Délégation de service public',
                                                'Octroi de garanties, prêts ou cautions',
                                                'Autre',
                                                `Tous modes`                                    
                                        ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model and ManageRiskState */}
                            </Select>
                        </Form.Item>

                        <Form.Item style={{margin: 0}} name='domaine'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.domaines_activated ? false : true} style={{ width: '100%', marginBottom: 10 }} maxTagCount={3} placeholder="Filtrer par domaine de compétence" onChange={handleChangeDomaine}>
                                        {[
                                                
                                                'Éducation et formation',
                                                'Voirie',
                                                'Social',
                                                'Culture',
                                                'Sport',
                                                'Aménagement du territoire',
                                                'Ruralité',
                                                'Environnement et développement durable',
                                                'Patrimoine',
                                                'Économie',
                                                'Transports',
                                                'Eau et assainissement',
                                                'Autre',
                                                'Tous domaines'
                                        ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                            </Select>
                        </Form.Item>
{clientSelected.methodo_le_ray &&
                        <Form.Item style={{margin: 0}} name='confiance'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.type_hab === 'Risk management' ? false : true} style={{ width: '100%', marginBottom: 10 }} maxTagCount={1} placeholder="Filtrer par indice de confiance" onChange={handleChangeConfiance}>
                                        {[
                                            
                                            'A',
                                            'B',
                                            'C'
                                        ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                            </Select>
                        </Form.Item>
}
                    </Form>
                </div>

                <span style={{fontWeight: 'bold', textAlign: 'center', width: '100%'}}>{dataSet.length} risque{dataSet.length > 1 ? 's' : ''} affiché{dataSet.length > 1 ? 's' : ''}</span>

            </div>


        </div>
    </>
)
}
export default Farmer;