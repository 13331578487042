import React, { useEffect, useState } from 'react'
import '../public/styles/informations.css'

//moment
import moment from "moment/min/moment-with-locales"

//ant_____________________
import {
    message

} from 'antd';

import {
    AimOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
} from '@ant-design/icons';

import Liste from './Liste';
import Button from './Button';
import { Link } from 'react-router-dom';

//REDUX___________________________________________________
import { useDispatch, useSelector } from 'react-redux'

import { formatCodeNew, short, scoreTotal } from "../helpers"

moment.locale('fr')


const Actions = ({ user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected }) => {

    //redux
    const dispatch = useDispatch()

    const planSelected = useSelector(({ planSelected }) => planSelected)

    const successClose = () => {
        message.success(`Plan d'action clôturé 😇`);
    };

    const [planCreator, setPlanCreator] = useState()

    //LISTENERS CHECK
    // useEffect(() => { if (user) { console.log('user in Informations : ', user) } }, [user])
    useEffect(() => { if (clientSelected) { console.log('clientSelected in Informations : ', clientSelected) } }, [clientSelected])
    // useEffect(() => { if (listFollowed) { console.log('listFollowed in Informations : ', listFollowed) } }, [listFollowed])
    // useEffect(() => { if (listByRisks) { console.log('listByRisks in Informations : ', listByRisks) } }, [listByRisks])
    // useEffect(() => { if (listByProcesses) { console.log('listByProcesses in Informations : ', listByProcesses) } }, [listByProcesses])
    // useEffect(() => { if (stateSelected) { console.log('stateSelected in Informations : ', stateSelected) } }, [stateSelected])
    // useEffect(() => { if (planCreator) { console.log('planCreator in Informations : ', planCreator) } }, [planCreator])
    // useEffect(() => { if (planSelected) { console.log('planSelected in Actions : ', planSelected) } }, [planSelected])


    useEffect(() => {
        const fetchActionCreator = async () => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/get-action-creator/${stateSelected._id}/${planSelected._id}`)
            const json = await data.json()
            if (json.result) {
                setPlanCreator(json.creator[0]?.action?.creator)
            }
        }
        if (planSelected) { fetchActionCreator() }
    }, [planSelected])

    const handleCloseAction = async () => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/close-action`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ stateId: stateSelected?._id, actionId: planSelected._id, dateClosure: moment() })
        })
        const json = await data.json()

        if (json.result) {
            await successClose()
            dispatch({ type: 'selectplan', plan: null })
        }
    }

    return (
        <>
            {!stateSelected &&
                <div className='containersuivi' >
                    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>Sélectionnez un risque pour l'afficher</h1>
                    </div>
                </div>
            }
            {stateSelected &&
                <>
                    <div className='containersuivi' >

                        {clientSelected?.methodo_le_ray && clientSelected?.score && stateSelected?.calcul &&
                            <>
                                <div title={`Score`} style={{ zIndex: 2, cursor: 'pointer', position: 'absolute', top: '17%', left: '19%', width: 50, height: 50, borderRadius: 3, color: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FFF' : '#222', backgroundColor: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 500 ? '#7E005D' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 50 ? '#C80094' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FF0DC0' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 2 ? '#FF9BE5' : '#FFD9F5', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 'bold' }}>
                                    {scoreTotal(clientSelected?.score, stateSelected?.calcul)}
                                </div>
                                {stateSelected?.calcul?.confiance && <span style={{ zIndex: 1, position: 'absolute', top: '21.75%', left: '19.5%', fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: '#CCC', color: '#222', borderRadius: '50%' }}>{stateSelected?.calcul?.confiance}</span>}
                            </>
                        }

                        {/* BANDEAU 1 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <h2 title={stateSelected.intitule} style={{ margin: 0 }}>{!clientSelected?.level_more && formatCodeNew(stateSelected.process.libelle, stateSelected.risk?.family.libelle, stateSelected.risk.code)} - {short(stateSelected.intitule)}</h2>
                            {stateSelected.replaced_by &&
                                <span>{`Modifié le ${moment(stateSelected.date_iteration).format('LL')} par ${stateSelected.replaced_by.firstname} ${stateSelected.replaced_by.lastname}`}<img alt='avatar' className='avatar-right' src={stateSelected.replaced_by.avatar} /></span>
                            }
                            {stateSelected.priority &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '7%', padding: 3, borderRadius: 15, backgroundColor: 'pink', border: '2px solid red', position: 'absolute', top: '18%', right: '19%' }}>
                                    <span style={{ fontWeight: 'bold' }}> Risque prioritaire </span>
                                </div>
                            }

                        </div>

                        {/* BANDEAU 2 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <div><span>{`Propriétaire : ${stateSelected?.proprietaire?.habilitations.find(e => e.client_hab === clientSelected._id)?.type_hab} - ${stateSelected?.proprietaire?.firstname} ${stateSelected.proprietaire?.lastname}`}</span><img className='avatar-right' src={stateSelected.proprietaire?.avatar} alt='proprietaireAvatar' /></div>
                            <div><span>{`Risque créé le ${moment(stateSelected?.date_creation).format('LL')} par ${stateSelected?.creator?.firstname} ${stateSelected?.creator?.lastname}`}</span><img className='avatar-right' src={stateSelected?.creator?.avatar} alt='userAvatar' /></div>

                        </div>

                        <div style={{ width: '88%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {stateSelected.lvl_target_intern &&
                                <div title={`Delta d'évolution du risque à opérer`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '40%', borderRadius: 50, border: '2px solid #C36F09', margin: '20px 0px' }}>
                                    <span className='lib-score' style={{ width: '150%' }}>Ecart entre maitrise cible et maîtrise actuelle</span>
                                    <span className='score'>{Math.round((stateSelected.lvl_target_intern - stateSelected.lvl_maitrise) * 10) / 10}
                                        {stateSelected.lvl_target_intern - stateSelected.lvl_maitrise > 0 ?
                                            <ArrowUpOutlined style={{ marginLeft: 15, fontSize: 15, color: 'red' }} />
                                            :
                                            <ArrowDownOutlined style={{ marginLeft: 15, fontSize: 15, color: 'green' }} />
                                        }
                                    </span>
                                </div>
                            }
                            {!stateSelected.lvl_target_intern &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#FFE', border: '0px solid #FEF375', padding: 10, margin: 20, borderRadius: 3 }}>
                                    <AimOutlined style={{ fontSize: 20, marginRight: 20 }} />
                                    <h5>Pas de maîtrise cible définie sur ce risque</h5>
                                    <AimOutlined style={{ fontSize: 20, marginLeft: 20 }} />
                                </div>
                            }

                        </div>

                        {/* DEUX COLONNES EN DESSOUS */}

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', height: '68%', width: '90%', marginBottom: 20 }}>

                            {/* COLONNE DE GAUCHE */}
                            <div className='subcontainersuivi' style={{ margin: 0, height: '95%', width: '30%' }}>
                                <Liste
                                    stateSelected={stateSelected}
                                    data={stateSelected.action}
                                    dataTitle={`Plans d'action`}
                                    typeOfList={'actions'}
                                />
                            </div>

                            {/* COLONNE DE DROITE */}
                            <div className='subcontainersuivi' style={{ justifyContent: 'center', borderRadius: 2, backgroundColor: '#FFF', border: '1px solid #726468', flexDirection: 'row', margin: 0, height: '86%', width: '70%' }}>

                                {planSelected &&
                                    <>
                                        <div className='subcontainersuivi' style={{ backgroundColor: 'rgba(0,0,0,0)', margin: 0, height: '95%', width: '85%', justifyContent: 'space-between' }}>

                                            <div style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '90%', padding: 0 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div>
                                                        <h2 style={{ margin: 0 }}>{planSelected?.objet}</h2>
                                                        <span style={{ margin: 0, marginBottom: 5 }}>{`enregistré par ${planCreator?.firstname} ${planCreator?.lastname}`}<img className='avatar-right' src={planCreator?.avatar} alt='creatorAvatar' /></span>
                                                    </div>
                                                    <div style={{ maxWidth: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', width: '60%', margin: '10px 0px' }}>
                                                        <span className='lib-score' >Maitrise cible</span>
                                                        <span className='score' >{planSelected?.maitrise_cible}</span>
                                                    </div>
                                                </div>
                                                <p style={{ backgroundColor: '#FFF', padding: 5, textAlign: 'justify', margin: '10px 0px 0px 0px', width: '120%', height: '75%', overflowY: 'auto', borderTop: '1px solid #726468', borderBottom: '1px solid #726468' }}>{planSelected?.descr_action}</p>
                                            </div>

                                            <div style={{ width: '35%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: '14.5%', right: '24%' }}>
                                                <div className='subcontainersuivi' style={{ width: '100%', flexDirection: 'column', justifyContent: 'space-evenly', backgroundColor: 'rgba(0,0,0,0)', margin: 0, height: '95%', alignItems: 'flex-start' }}>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Date de début : <span style={{ fontWeight: 'normal', color: '#222' }}>{moment(planSelected?.date_debut).format('LL')}</span></span>
                                                        <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Date de fin : <span style={{ fontWeight: 'normal', color: '#222' }}>{moment(planSelected?.date_fin).format('LL')}</span></span>
                                                        <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Origine : <span style={{ fontWeight: 'normal', color: '#222' }}>{planSelected?.origine}</span></span>
                                                    </div>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Services associés : <span style={{ fontWeight: 'normal', color: '#222' }}>{planSelected?.services_associes?.length > 0 ? planSelected?.services_associes?.map((e, i) => { return i === planSelected?.services_associes.length - 1 ? `${e}.` : `${e}, ` }) : 'Aucun service associé'}</span></span>
                                                    </div>
                                                </div>
                                                <div className='subcontainersuivi' style={{ backgroundColor: 'transparent' }}>
                                                    {!planSelected?.date_closure && stateSelected.proprietaire !== user._id ?
                                                        <Button texte={`Clôturer ce plan d'action`} onClick={() => { handleCloseAction() }} styleAdded={{ width: '60%' }} />
                                                        :
                                                        <h3 style={{}}>{`Plan d'action clos le ${moment(planSelected?.date_closure).format('LL')}`}</h3>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                }
                                {!planSelected &&
                                    <div style={{ maxHeight: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90%', width: '90%', padding: 20 }}>
                                        <h3>{stateSelected?.action.length > 0 ? `Sélectionner un plan d'action pour le consulter` : `Aucun plan d'action renseigné pour le moment`}</h3>
                                    </div>
                                }

                            </div>
                            <div style={{ width: '15%', position: 'absolute', left: '24%', bottom: '16%' }}><Link className='link-dark' to={`/create/action`}> Nouveau plan d'action </Link></div>
                        </div>

                    </div>
                </>
            }
        </>
    )
}
export default Actions;