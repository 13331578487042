import React, { useState, useEffect } from 'react'
import '../public/styles/additem.css'

import AvatarUpload from '../components/AvatarUpload'
import moment from "moment/min/moment-with-locales"

//REDUX
import { useDispatch, useSelector } from 'react-redux'

//ant_____________________
import {
    message,
    Input,
    Select,
    Form
} from 'antd';

import {
    CloseOutlined
} from '@ant-design/icons';
import Button from './Button';

moment.locale('fr')

const { Option } = Select

const AddItem = ({actualisation, setActualisation, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected, item, panelOpen, setPanelOpen}) => {

    const [form] = Form.useForm()

//setters
    //toggle between family nd risk
    const [toggle, setToggle] = useState(0)
    //toggle datas to save
    const [current, setCurrent] = useState({owner: clientSelected?._id})
    //families to display in select in addRisk
    const [families, setFamilies] = useState([])

//LISTENERS CHECK
    // useEffect(()=>{if (user) {console.log('user in AddItem : ', user)}}, [user])
    // useEffect(()=>{if (clientSelected) {console.log('clientSelected in AddItem : ', clientSelected)}}, [clientSelected])
    // useEffect(()=>{if (listFollowed) {console.log('listFollowed in AddItem : ', listFollowed)}}, [listFollowed])
    // useEffect(()=>{if (listByRisks) {console.log('listByRisks in AddItem : ', listByRisks)}}, [listByRisks])
    // useEffect(()=>{if (listByProcesses) {console.log('listByProcesses in AddItem : ', listByProcesses)}}, [listByProcesses])
    // useEffect(()=>{if (stateSelected) {console.log('stateSelected in AddItem : ', stateSelected)}}, [stateSelected])

    useEffect(()=>{if (current) {console.log('current in AddItem : ', current)}}, [current])

    const successProcess = () => {
        message.success('Processus métier sauvegardé avec succès 👍 ');
      };

      const successFamily = () => {
        message.success('Famille de risque sauvegardée avec succès 👍 ');
      };

      const successRisk = () => {
        message.success('Risque sauvegardé avec succès 👍 ');
      };

    const toggleNum = (i) => {
        setToggle(i)
        setCurrent({owner: clientSelected._id})
    }



//useEffect fetchFamilies and fill current.owner
    useEffect(()=>{
        const fetchFamilies = async () => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/families/get-families/${clientSelected._id}`)
            const json = await data.json()
                if(json.result) {
                    //console.log(json.familiesFound)
                    setFamilies(json.familiesFound)
                }
        }
        if (clientSelected) {
            fetchFamilies()
            setCurrent({...current, owner: clientSelected._id})
        }
    }, [clientSelected, actualisation])

    function onChangeFamily(value) { setCurrent({ ...current, process_family: value }) }
    function handleChangeLibelle(e) { setCurrent({ ...current, libelle: e.target.value }) }

    const handleSaveProcess = async () => {

        const data = await fetch(`${process.env.REACT_APP_BACK_END}/processes/new-process`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ processToSave: current })
        })
        const json = await data.json()

        if (json.result) {
            await successProcess()
            // console.log(json.savedProcess)         
            // console.log('SAVED')
            setPanelOpen(false)
            setCurrent({owner: clientSelected._id})
            setActualisation(!actualisation)
        }

    }

    const handleSaveFamily = async () => {

        const data = await fetch(`${process.env.REACT_APP_BACK_END}/families/new-family`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ familyToSave: current })
        })
        const json = await data.json()

        if (json.result) {
            await successFamily()
            // console.log(json.savedFamily)         
            // console.log('SAVED')
            setPanelOpen(false)
            setCurrent({owner: clientSelected._id})
            setActualisation(!actualisation)
        }

    }

    const handleSaveRisk = async () => {

        const data = await fetch(`${process.env.REACT_APP_BACK_END}/risks/new-risk`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ riskToSave: current })
        })
        const json = await data.json()

        if (json.result) {
            await successRisk()
            // console.log(json.savedRisk)         
            // console.log('SAVED')
            setPanelOpen(false)
            setCurrent({owner: clientSelected._id})
            setActualisation(!actualisation)
        }

    }

    return (
        <>            
            <div className={`panelitem${panelOpen ? '-open' : ''}`}>
                {item === 'process' &&
                    <>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', width: '100%', padding: 8, backgroundColor: '#EDB30D', color: '#FFF', fontWeight: 'bold'}}>
                        Ajouter un processus métier
                        <CloseOutlined style={{color: '#FFF'}} onClick={()=>{setPanelOpen(!panelOpen)}}/>
                    </div>
                    
                    <div style={{height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Form style={{width: '80%', alignSelf: 'center'}} form={form}>
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Sélectionner une famille de processus métier"
                            name="Famille"
                            label="Famille de processus métier"
                            hasFeedback
                            validateStatus={current.process_family ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Sélectionner ou rechercher..."
                                optionFilterProp="children"
                                onChange={onChangeFamily}
                            >
                                {
                                    ['Gouvernance', 'Réalisation', 'Support'].map((e, i) => {
                                        return <Option key={i} value={e}>{e}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Libelle du processus métier"
                            name="Libelle du processus métier"
                            label="Libellé du processus métier"
                            hasFeedback
                            validateStatus={current.libelle ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                    whitespace: true
                                },
                            ]}
                        >
                            <Input placeholder={current.libelle} value={current.libelle} onChange={handleChangeLibelle} />
                        </Form.Item>
                    </Form>
                        <div style={{ width: '10vw', marginTop: '5%' }}><Button texte={'Enregistrer'} onClick={() => { handleSaveProcess(); form.resetFields() }} /></div>
                    
                    </div>

                    </>
                }
                {item === 'risk' &&
                    <>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', width: '100%', padding: 8, backgroundColor: '#EDB30D', color: '#FFF', fontWeight: 'bold'}}>
                        Ajouter un risque ou une famille de risque
                        <CloseOutlined style={{color: '#FFF'}} onClick={()=>{setPanelOpen(!panelOpen)}}/>
                    </div>

                    <div style={{height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>


                        <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', margin: '20px 0px', }}>
                            <span onClick={()=>toggleNum(0)} style={{cursor: 'pointer', backgroundColor: toggle === 0 ? '#EDB30D' : '#FFF', color: toggle === 0 ? '#FFF' : '#EDB30D', width: '60%', padding: '2px 10px', border: '1px solid #EDB30D'}}>Famille de risque</span>
                            <span onClick={()=>toggleNum(1)} style={{cursor: 'pointer', backgroundColor: toggle === 1 ? '#EDB30D' : '#FFF', color: toggle === 1 ? '#FFF' : '#EDB30D', width: '40%', padding: '2px 10px', marginLeft: -1, border: '1px solid #EDB30D'}}>Risque</span>
                        </div>

                    {toggle === 0 &&
                        <>
                        <div style={{height: '50%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Form style={{width: '80%'}} form={form}>
                            <Form.Item
                                style={{ width: '100%' }}
                                tooltip="Libelle de la famille de risque"
                                name="Libelle de la famille de risque"
                                label="Libellé de la famille de risque"
                                hasFeedback
                                validateStatus={current.libelle ? "success" : ''}
                                help=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Désolé, obligatoire 😁',
                                        whitespace: true
                                    },
                                ]}
                            >
                                <Input placeholder={current.libelle} value={current.libelle} onChange={handleChangeLibelle} />
                            </Form.Item>
                        </Form>
                        </div>
                            <div style={{width: '10vw', marginTop: '5%' }}><Button texte={'Enregistrer'} onClick={() => { handleSaveFamily(); form.resetFields() }} /></div>
                        </>
                    }

                    {toggle === 1 &&

                    <>
                        <div style={{height: '50%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Form style={{width: '80%'}} form={form}>
                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Sélectionner une famille de risque"
                            name="Famille de risque"
                            label="Famille de risque"
                            hasFeedback
                            validateStatus={current.process_family ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Sélectionner ou rechercher..."
                                optionFilterProp="children"
                                onChange={onChangeFamily}
                            >
                                {
                                    families.map((e, i) => {
                                        return <Option key={i} value={e._id}>{e.code} - {e.libelle}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ width: '100%' }}
                            tooltip="Libelle du risque"
                            name="Libelle du risque"
                            label="Libellé du risque"
                            hasFeedback
                            validateStatus={current.libelle ? "success" : ''}
                            help=""
                            rules={[
                                {
                                    required: true,
                                    message: 'Désolé, obligatoire 😁',
                                    whitespace: true
                                },
                            ]}
                        >
                            <Input placeholder={current.libelle} value={current.libelle} onChange={handleChangeLibelle} />
                        </Form.Item>
                    </Form>
                        </div>
                        <div style={{ width: '10vw', marginTop: '5%' }}><Button texte={'Enregistrer'} onClick={() => { handleSaveRisk(); form.resetFields() }} /></div>
                    </>

                    }
                                        
                    </div>

                    </>
                }
            </div>        
        </>
    )
    }
    export default AddItem;