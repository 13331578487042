
const predictMaitriseCible = (proba, impact) => {
     
  let operation = Math.sqrt(proba * impact);
  
  const arr = (ope) => {return ((ope)*10)/10}
  
  return arr(operation)

}
  

export default predictMaitriseCible