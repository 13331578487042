// Vulnerabilité sur un risque

const criticity = (proba, impact, choix) => {
      let operation;
            switch (choix) {

                  case 'Arrondi supérieur':
                        operation = Math.ceil((proba + impact) / 2)
                        break
                  case 'Arrondi inférieur':
                        operation = Math.floor((proba + impact) / 2)
                        break
                  case 'Plus élevé':
                        if (proba > impact) {
                              operation = proba
                              break
                        } else {
                              operation = impact
                              break
                        }
                  case 'Plus faible':
                        if (proba > impact) {
                              operation = impact
                              break
                        } else {
                              operation = proba
                              break
                        }
                  case 'Moyenne':
                        operation = ((proba + impact) / 2)
                        break
                  default: 
                        console.log('... No case met ...')
                        break
            }
      
            const arr = (ope) => {return ((ope)*10)/10}

            return arr(operation)
      
      }
      
      
      

      export default criticity