const compareObjects = (o1, o2) => {
    
//soluce 1
    // return Object.keys(o1)
    // .filter(k => !Object.keys(o2).includes(k))
    // .concat(Object.keys(o2).filter(k => !Object.keys(o1).includes(k)))
    // .map(k => o1[k] || o2[k])

//soluce 2
    // let newObj = {};

    // for (let keys in o2) {
    //   if (!o1[keys]) {
    //     newObj[keys] = o2[keys]
    //   }
    // };

    // return newObj

//soluce 3

    // const c = Object.entries(b).reduce((c, [k, v]) => Object.assign(c, a[k] ? {} : { [k]: v }), {});
    // return c

//soluce 4

    // const combinedObject = { ...o1, ...o2 }

    // const diff = Object.entries(combinedObject).reduce((acc, [key, value]) => {
    //   if (
    //     !Object.values(o1).includes(value) ||
    //     !Object.values(o2).includes(value)
    //   )
    //     acc[key] = value
    
    //   return acc
    // }, {})
    // console.log({diff})
    // if (diff && Object.keys(diff).length === 0 && Object.getPrototypeOf(diff) === Object.prototype) {
    //     return null
    // } else {
    //     return diff
    // }

//soluce 5

  // console.log('entering function')
  // console.log(o1)
  // console.log(Object.entries(o2))
  
  let diff = []

  for (let i=0;i<Object.keys(o2).length;i++) {

    console.log(o1[Object.keys(o2)[i]], " !== ", o2[Object.keys(o2)[i]])

     if(!o1.hasOwnProperty(Object.keys(o2)[i])) {
      console.log(!o1.hasOwnProperty(Object.keys(o2)[i]), o2[Object.keys(o2)[i]])
      if(o2[Object.keys(o2)[i]]) {diff.push(o2[Object.keys(o2)[i]])}
     } else if(o1[Object.keys(o2)[i]] !== o2[Object.keys(o2)[i]]) {
        if(o1[Object.keys(o2)[i]]?.net){
          if(o1[Object.keys(o2)[i]]?.brut !== o2[Object.keys(o2)[i]]?.brut)
          diff.push(o2[Object.keys(o2)[i]])
        } else if(o1[Object.keys(o2)[i]]?._id) {
          if(o1[Object.keys(o2)[i]]?._id !== o2[Object.keys(o2)[i]])
          diff.push(o2[Object.keys(o2)[i]])
        } else {
          diff.push(o2[Object.keys(o2)[i]])
        }
      }
    
  }
  return diff
  }

export default compareObjects