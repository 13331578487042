import React, { useEffect, useState } from 'react'
import '../public/styles/incidents.css'

import moment from "moment/min/moment-with-locales"

//ant_____________________
import {
    notification, Popconfirm,
    Upload,
    message
} from 'antd';

import { PushpinOutlined, CloseCircleOutlined, CloseOutlined, DownOutlined, FileOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Score from './Score';
import ScoreDots from './ScoreDots';
import ScoreDotsParametrable from './ScoreDotsParametrable';
import Button from './Button';
import Liste from './Liste';
import { Link } from 'react-router-dom';

//REDUX___________________________________________________
import { useDispatch, useSelector } from 'react-redux'

//helpers

import { formatCodeNew, short } from "../helpers"
import { scoreTotal } from "../helpers"

moment.locale('fr')



const Incidents = ({ actualisation, setActualisation, volet, setVolet, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected }) => {

    //Drag nd drop antd
    const { Dragger } = Upload;

    const incidentSelected = useSelector(({ incidentSelected }) => incidentSelected)

    const [incidentCreator, setIncidentCreator] = useState()

    const [dci, setDci] = useState(stateSelected?.dci)

    const toggleIncidents = () => {
        setVolet(!volet)
    }

    const openNotification = () => {
        notification.open({
            message: 'Du brut au net',
            description:
                `La probabilité et l'impact bruts sont ajustés par rapport au niveau de maîtrise associé et au dispositif de maîtrise déployé.`,
            onClick: () => {
                //console.log('Notification Clicked!');
            },
        });
    };

    //LISTENERS CHECK
    // useEffect(() => { if (user) { console.log('user in Informations : ', user) } }, [user])
    // useEffect(() => { if (clientSelected) { console.log('clientSelected in Informations : ', clientSelected) } }, [clientSelected])
    // useEffect(() => { if (listFollowed) { console.log('listFollowed in Informations : ', listFollowed) } }, [listFollowed])
    // useEffect(() => { if (listByRisks) { console.log('listByRisks in Informations : ', listByRisks) } }, [listByRisks])
    // useEffect(() => { if (listByProcesses) { console.log('listByProcesses in Informations : ', listByProcesses) } }, [listByProcesses])
    useEffect(() => { if (stateSelected) { console.log('stateSelected in Incidents : ', stateSelected) } }, [stateSelected])

    useEffect(() => {
        const fetchIncidentCreator = async () => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/get-incident-creator/${stateSelected._id}/${incidentSelected._id}`)
            const json = await data.json()
            if (json.result) {
                setIncidentCreator(json.creator[0].incident.creator)
            }
        }
        if (incidentSelected) { fetchIncidentCreator() }
    }, [incidentSelected])


    //props du DRAGGER DCI
    const props = {
        name: 'file',
        style: { padding: 10 },
        showUploadList: false,
        multiple: false,
        action: `${process.env.REACT_APP_BACK_END}/upload/${stateSelected?._id}/dci`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (status === 'done' && info.file.response?.result === true) {
                //console.log({response: info.file.response})
                message.success(`${info.file.name} file uploaded successfully.`);
                setDci({ url: info.file.response.cloudResult, name: info.file.name });
                setActualisation(!actualisation)
                //console.log({incidentSelected, planSelected})
            } else if (status === 'error' || info.file.response?.result === false) {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            //console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const deletePiece = async (piece) => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/delete-piece/${stateSelected._id}/dci`)
        const json = await data.json()

        if (json.result) {
            console.log('deleted')
            setActualisation(!actualisation)
            setDci(null)
            message.success(`DCI successfully deleted.`);
        }
    }

    return (
        <>
            {!stateSelected &&
                <div className='containersuivi' >
                    <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>Sélectionnez un risque pour l'afficher</h1>
                    </div>
                </div>
            }
            {stateSelected &&
                <>
                    <div className='containersuivi'>
                        {clientSelected?.methodo_le_ray && clientSelected?.score && stateSelected?.calcul &&
                            <>
                                <div title={`Score`} style={{ zIndex: 2, cursor: 'pointer', position: 'absolute', top: '17%', left: '19%', width: 50, height: 50, borderRadius: 3, color: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FFF' : '#222', backgroundColor: scoreTotal(clientSelected?.score, stateSelected?.calcul) > 500 ? '#7E005D' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 50 ? '#C80094' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 10 ? '#FF0DC0' : scoreTotal(clientSelected?.score, stateSelected?.calcul) > 2 ? '#FF9BE5' : '#FFD9F5', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 'bold' }}>
                                    {scoreTotal(clientSelected?.score, stateSelected?.calcul)}
                                </div>
                                {stateSelected?.calcul?.confiance && <span style={{ zIndex: 1, position: 'absolute', top: '21.75%', left: '19.5%', fontWeight: 'bold', textAlign: 'center', lineHeight: '30px', fontSize: 16, height: 30, width: 30, backgroundColor: '#CCC', color: '#222', borderRadius: '50%' }}>{stateSelected?.calcul?.confiance}</span>}
                            </>
                        }
                        {/* BANDEAU 1 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <h2 title={stateSelected.intitule} style={{ margin: 0 }}>{!clientSelected?.level_more && formatCodeNew(stateSelected.process.libelle, stateSelected.risk?.family.libelle, stateSelected.risk.code)} - {short(stateSelected.intitule)}</h2>
                            {stateSelected.replaced_by &&
                                <span>{`Modifié le ${moment(stateSelected.date_iteration).format('LL')} par ${stateSelected.replaced_by.firstname} ${stateSelected.replaced_by.lastname}`}<img className='avatar-right' src={stateSelected.replaced_by.avatar} /></span>
                            }
                            {stateSelected.priority &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '7%', padding: 3, borderRadius: 15, backgroundColor: 'pink', border: '2px solid red', position: 'absolute', top: '18%', right: '19%' }}>
                                    <span style={{ fontWeight: 'bold' }}> Risque prioritaire </span>
                                </div>
                            }

                        </div>

                        {/* BANDEAU 2 DU HAUT */}
                        <div style={{ backgroundColor: '#FFFEEE', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 5 }}>

                            <div><span>{`Propriétaire : ${stateSelected.proprietaire?.habilitations.find(e => e.client_hab === clientSelected._id)?.type_hab} - ${stateSelected.proprietaire?.firstname} ${stateSelected.proprietaire?.lastname}`}</span><img className='avatar-right' src={stateSelected.proprietaire?.avatar} /></div>
                            <div><span>{`Risque créé le ${moment(stateSelected.date_creation).format('LL')} par ${stateSelected.creator?.firstname} ${stateSelected.creator?.lastname}`}</span><img className='avatar-right' src={stateSelected.creator?.avatar} /></div>

                        </div>

                        {/* DEUX COLONNES EN DESSOUS */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', marginBottom: 0, paddingTop: 20 }}>

                            {/* BANDEAU DU HAUT */}
                            <div className='subcontainersuivi' style={{ margin: 0, flexDirection: 'column', justifyContent: 'flex-start', height: '90%', width: '33%' }}>
                                <span style={{ marginBottom: 10, fontWeight: 'bolder', fontSize: 18 }}>Probabilité</span>

                                <div className='field' style={{ height: '30%', width: '80%', maxHeight: '100%' }}>
                                    <span className='title-field'>Probabilité de survenue</span>
                                    <p className='content'>{stateSelected.arg_proba}</p>
                                </div>

                                <span style={{ width: '80%', height: '10%', marginTop: 20, borderRadius: 30, textAlign: 'center' }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Temporalité d'observation : </span>{stateSelected.interval ? `${stateSelected.interval} mois` : 'Non connue'}</span>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', marginBottom: 0 }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', margin: '10px 0px' }}>
                                        <span className='lib-score'>Probabilité brute</span>
                                        <span className='score'>{stateSelected.lvl_proba.brut}</span>
                                    </div>
                                    <DownOutlined />
                                    <div style={{ border: '2px solid #C36F09', borderRadius: 50, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', margin: '10px 0px' }}>
                                        <span className='lib-score'>Probabilité nette</span>
                                        <span className='score'>{stateSelected.lvl_proba.net}</span>
                                        <QuestionCircleOutlined style={{ cursor: 'pointer', position: 'fixed', left: '22%', fontSize: 20, color: '#BBB' }} onClick={() => openNotification()} />
                                    </div>

                                    <ScoreDotsParametrable
                                        clientSelected={clientSelected}
                                        value={stateSelected.lvl_proba.net}
                                        reverse={true}
                                    />

                                </div>

                            </div>

                            {/* BANDEAU DU BAS */}

                            <div className='subcontainersuivi' style={{ margin: 0, flexDirection: 'column', justifyContent: 'flex-start', height: '90%', width: '33%' }}>
                                <span style={{ marginBottom: 10, fontWeight: 'bolder', fontSize: 18 }}>Impact</span>

                                <div className='field' style={{ height: '30%', width: '80%', maxHeight: '100%' }}>
                                    <span className='title-field'>Impact en cas de survenue</span>
                                    <p className='content'>{stateSelected.arg_impact}</p>
                                </div>

                                <span style={{ width: '80%', height: '10%', marginTop: 20, borderRadius: 30, textAlign: 'center', overflow: 'auto' }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Nature(s) d'impact : </span>{stateSelected.nature.length > 0 ? stateSelected.nature.map((e, i) => { return (i + 1 === stateSelected.nature.length ? `${e}.` : `${e}, `) }) : 'Aucune'}</span>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', marginBottom: 0 }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', margin: '10px 0px' }}>
                                        <span className='lib-score'>Impact brut</span>
                                        <span className='score'>{stateSelected.lvl_impact.brut}</span>
                                    </div>
                                    <DownOutlined />
                                    <div style={{ border: '2px solid #C36F09', borderRadius: 50, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '80%', margin: '10px 0px' }}>
                                        <span className='lib-score'>Impact net</span>
                                        <span className='score'>{stateSelected.lvl_impact.net}</span>
                                        <QuestionCircleOutlined style={{ cursor: 'pointer', position: 'fixed', left: '42%', fontSize: 20, color: '#BBB' }} onClick={() => openNotification()} />
                                    </div>

                                    <ScoreDotsParametrable
                                        clientSelected={clientSelected}
                                        value={stateSelected.lvl_impact.net}
                                        reverse={true}
                                    />

                                </div>

                            </div>

                            <div className='subcontainersuivi' style={{ margin: 0, flexDirection: 'column', justifyContent: 'flex-start', height: '90%', width: '34%' }}>
                                <span style={{ marginBottom: 10, fontWeight: 'bolder', fontSize: 18 }}>Maîtrise</span>
                                <div className='field' style={{ height: '30%', width: '80%', maxHeight: '100%' }}>
                                    <span className='title-field'>Dispositif de maîtrise déployé</span>
                                    <p className='content'>{stateSelected.descr_maitrise}</p>
                                </div>

                                <span style={{ width: '80%', height: '10%', marginTop: 20, borderRadius: 30, textAlign: 'center', overflow: 'auto' }}><span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Service(s) associé(s) : </span>{stateSelected.services_associes.length > 0 ? stateSelected.services_associes : 'Aucun'}</span>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '60%', margin: '10px 0px' }}>
                                            <span className='lib-score'>Niveau de maîtrise</span>
                                            <span className='score'>{stateSelected.lvl_maitrise}</span>
                                        </div>
                                        <ScoreDotsParametrable
                                            clientSelected={clientSelected}
                                            value={stateSelected.lvl_maitrise}
                                            reverse={false}
                                            // maitriseDots={true}
                                        />
                                    </div>

                                    <span style={{ marginTop: 20, marginBottom: 10, fontWeight: 'bold' }} >Dispositif de maîtrise</span>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '80%', marginBottom: -15 }}>
                                        <span style={{ minWidth: '20%', margin: '0px 5px', padding: '5px 5px', borderRadius: 10, border: stateSelected.dispositif_maitrise === 'Préventif' ? '2px solid #C36F09' : '', color: stateSelected.dispositif_maitrise === 'Préventif' ? '#FFF' : '#333', backgroundColor: stateSelected.dispositif_maitrise === 'Préventif' ? '#EDB30D' : '#FEF375', fontWeight: stateSelected.dispositif_maitrise === 'Préventif' ? 'bold' : 'normal', textAlign: 'center' }}>Préventif</span>
                                        <span style={{ minWidth: '20%', margin: '0px 5px', padding: '5px 5px', borderRadius: 10, border: stateSelected.dispositif_maitrise === 'Détectif' ? '2px solid #C36F09' : '', color: stateSelected.dispositif_maitrise === 'Détectif' ? '#FFF' : '#333', backgroundColor: stateSelected.dispositif_maitrise === 'Détectif' ? '#EDB30D' : '#FEF375', fontWeight: stateSelected.dispositif_maitrise === 'Détectif' ? 'bold' : 'normal', textAlign: 'center' }}>Détectif</span>
                                        <span style={{ minWidth: '20%', margin: '0px 5px', padding: '5px 5px', borderRadius: 10, border: stateSelected.dispositif_maitrise === 'Curatif' ? '2px solid #C36F09' : '', color: stateSelected.dispositif_maitrise === 'Curatif' ? '#FFF' : '#333', backgroundColor: stateSelected.dispositif_maitrise === 'Curatif' ? '#EDB30D' : '#FEF375', fontWeight: stateSelected.dispositif_maitrise === 'Curatif' ? 'bold' : 'normal', textAlign: 'center' }}>Curatif</span>
                                        <span style={{ minWidth: '20%', margin: '0px 5px', padding: '5px 5px', borderRadius: 10, border: stateSelected.dispositif_maitrise === 'Mixte' ? '2px solid #C36F09' : '', color: stateSelected.dispositif_maitrise === 'Mixte' ? '#FFF' : '#333', backgroundColor: stateSelected.dispositif_maitrise === 'Mixte' ? '#EDB30D' : '#FEF375', fontWeight: stateSelected.dispositif_maitrise === 'Mixte' ? 'bold' : 'normal', textAlign: 'center' }}>Mixte</span>
                                    </div>

                                </div>
                            </div>

                            <Button
                                texte={'Incidents'}
                                styleAdded={{ pointerEvents: !volet ? 'auto' : 'none', opacity: !volet ? 1 : 0, width: '10%', position: 'absolute', height: 38.75, left: '25%', bottom: '11%' }}
                                onClick={() => toggleIncidents()}
                                iconLeft
                                specif='dark'
                            />

                            {dci ?
                                <>
                                    <a name={dci?.name} href={dci?.url} target="_blank">
                                        <Button
                                            texte={'Descr. Contrôle Interne'}
                                            styleAdded={{ zIndex: 20, pointerEvents: !volet ? 'auto' : 'none', opacity: !volet ? 1 : 0, position: 'absolute', right: '25%', bottom: '11%', width: '10%', height: 38.75, padding: 0, margin: '-16px 0px 0px 0px' }}
                                            onClick={() => console.log('click')}
                                            iconPin
                                            specif='dark'
                                        />
                                    </a>
                                    <CloseOutlined style={{ zIndex: 50, fontSize: 10, position: 'absolute', right: '25.5%', bottom: '12.5%', color: '#FFF' }} onClick={() => { deletePiece() }} />
                                </>
                                :
                                <Dragger {...props} style={{ cursor: 'pointer', zIndex: 1, position: 'absolute', right: '25%', bottom: '11%', width: '10%', backgroundColor: 'transparent', padding: 0, margin: 0, height: 38.75, maxHeight: 38.75, border: 'none' }}>
                                    <Button
                                        {...props}
                                        texte={'Descr. Contrôle Interne'}
                                        styleAdded={{ zIndex: 20, pointerEvents: !volet ? 'auto' : 'none', opacity: !volet ? 1 : 0, width: '100%', height: 38.75, padding: 0, margin: '-16px 0px 0px 0px' }}
                                        onClick={() => console.log('click')}
                                        iconPin
                                        specif='dark'
                                    />
                                </Dragger>
                            }

                            {/* SLIDER INCIDENTS */}

                            <div className={`slider${volet ? '-open' : ''}`} style={{ boxShadow: '0px 0px 5px 0px #888' }}>
                                <CloseCircleOutlined style={{ cursor: 'pointer', position: 'absolute', fontSize: 20, right: '2%', top: '2%' }} onClick={() => toggleIncidents()} />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', height: '68%', width: '90%', marginBottom: 20 }}>

                                    {/* COLONNE DE GAUCHE */}
                                    <div className='subcontainersuivi' style={{ margin: 0, height: '100%', width: '30%' }}>
                                        <Liste
                                            stateSelected={stateSelected}
                                            data={stateSelected.incident}
                                            dataTitle={`Incidents`}
                                            typeOfList={'incidents'}
                                        />
                                    </div>

                                    {/* COLONNE DE DROITE */}
                                    <div className='subcontainersuivi' style={{ borderRadius: 3, justifyContent: 'center', flexDirection: 'row', margin: 0, height: '90%', width: '68%', border: '1px solid #726468' }}>

                                        {incidentSelected &&
                                            <>

                                                <div className='subcontainersuivi' style={{ backgroundColor: 'rgba(0,0,0,0)', margin: 0, height: '95%', width: '85%', justifyContent: 'space-between' }}>

                                                    <div style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '90%', padding: 0 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                                                            <div>
                                                                <h2 style={{ margin: 0 }}>{incidentSelected?.objet}</h2>
                                                                <span style={{ margin: 0, marginBottom: 5 }}>{`enregistré par ${incidentCreator?.firstname} ${incidentCreator?.lastname}`}<img className='avatar-right' src={incidentCreator?.avatar} alt='creatorAvatar' /></span>
                                                            </div>
                                                            <div style={{ maxWidth: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', width: '60%', margin: '10px 0px' }}>
                                                                <span className='lib-score' >Impact ressenti</span>
                                                                <span className='score' >{incidentSelected?.impact_ressenti}</span>
                                                            </div>
                                                        </div>
                                                        <p style={{ backgroundColor: '#FFF', padding: 5, textAlign: 'justify', margin: '10px 0px 0px 0px', width: '120%', height: '75%', overflowY: 'auto', borderTop: '1px solid #726468', borderBottom: '1px solid #726468' }}>{incidentSelected?.descr_incident}</p>
                                                    </div>
                                                </div>

                                                <div style={{ width: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: '14.5%', right: '16%' }}>
                                                    <div className='subcontainersuivi' style={{ width: '100%', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'flex-start', backgroundColor: 'rgba(0,0,0,0)', margin: 0, height: '95%', alignItems: 'flex-start' }}>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Date de l'incident : <span style={{ fontWeight: 'normal', color: '#222' }}>{moment(incidentSelected?.date_debut).format('LL')}</span></span>
                                                            <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Nature de l'impact : <span style={{ fontWeight: 'normal', color: '#222' }}>{incidentSelected?.nature_impact.length > 0 ? incidentSelected?.nature_impact.map((e) => { return e }) : 'Pas de service concerné'}</span></span>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Lieu : <span style={{ fontWeight: 'normal', color: '#222' }}>{incidentSelected?.lieu ? incidentSelected?.lieu : 'Pas de lieu associé'}</span></span>
                                                            <span style={{ fontWeight: 'bolder', color: '#EDB30D' }}>Service : <span style={{ fontWeight: 'normal', color: '#222' }}>{incidentSelected?.service ? incidentSelected?.service : 'Pas de service concerné'}</span></span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                        {!incidentSelected &&
                                            <div style={{ maxHeight: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90%', width: '90%', padding: 20 }}>
                                                <h3>{stateSelected?.incident.length > 0 ? `Sélectionner un incident pour le consulter` : `Aucun incident remonté pour le moment`}</h3>
                                            </div>
                                        }

                                    </div>
                                    <div style={{ width: '20%', position: 'absolute', left: '9%', bottom: '2%' }}><Link className='link-dark' to={`/create/incident`}> Déclarer un incident </Link></div>
                                </div>



                            </div>

                        </div>

                    </div>
                </>
            }
        </>
    )
}
export default Incidents;