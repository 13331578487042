/* eslint-disable import/no-anonymous-default-export */
export default (listByRisks = [], action) => {

    if (action.type === 'listByRisks') {
        // console.log('action.listByRisks : ', action.listByRisks)
        return action.listByRisks
    } else {
        // console.log('listByRisks : ', listByRisks)
        return listByRisks
    }
}