// Vulnerabilité sur un risque

const vulnerability = (criticity, maitrise) => {

      let operation = criticity / maitrise

      const arr = (ope) => {return ((ope)*10)/10}

      return arr(operation)
}

export default vulnerability