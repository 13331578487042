import React, { useState, useEffect } from 'react'
import '../public/styles/farmer.css'
import MarciGraphR from './MarciGraphR';

//ant_____________________
import {

    Select, Slider, Form

} from 'antd';
import { DatabaseTwoTone, FlagTwoTone } from '@ant-design/icons';


const MarciR = ({ toggleNum, setStateSelected, clientSelected, listFollowed, listByRisks, listByProcesses }) => {
    
    //antd
    const [form] = Form.useForm()
    const { Option } = Select;

    //setters
    const [filters, setFilters] = useState([])
    const [familyFilter, setFamilyFilter] = useState()
    const [processFilter, setProcessFilter] = useState()

    const [sliderM, setSliderM] = useState()
    const [sliderA, setSliderA] = useState()
    const [sliderR, setSliderR] = useState()

    const [dataSet, setDataSet] = useState(
        listFollowed
    ) 

    //listeners
    // useEffect(()=>{console.log({sliderM})}, [sliderM])
    // useEffect(()=>{console.log({listFollowed})}, [listFollowed])
    // useEffect(()=>{console.log({listByRisks})}, [listByRisks])
    // useEffect(()=>{console.log({listByProcesses})}, [listByProcesses])
    // useEffect(()=>{console.log({familyFilter, result: dataSet.filter(e=>e.risk?.family?.libelle === familyFilter)})}, [familyFilter])
    // useEffect(()=>{console.log({processFilter, result: dataSet.filter(e=>e.process?.libelle === processFilter)})}, [processFilter])

    //handle changes
    const handleChangeObjectif = (value) => { setFilters( {...filters, objectif: value} ) }
    const handleChangeFamily = (value) => { setFamilyFilter( value ) }
    const handleChangeProcess = (value) => { setProcessFilter( value ) }
    const handleChangeDomaine = (value) => { setFilters( {...filters, domaines_competence: value } ) }
    const handleChangeMode = (value) => { setFilters( {...filters, modes_intervention: value } ) }
    
    const onChangeSliderM = (value) => { setSliderM(value); setSliderA(); setSliderR() }
    const onChangeSliderA = (value) => { setSliderA(value); setSliderR(); setSliderM() }
    const onChangeSliderR = (value) => { setSliderR(value); setSliderA(); setSliderM() }
    const handleChangeConfiance = (value) => setFilters( {...filters, confiance: value} )

    //const onChangeSliderWorst = (value) => { setSliderWorst(value) }

    const handleChangeDataSet = (value) => {
        setDataSet(value)
    }

return (
    <>
        <div id='reportMarci' className='containersuivi' style={{flexDirection : 'row', justifyContent: 'center'}}>
            {/* Titre en dessous */}
            <h2 style={{position: 'absolute', top: '20%', left: '31%'}}>Détermination des actions prioritaires à mener</h2>
            {/* zone graphique */}
            <span style={{fontWeight: 'bolder', position: 'absolute', top: '21%', left: '21%'}}>Criticité</span>
            <span style={{fontWeight: 'bolder', position: 'absolute', bottom: '16%', right: '37%'}}>Maîtrise</span>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', transform: 'rotate(-90deg)', position: 'absolute', top: '51%', left: '10%', width: '24%'}}>
                <span style={{color: '#8ac926', fontWeight: 'bolder',}}>Faible</span>
                <span style={{color: '#ffb703', fontWeight: 'bolder',}}>Modérée</span>
                <span style={{color: 'orange', fontWeight: 'bolder',}}>Conséquente</span>
                <span style={{color: 'red', fontWeight: 'bolder',}}>Elevée</span>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: '16%', left: '27%', width: '31%'}}>
                <span style={{color: 'red', fontWeight: 'bolder',}}>Faible</span>
                <span style={{color: 'orange', fontWeight: 'bolder',}}>Modéré</span>
                <span style={{color: '#ffb703', fontWeight: 'bolder',}}>Conséquente</span>
                <span style={{color: '#8ac926', fontWeight: 'bolder',}}>Elevée</span>
            </div>

            <div style={{width: '65%', height: '95%'}}>

                <MarciGraphR setStateSelected={setStateSelected} toggleNum={toggleNum} processFilter={processFilter} familyFilter={familyFilter} clientSelected={clientSelected} handleChangeDataSet={handleChangeDataSet} dataSet={dataSet} sliderM={sliderM} sliderA={sliderA} sliderR={sliderR} listFollowed={listFollowed} filters={filters}/>

            </div>

            {/* zone filtres */}
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginRight: '-5%', width: '30%', height: '95%'}}>

                <Form form={form} style={{width: '100%', marginLeft: '20%'}}>

                    <div style={{width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Exposition maximale : {sliderM ? sliderM : dataSet.filter(f=>f.crit >= 4 && f.lvl_maitrise <=3).length} risque{sliderM ? sliderM > 1 ? 's' : '' : listFollowed.filter(f=>f.crit >= 4 && f.lvl_maitrise <=3).length > 1 ? 's' : ''}</span>
                        <Form.Item style={{margin: 0}} name='m'>                        
                        <Slider marks={{}} min={1} max={listFollowed.filter(f=>f.crit >= 4 && f.lvl_maitrise <=3).length} value={sliderM} defaultValue={listFollowed.filter(f=>f.crit >= 4 && f.lvl_maitrise <=3).length} onChange={(e)=>{onChangeSliderM(e)}}/>
                        </Form.Item>
                    </div>

                    <div style={{width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Evaluation de la maîtrise : {sliderA ? sliderA : dataSet.filter(f=>f.crit >= 4 && f.lvl_maitrise >=4).length} risque{sliderA ? sliderA > 1 ? 's' : '' : listFollowed.filter(f=>f.crit >= 4 && f.lvl_maitrise >=4).length > 1 ? 's' : ''}</span>
                        <Form.Item style={{margin: 0}} name='a'>                        
                        <Slider marks={{}} min={1} max={listFollowed.filter(f=>f.crit >= 4 && f.lvl_maitrise >=4).length} value={sliderA} defaultValue={listFollowed.filter(f=>f.crit >= 4 && f.lvl_maitrise >=4).length} onChange={(e)=>{onChangeSliderA(e)}}/>
                        </Form.Item>
                    </div>

                    <div style={{width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Réallocation de ressources : {sliderR ? sliderR : dataSet.filter(f=>f.crit <= 3 && f.lvl_maitrise >=3).length} risque{sliderR ? sliderR > 1 ? 's' : '' : listFollowed.filter(f=>f.crit <= 3 && f.lvl_maitrise >=3).length > 1 ? 's' : ''}</span>
                        <Form.Item style={{margin: 0}} name='r'>                        
                        <Slider marks={{}} min={1} max={listFollowed.filter(f=>f.crit <= 3 && f.lvl_maitrise >=3).length} value={sliderR} defaultValue={listFollowed.filter(f=>f.crit <= 3 && f.lvl_maitrise >=3).length} onChange={(e)=>{onChangeSliderR(e)}}/>
                        </Form.Item>
                    </div>

                    <div style={{backgroundColor: '#EEE', marginBottom: 10, width: '80%', display: 'flex', flexDirection: 'column', justifyContent:' center', alignItems: 'center'}}>
                        <span style={{height: '70%', lineHeight: '100%', width: '80%', maxWidth: '80%', minWidth: '15%', cursor: 'pointer', margin: 5, padding: 10, borderRadius: 10, backgroundColor: '#DDD', textAlign: 'center', fontWeight: 'bolder', color: '#222'}} onClick={()=>{handleChangeDataSet(listFollowed); setFilters([]); setSliderM(); setSliderA(); setSliderR(); setProcessFilter(); setFamilyFilter(); form.resetFields(['family', 'process', 'objectif', 'domaine', 'name', 'confiance', 'm', 'a', 'r'])}}>Tous les risques</span>
                    </div>

                    {/* <div style={{width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Exposition minimale</span>
                        <Slider marks={{0: 0, 5: 5, 10: 10, 15: 15, 20:20}} min={0} max={20} defaultValue={dataSet.length} onChange={(e)=>{onChangeSliderWorst(e)}}/>
                    </div> */}

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                        <span style={{fontWeight: 'bold', marginBottom: 5}}>Filtres métiers</span>

                        <Form.Item style={{margin: 0, width: '100%'}} name='family'>                        
                            <Select /*mode="tags"*/ style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par famille de risques" onChange={handleChangeFamily}>
                                {listByRisks.map((e, i) => { return <Option key={e.family.libelle}>{e.family.libelle}</Option> })} 
                            </Select>
                        </Form.Item>
                        <Form.Item style={{margin: 0, width: '100%'}} name='process'>
                            <Select /*mode="tags"*/ style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par processus métier" onChange={handleChangeProcess}>
                                {
                                listByProcesses.map((e, i) => { return e.family.processes }).flat().map((f, j)=>{return <Option key={f.libelle}>{f.libelle}</Option>})
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item style={{margin: 0, width: '100%'}} name='objectif'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.objectif_activated ? false : true} maxTagCount={3} style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par objectif COSO" onChange={handleChangeObjectif}>
                                {['Performance', 'Conformité', 'Reporting'].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model and ManageRiskState*/}
                            </Select>
                        </Form.Item>
                        <Form.Item style={{margin: 0, width: '100%'}} name='mode'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.modes_activated ? false : true} style={{ width: '100%', marginBottom: 10 }} maxTagCount={3} placeholder="Filtrer par mode d'intervention" onChange={handleChangeMode}>
                                        {[
                                                
                                                'Octroi de subventions',
                                                'Remboursement de frais',
                                                'Attribution d’aides individuelles',
                                                'Passation de marchés',
                                                'Mise en place de régies',
                                                'Délégation de service public',
                                                'Octroi de garanties, prêts ou cautions',
                                                'Autre',
                                                `Tous modes`                                    
                                        ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model and ManageRiskState */}
                            </Select>
                        </Form.Item>
                        <Form.Item style={{margin: 0, width: '100%'}} name='domaine'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.domaines_activated ? false : true} style={{ width: '100%', marginBottom: 10 }} maxTagCount={3} placeholder="Filtrer par domaine de compétence" onChange={handleChangeDomaine}>
                                        {[
                                                
                                                'Éducation et formation',
                                                'Voirie',
                                                'Social',
                                                'Culture',
                                                'Sport',
                                                'Aménagement du territoire',
                                                'Ruralité',
                                                'Environnement et développement durable',
                                                'Patrimoine',
                                                'Économie',
                                                'Transports',
                                                'Eau et assainissement',
                                                'Autre',
                                                'Tous domaines'
                                        ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                            </Select>
                        </Form.Item>
                        {clientSelected.methodo_le_ray &&
                        <Form.Item style={{margin: 0, width: '100%'}} name='confiance'>
                            <Select /*mode="tags"*/ disabled={clientSelected?.type_hab === 'Risk management' ? false : true} style={{ width: '100%', marginBottom: 10 }} maxTagCount={1} placeholder="Filtrer par indice de confiance" onChange={handleChangeConfiance}>
                                        {[
                                            
                                            'A',
                                            'B',
                                            'C'
                                        ].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                            </Select>
                        </Form.Item>
                        }
                </div>
                </Form>

                <span style={{fontWeight: 'bold', textAlign: 'center', width: '100%'}}>{dataSet.length} risque{dataSet.length > 1 ? 's' : ''} affiché{dataSet.length > 1 ? 's' : ''}</span>

            </div>


        </div>
    </>
)
}
export default MarciR;