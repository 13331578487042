import React, { useState, useEffect } from 'react'
import '../public/styles/radian.css'
import { Link } from 'react-router-dom';

import RadianGraph from './RadianGraph';
import RadianGraphMacro from './RadianGraphMacro';

//REDUX___________________________________________________
import { useDispatch, useSelector } from 'react-redux'

import { short, shortparam } from '../helpers'

//ant_____________________
import {

    Select, Slider, Form

} from 'antd';
import { CaretLeftFilled, CaretRightFilled, EyeOutlined, RadarChartOutlined, SwapOutlined, ZoomInOutlined } from '@ant-design/icons';


const Radian = ({ setStateSelected, toggleNum, clientSelected, listFollowed, listByRisks, listByProcesses }) => {

    const [form] = Form.useForm()

    //antd
    const { Option } = Select;

    //redux
    const dispatch = useDispatch()

    const [selection, setSelection] = useState({})
    const [hover, setHover] = useState({})

    const [spider, setSpider] = useState(false)

    const [toggle, setToggle] = useState(true)

    const [swap, setSwap] = useState(false)

    const [processFilter, setProcessFilter] = useState()
    const [familyFilter, setFamilyFilter] = useState()

    // index au sein de la sélection pour switch entre risks
    const [indexRisk, setIndexRisk] = useState(0)

    //  useEffect(()=>{console.log({processFilter})}, [processFilter])
    //  useEffect(()=>{console.log({familyFilter})}, [familyFilter])

    const [dataSet, setDataSet] = useState(listByRisks[0]?.family?.risks?.map(e=>e.states).flat())

    //réinitialise l'index à chaque nouvelle sélection pour éviter index > length
    useEffect(()=>{setIndexRisk(0)}, [selection])

    useEffect(()=>{
        const handleChangeDataSet = (value) => {
            setDataSet(value)
        }
        
        if(processFilter || processFilter === 0) {handleChangeDataSet(listByProcesses.map((e, i) => { return e.family?.processes }).flat()[processFilter].states)}
        if(familyFilter || familyFilter === 0) {handleChangeDataSet(listByRisks[familyFilter]?.family?.risks.map(e=>e.states[0]))}

    }, [processFilter, familyFilter, swap])

    //useEffect(()=>{console.log({selection: selection, typo: typeof(selection)})}, [selection])
    //useEffect(()=>{console.log({hover: hover, typo: typeof(hover)})}, [hover])

    const handleChangeFamily = (value) => { setFamilyFilter( listByRisks.findIndex(el=>el.family.libelle === value) ); setProcessFilter(); form.resetFields(['process']) }
    const handleChangeProcess = (value) => { setProcessFilter( listByProcesses.map((e, i) => { return e.family.processes }).flat().findIndex(g=>g.libelle === value) ); setFamilyFilter(); form.resetFields(['risk']) }

    const fond = [
        '#d4111e', '#d4111e', '#d4111e', '#dd462b', '#dd462b', '#f48c06', '#f48c06', '#feed34', '#feed34',
        '#a0d034', '#a0d034', '#75c626', '#75c626', '#49bc17', '#EEE', '#EEE', '#DDD', '#DDD', '#CCC',
        '#CCC', '#BBB', '#BBB', '#AAA', '#AAA', '#999',
    ]

    const displayBar = () => {
        return (fond.reverse().map((el, i)=>{
            return <div title={i-12} style={{backgroundColor: el, width : '3.2%', height: '100%', borderRadius: 2}}></div>
        })
        )
    }

    const displayScore = () => {
        return (fond.map((el, i)=>{
            if(!toggle) {
            return (<div title={Math.round(selection.rapport*2) === i-12 ? i-12 : ''} style={{position: 'relative', backgroundColor: Math.round(selection.rapport*2) === i-12 ? '#222' : 'transparent', width : '3.2%', height: '100%', borderRadius: '0px 0px 20px 20px', color: '#222'}}>
                        {Math.round(selection.rapport*2) === i-12 && <div style={{transform: i-12 < 0 ? 'translateX(-50%)' : '', minWidth: '3.2%', fontSize: 12, position: 'relative', top: -20}}>{i-12}</div>}
                    </div>)
            } else { //console.log(' selection index and rapport : ', Math.round(selection[indexRisk]?.rapport*2), ' - ', indexRisk)
            return (<div title={Math.round(selection[indexRisk]?.rapport*2) === i-12 ? i-12 : ''} style={{position: 'relative', backgroundColor: Math.round(selection[indexRisk]?.rapport*2) === i-12 ? '#222' : 'transparent', width : '3.2%', height: '100%', borderRadius: '0px 0px 20px 20px', color: '#222'}}>
                        {Math.round(selection[indexRisk]?.rapport*2) === i-12 && <div style={{transform: i-12 < 0 ? 'translateX(-50%)' : '', minWidth: '3.2%', fontSize: 12, position: 'relative', top: -20}}>{i-12}</div>}
                    </div>)
            }
        })
        )
    }

    return (
        <>
            <div id='reportRadian' className='containersuivi' style={{flexDirection : 'row', justifyContent: 'center'}}>
                {/* Titre en dessous */}
                {/* Mis avec un h2 en remplacement du hover quand !hover */}
                {/* zone graphique */}
                <div style={{width: '65%', height: '95%'}}>
                    {toggle ?
                    <RadianGraphMacro swap={swap} toggle={toggle} setToggle={setToggle} setFamilyFilter={setFamilyFilter} setProcessFilter={setProcessFilter} hover={hover} setHover={setHover} spider={spider} selection={selection} setSelection={setSelection} listByProcesses={listByProcesses} listByRisks={listByRisks} clientSelected={clientSelected} dataSet={dataSet} listFollowed={listFollowed}/>
                    :
                    <RadianGraph hover={hover} setHover={setHover} spider={spider} selection={selection} setSelection={setSelection} listByProcesses={listByProcesses} listByRisks={listByRisks} clientSelected={clientSelected} dataSet={dataSet} listFollowed={listFollowed}/>
                    }
                </div>

                {/* zone filtres */}
                <div id='filtres' style={{zIndex: -1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', marginRight: '-5%', width: '30%', height: '95%'}}>

                {!toggle && hover?.intitule &&
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 20, textAlign: 'center', backgroundColor: '#222', opacity: 0.8, color: '#FFF', fontSize: 14, position: 'absolute', top: '23%', left: '63.8%', width: '14.4%', height: '18%', padding: 10, borderRadius: 5}}>
                        <span style={{fontWeight: 'bolder', paddingBottom: 10, fontSize: hover.intitule.length > 35 ? 12: 14}}>{hover.intitule}</span>        
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: hover.intitule.length > 35 ? 12: 14}}>
                            <span>Probabilité : {hover?.lvl_proba?.net}</span> 
                            <span>Impact : {hover?.lvl_impact?.net}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: hover.intitule.length > 35 ? 12: 14}}>    
                            <span>Maîtrise : {hover?.lvl_maitrise}</span> 
                            <span>Criticité : {hover?.crit}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: hover.intitule.length > 35 ? 12: 14}}>   
                            <span>Vulnérabilité : {hover?.vuln}</span> 
                            <span>Equilibre : {hover?.rapport}</span> 
                        </div>
                        <span style={{width: '100%', display: 'flex', justifyContent: 'space-around', paddingBottom: 5, fontSize: hover.intitule.length > 35 ? 12: 14}}>Maîtrise optimale : {hover?.prediction}</span> 
                        <span>Conseil : {Math.round(hover?.prediction) == hover?.lvl_maitrise ? `Maintenir l'équilibre` : hover?.prediction > hover?.lvl_maitrise ? 'Renforcer la maîtrise' : 'Réallouer des ressources'}</span> 
                    </div>        
                }

                {!toggle && !hover?.intitule &&
                    <div id='title' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, textAlign: 'center', position: 'absolute', top: '23%', left: '63.8%', width: '14.4%', height: '18%', padding: 10, borderRadius: 5}}>
                        <h2>Calibrage du degré de maîtrise optimal</h2>
                    </div>
                }

                {toggle && hover?.length === 1 &&
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 20, textAlign: 'center', backgroundColor: '#222', opacity: 0.8, color: '#FFF', fontSize: 14, position: 'absolute', top: '23%', left: '63.8%', width: '14.4%', height: '18%', padding: 10, borderRadius: 5}}>
                        <span style={{fontWeight: 'bolder', paddingBottom: 10, fontSize: hover[0].intitule.length > 35 ? 12: 14}}>{shortparam(hover[0].intitule, 80)}</span>        
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: hover[0].intitule.length > 35 ? 12: 14}}>
                            <span>Probabilité : {hover[0]?.lvl_proba?.net}</span> 
                            <span>Impact : {hover[0]?.lvl_impact?.net}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: hover[0].intitule.length > 35 ? 12: 14}}>    
                            <span>Maîtrise : {hover[0]?.lvl_maitrise}</span> 
                            <span>Criticité : {hover[0]?.crit}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: hover[0].intitule.length > 35 ? 12: 14}}>   
                            <span>Vulnérabilité : {hover[0]?.vuln}</span> 
                            <span>Equilibre : {hover[0]?.rapport}</span> 
                        </div>
                        <span style={{width: '100%', display: 'flex', justifyContent: 'space-around', paddingBottom: 5, fontSize: hover[0].intitule.length > 35 ? 12: 14}}>Maîtrise optimale : {hover[0]?.prediction}</span> 
                        <span>Conseil : {Math.round(hover[0]?.prediction) == hover[0]?.lvl_maitrise ? `Maintenir l'équilibre` : hover[0]?.prediction > hover[0]?.lvl_maitrise ? 'Renforcer la maîtrise' : 'Réallouer des ressources'}</span> 
                    </div>        
                }

                {toggle && hover?.length > 1 &&
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', zIndex: 20, textAlign: 'left', backgroundColor: '#222', opacity: 0.8, color: '#FFF', fontSize: 14, position: 'absolute', top: '23%', left: '63.8%', width: '14.4%', height: '18%', padding: 10, borderRadius: 5}}>
                        {hover.map((el, i)=> {
                            return <span style={{fontSize: hover.length > 5 ? 8 : 12, marginBottom: 5}}>{i+1} - {short(el.intitule)}</span>
                        })
                        }
                    </div>        
                }    

                {toggle && !hover.length &&
                    <div id='title' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, textAlign: 'center', position: 'absolute', top: '23%', left: '63.8%', width: '14.4%', height: '18%', padding: 10, borderRadius: 5}}>
                        <h2>Calibrage du degré de maîtrise optimal</h2>
                    </div>
                }

                {!toggle && selection?.intitule &&
                    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', zIndex: 10, textAlign: 'center', backgroundColor: '#EEE', opacity: 1, color: '#222', position: 'absolute', top: '43.5%', left: '63.8%', width: '14.4%', height: '22%', padding: 10, borderRadius: 5}}>
                        {/* Titre */}
                        <span style={{fontWeight: 'bolder', paddingBottom: 10, fontSize: selection.intitule.length > 35 ? 12: 14}}>{shortparam(selection.intitule,80)}</span>        
                        {/* Indicateurs */}
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection.intitule.length > 35 ? 12: 14}}>
                            <span>Probabilité : {selection?.lvl_proba?.net}</span> 
                            <span>Impact : {selection?.lvl_impact?.net}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection.intitule.length > 35 ? 12: 14}}>    
                            <span>Maîtrise : {selection?.lvl_maitrise}</span> 
                            <span>Criticité : {selection?.crit}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection.intitule.length > 35 ? 12: 14}}>   
                            <span>Vulnérabilité : {selection?.vuln}</span> 
                            <span>Equilibre : {selection?.rapport}</span> 
                        </div>
                        <span style={{width: '100%', display: 'flex', justifyContent: 'space-around', paddingBottom: 5, fontSize: selection.intitule.length > 35 ? 12: 14}}>Maîtrise optimale : {selection?.prediction}</span> 
                        {/* Bandeau risque du bas */}
                        <div style={{backgroundColor: '#FFF', padding: 5, borderRadius: 3, height: '30%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>

                            <div style={{backgroundColor: '#FFF', padding: '5px 5px 1px 5px', borderRadius: 3, height: '35%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                {displayScore()}
                            </div>
                            <div style={{backgroundColor: '#FFF', padding: '0px 5px 0px 5px', borderRadius: 3, height: '35%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                {displayBar()}
                            </div>
                        </div>
                    {/* Renvoi au risque lui-même */}
                        <div title='Voir le risque' onClick={()=>{ toggleNum(0); setStateSelected(selection) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '-5%', top: '-5%', cursor: 'pointer', backgroundColor: '#EEE', borderRadius: '50%', width: 30, height: 30 }}><EyeOutlined style={{fontSize: 20}}/></div>

                    </div>   
                }

                {toggle && selection?.length === 1 &&
                    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', zIndex: 10, textAlign: 'center', backgroundColor: '#EEE', opacity: 1, color: '#222', position: 'absolute', top: '43.5%', left: '63.8%', width: '14.4%', height: '22%', padding: 10, borderRadius: 5}}>
                        {/* Titre */}
                        <span style={{fontWeight: 'bolder', paddingBottom: 10, fontSize: selection[0].intitule.length > 35 ? 12: 14}}>{shortparam(selection[0].intitule, 80)}</span>        
                        {/* Indicateurs */}
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection[0].intitule.length > 35 ? 12: 14}}>
                            <span>Probabilité : {selection[0]?.lvl_proba?.net}</span> 
                            <span>Impact : {selection[0]?.lvl_impact?.net}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection[0].intitule.length > 35 ? 12: 14}}>    
                            <span>Maîtrise : {selection[0]?.lvl_maitrise}</span> 
                            <span>Criticité : {selection[0]?.crit}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection[0].intitule.length > 35 ? 12: 14}}>   
                            <span>Vulnérabilité : {selection[0]?.vuln}</span> 
                            <span>Equilibre : {selection[0]?.rapport}</span> 
                        </div>
                        <span style={{width: '100%', display: 'flex', justifyContent: 'space-around', paddingBottom: 5, fontSize: selection[0].intitule.length > 35 ? 12: 14}}>Maîtrise optimale : {selection[0]?.prediction}</span> 
                        {/* Bandeau risque du bas */}
                        <div style={{backgroundColor: '#FFF', padding: 5, borderRadius: 3, height: '30%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>

                            <div style={{backgroundColor: '#FFF', padding: '5px 5px 1px 5px', borderRadius: 3, height: '35%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                {displayScore()}
                            </div>
                            <div style={{backgroundColor: '#FFF', padding: '0px 5px 0px 5px', borderRadius: 3, height: '35%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                {displayBar()}
                            </div>
                        </div>
                        <div title='Voir le risque' onClick={()=>{ toggleNum(0); setStateSelected(selection[0]) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '-5%', top: '-5%', cursor: 'pointer', backgroundColor: '#EEE', borderRadius: '50%', width: 30, height: 30 }}><EyeOutlined style={{fontSize: 20}}/></div>

                    </div>   
                }

                {toggle && selection?.length > 1 &&
                    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', zIndex: 10, textAlign: 'center', backgroundColor: '#EEE', opacity: 1, color: '#222', position: 'absolute', top: '43.5%', left: '63.8%', width: '14.4%', height: '22%', padding: 10, borderRadius: 5}}>
                        {/* Nb risques */}
                        <span style={{position: 'absolute', fontSize: 12, top: '-10%'}}>{selection?.length} risques</span>
                        {/* Flèches */}
                        <CaretLeftFilled onClick={()=>{ if ( indexRisk === 0 ) { setIndexRisk(selection.length - 1 ) } else { setIndexRisk(indexRisk-1) } }} style={{cursor: 'pointer', position: 'absolute', fontSize: 20, top: '45%', left: '-6%'}} /> <CaretRightFilled onClick={()=>{ if ( indexRisk === selection.length-1 ) { setIndexRisk(0) } else { setIndexRisk(indexRisk+1) } }} style={{cursor: 'pointer', position: 'absolute', fontSize: 20, top: '45%', right: '-6%'}}/>
                        {/* Titre */}
                        <span style={{fontWeight: 'bolder', paddingBottom: 10, fontSize: selection[indexRisk].intitule.length > 35 ? 12: 14}}>{indexRisk+1} - {shortparam(selection[indexRisk].intitule,80)}</span>        
                        {/* Indicateurs */}
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection[indexRisk].intitule.length > 35 ? 12: 14}}>
                            <span>Probabilité : {selection[indexRisk]?.lvl_proba?.net}</span> 
                            <span>Impact : {selection[indexRisk]?.lvl_impact?.net}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection[indexRisk].intitule.length > 35 ? 12: 14}}>    
                            <span>Maîtrise : {selection[indexRisk]?.lvl_maitrise}</span> 
                            <span>Criticité : {selection[indexRisk]?.crit}</span> 
                        </div>
                        <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', paddingBottom: 5, fontSize: selection[indexRisk].intitule.length > 35 ? 12: 14}}>   
                            <span>Vulnérabilité : {selection[indexRisk]?.vuln}</span> 
                            <span>Equilibre : {selection[indexRisk]?.rapport}</span> 
                        </div>
                        <span style={{width: '100%', display: 'flex', justifyContent: 'space-around', paddingBottom: 5, fontSize: selection[indexRisk].intitule.length > 35 ? 12: 14}}>Maîtrise optimale : {selection[indexRisk]?.prediction}</span> 
                        {/* Bandeau risque du bas */}
                        <div style={{backgroundColor: '#FFF', padding: 5, borderRadius: 3, height: '30%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>

                            <div style={{backgroundColor: '#FFF', padding: '5px 5px 1px 5px', borderRadius: 3, height: '35%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                {displayScore()}
                            </div>
                            <div style={{backgroundColor: '#FFF', padding: '0px 5px 0px 5px', borderRadius: 3, height: '35%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                {displayBar()}
                            </div>
                        </div>
                    <div title='Voir le risque' onClick={()=>{ toggleNum(0); setStateSelected(selection[indexRisk]) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '-5%', top: '-5%', cursor: 'pointer', backgroundColor: '#EEE', borderRadius: '50%', width: 30, height: 30 }}><EyeOutlined style={{fontSize: 20}}/></div>

                    </div>   
                }

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%', backgroundColor: '#EEE', marginBottom: 10, borderRadius: 3, padding: '10px 10px 0px 10px'}}>
                            <span style={{width: '85%', fontWeight: 'bold', marginBottom: 5}}>Filtres métiers {!toggle && dataSet.length > 2 && <RadarChartOutlined title={spider ? `Désactiver le mode "radar"` : `Activer le mode "radar"`} style={{marginLeft: '25%', fontSize: 16, color: spider ? '#AAA' : '#CCC'}} onClick={()=>{setSpider(!spider)}}/>} {toggle && <SwapOutlined title={swap ? `Par familles de risques` : `Par familles de processus métier`} style={{marginLeft: '25%', fontSize: 16, color: '#AAA', transform: swap ? 'rotate(180deg)' : 'rotate(-180deg)', transition: 'transform 1s ease-in-out'}} onClick={()=>{ setSwap(!swap); form.resetFields(['risk', 'process']) }}/>} <ZoomInOutlined title={toggle ? 'Vue par famille ou process' : 'Vue macroscopique'} style={{marginLeft: '5%', fontSize: 16, color: !toggle ? '#AAA' : '#CCC'}} onClick={()=>{setToggle(!toggle); form.resetFields(['risk', 'process'])}}/> </span>
                            
                            <Form style={{width: '100%', marginBottom: 0}} form={form}>
                                <Form.Item style={{width: '100%', marginBottom: 0}} name='risk'>
                                <Select /*mode="tags"*/ disabled={toggle ? true : false} style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par famille de risques" onChange={handleChangeFamily}>
                                    {listByRisks.map((e, i) => { return <Option key={e.family.libelle}>{e.family.libelle}</Option> })} 
                                </Select>
                                </Form.Item>
                            </Form>
                            
                            <Form style={{width: '100%', marginBottom: 0}} form={form}>
                                <Form.Item style={{width: '100%', marginBottom: 0}} name='process'>
                                <Select /*mode="tags"*/ disabled={toggle ? true : false} style={{ width: '100%', marginBottom: 10 }} placeholder="Filtrer par processus métier" onChange={handleChangeProcess}>
                                    {
                                    listByProcesses.map((e, i) => { return e.family.processes }).flat().map((f, j)=>{return <Option key={f.libelle}>{f.libelle}</Option>})
                                    }
                                </Select>
                                </Form.Item>
                            </Form>
                            
                    </div>

                        

                </div>

            </div>
        </>
    )
    }
    export default Radian;