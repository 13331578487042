import "./App.css";

import Home from "../src/views/Home";
import Suivi from "../src/views/Suivi";
import Parametrage from "../src/views/Parametrage";
import Create from "./views/Create";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App(props) {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}>
          <Route exact path="/:loaded" element={<Home />} />
        </Route>
        <Route exact path="/parametrage" element={<Parametrage />} />
        <Route exact path="/suivi" element={<Suivi />} />
        <Route exact path="/suivi" element={<Suivi />}>
          <Route exact path=":toggleFromHome" element={<Suivi />} />
          <Route exact path=":stepFromCreate" element={<Suivi />} />
          <Route
            exact
            path=":stepFromCreate/:stateFromCreate"
            element={<Suivi />}
          />
        </Route>
        <Route exact path="" element={<Suivi />} />
        <Route exact path="/create/:typo" element={<Create />}>
          <Route exact path=":stepFromSuivi" element={<Create />} />
          <Route exact path=":clientId/:tokenFromUrl" element={<Create />} />
          <Route exact path=":auditId/:stateId" element={<Create />} />
          <Route exact path="" element={<Create />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
