import React, { useState, useEffect } from 'react'
import '../public/styles/manageprofile.css'

import AvatarUpload from '../components/AvatarUpload'
import moment from "moment/min/moment-with-locales"

//ant_____________________
import {
    Input,
    Upload,
    Select,
    Badge,
    Card,
    Slider,
    Statistic,
    Row,
    Col,
    Form,
    message
} from 'antd';

import ImgCrop from 'antd-img-crop';

import {
    LeftOutlined,
    RightOutlined,
    InboxOutlined,
    SwapOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,    
    UserOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
    LockOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import Button from './Button';
import Dragger from 'antd/lib/upload/Dragger';

//REDUX_______________________
import { useDispatch, useSelector } from 'react-redux'

moment.locale('fr')

const { TextArea } = Input;
const { Option } = Select

const ManageProfile = ({userSelected, setUserSelected, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected}) => {

    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [fileList, setFileList] = useState([]);

    const [current, setCurrent] = useState({})
    const [hover, setHover] = useState(false)

    useEffect(()=>{
//        console.log({userSelected})
        setCurrent({})
        form.resetFields()
        setFileList([])
    }, [userSelected])

    //LISTENERS CHECK
    // useEffect(()=>{if (user) {console.log('user in AddProfile : ', user)}}, [user])
    // useEffect(()=>{if (clientSelected) {console.log('clientSelected in AddProfile : ', clientSelected)}}, [clientSelected])
    // useEffect(()=>{if (listFollowed) {console.log('listFollowed in AddProfile : ', listFollowed)}}, [listFollowed])
    // useEffect(()=>{if (listByRisks) {console.log('listByRisks in AddProfile : ', listByRisks)}}, [listByRisks])
    // useEffect(()=>{if (listByProcesses) {console.log('listByProcesses in AddProfile : ', listByProcesses)}}, [listByProcesses])
    // useEffect(()=>{if (stateSelected) {console.log('stateSelected in AddProfile : ', stateSelected)}}, [stateSelected])
    // useEffect(()=>{if (current) {console.log('current in AddProfile : ', current)}}, [current])

    const handleChangeLastname = (e) => {setCurrent({...current, lastname: e.target.value})}
    const handleChangeFirstname = (e) => {setCurrent({...current, firstname: e.target.value})}    
    const handleChangeEmail = (e) => {setCurrent({...current, email: e.target.value})}    

    const handleChangeRole = (e) => {setCurrent({...current, role: e.target.value})}    
    const handleChangePassword = (e) => {setCurrent({...current, password: e.target.value})}    
    const handleChangeHabilitation = (value) => {setCurrent({...current, habilitations: [{client_hab: clientSelected._id, type_hab: value}]})}

    const successSave = () => {
        message.success('Profil enregistré avec succès 👍');
      };

    const deleteAvatar = async (id) => {
    //    console.log(id)
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/delete-avatar/${id}`)
        const json = await data.json()

        if (json.result) {
    //        console.log('Avatar deleted')
    //        console.log({userModified: json.userModified})
            setUserSelected(json.userModified[0])
        }
    }

//Upload antd

  const onChange = ({ fileList: newFileList }) => {
 
    setFileList(newFileList);
//   console.log(newFileList[0]?.status)
  
     if (newFileList[0].status === 'uploading') {
//         console.log(newFileList[0].name, ' uploading');
     }
     if (newFileList[0].status === 'done') {
         message.success(`${newFileList[0].name} file uploaded successfully.`)
//         console.log( newFileList[0] )
         setCurrent({ ...current, avatar: newFileList[0].response.cloudResult })

     } else if (newFileList[0].status === 'error') {
         message.error(`${newFileList[0].name} file upload failed.`);
     }

  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };


//end dragndrop

    const handleSaveProfile = async () => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/update-user/${userSelected._id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({current})
        })
        const json = await data.json()

        if (json.result) {
//            console.log(json)
            successSave()
            setCurrent({})
            setUserSelected(json.userUpdated[0])
            form.resetFields()
        }
    }


    const handleUnlink = async (id) => {

        const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/unlink-user/${id}/${clientSelected._id}`)

        const json = await data.json()

        if(json.result) {
//            console.log(json.newList)
            dispatch({type: 'clientusers', users: json.newList})

        }

    }

    return (
        <>                  
            <div className='containersuivi' style={{justifyContent: 'space-evenly', position: 'relative', width: '100%', height: '100%'}}>

            <DeleteOutlined onClick={()=>{ handleUnlink(userSelected._id) }} title='Supprimer le profil' className='trash' style={{position: 'absolute', bottom: '5%', left: '5%', cursor: 'pointer'}}/>

            {userSelected.avatar ?
            <>
            <div style={{position: 'absolute', top: '7%', left:'7%', width: '20%', height: '27%'}} onMouseEnter={()=>setHover(!hover)} onMouseLeave={()=>setHover(!hover)}>
                <img src={userSelected.avatar} style={{width: '100%', height: '100%', position: 'absolute', top: '7%', left:'7%', borderRadius: '50%', boxShadow: '1px 1px 10px 3px #DDD'}}/>
                <CloseCircleOutlined onClick={()=>{deleteAvatar(userSelected._id)}} style={{cursor: 'pointer', position: 'absolute', top: '10%', right: '-5%', display: hover ? 'block' : 'none'}}/>
            </div>
            </>
            :
            <div style={{position: 'absolute', top: '10%', left:'10%', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                <ImgCrop rotate>
                    <Upload
                        action={`${process.env.REACT_APP_BACK_END}/upload-user-avatar`}
                        listType="picture-card"
                        defaultFileList={userSelected.avatar}
                        fileList={fileList}
                        multiple={false}
                        onChange={onChange}
                        onPreview={onPreview}
                        maxCount={1}
                    >
                        {fileList.length < 1 && '+ Avatar'}
                    </Upload>
                </ImgCrop>
            </div>
            }
            <div style={{position: 'relative', width: '70%', left: '19%'}}>
            {/* Nom */}

            <Form
                form={form}
            >
            
            <Form.Item style={{ width: '80%' }} name="Nom" label="Nom" help="">
                <Input autoComplete="off" disabled={clientSelected.type_hab === 'Risk management' || userSelected._id === user?._id ? false : true} placeholder={userSelected.lastname} value={current.lastname} onChange={handleChangeLastname} />
            </Form.Item>

           {/* Prénom */}
           <Form.Item style={{ width: '80%' }} name="Prénom" label="Prénom" help="">
                <Input autoComplete="off" disabled={clientSelected.type_hab === 'Risk management' || userSelected._id === user?._id ? false : true} placeholder={userSelected.firstname} value={current.firstname} onChange={handleChangeFirstname} />
            </Form.Item>

           {/* Role */}
           <Form.Item style={{ width: '80%' }} name="Fonction" label="Fonction" help="">                
                <Input autoComplete="off" disabled={clientSelected.type_hab === 'Risk management' || userSelected._id === user?._id ? false : true} placeholder={userSelected.role} value={current.role} onChange={handleChangeRole} />
            </Form.Item>

            {/* email */}
            <Form.Item style={{ width: '80%' }} name="Email" label="Email" help="">
                <Input
                autoComplete="off"
                disabled={clientSelected.type_hab === 'Risk management' || userSelected._id === user?._id ? false : true}
                placeholder={userSelected.email}
                value={current.email}
                prefix={<UserOutlined />}
                onChange={handleChangeEmail}/>
            </Form.Item>

            {/* password */}
            <Form.Item style={{ width: '80%' }} name="Mot de passe" label="Mot de passe" help="">
                <Input.Password
                    autoComplete="new-password"
                    value={current.password}
                    disabled={userSelected._id !== user?._id ? true : false}
                    placeholder="Entrer un nouveau mot de passe"
                    prefix={<LockOutlined />}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={handleChangePassword}
                />
            </Form.Item>

        {/* habilitation */}
            <Form.Item style={{ width: '80%' }} name="Habilitation" label="Habilitation" help="">
                <Select placeholder={userSelected?.habilitations?.find(e=>e.client_hab===clientSelected?._id).type_hab} disabled={clientSelected?.type_hab === 'Risk management' || clientSelected?.type_hab === 'Gouvernance' ? false : true} style={{ width: '100%'}} onChange={handleChangeHabilitation}>
                    {['Risk management', 'Contribution métier', 'Gouvernance', 'Audit'].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                </Select>
            </Form.Item>

            </Form>

            </div>

        {/* avatar, habilitations avec type_hab parmi & client_hab : clientSelected._id */}

            {/* Button */}
            {Object.keys(current).length > 0 &&
            <>
            <div style={{ width: '10vw', position: 'absolute', bottom: '8%', right: '55%' }}><Button specif={'cancel'} texte={'Annuler'} onClick={() => { setCurrent({}); form.resetFields() }}></Button></div>
            <div style={{ width: '12vw', position: 'absolute', bottom: '8%', right: '16%' }}><Button texte={'Enregistrer les modifications'} onClick={() => { handleSaveProfile() }} /></div>
            </>
            }

            </div>        
        </>
    )
    }
    export default ManageProfile;