import React, { useState } from 'react'
import '../public/styles/informations.css'
import { Link } from 'react-router-dom';

import moment from "moment/min/moment-with-locales"

//ant_____________________
import {
    Input,
    Select,
    Form,
    message
} from 'antd';

import {
    UserOutlined,
} from '@ant-design/icons';
import Button from './Button';

moment.locale('fr')

const { Option } = Select

const AddProfile = ({crea, setCrea, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected}) => {

    const [current, setCurrent] = useState({})

//LISTENERS CHECK
    // useEffect(()=>{if (user) {console.log('user in AddProfile : ', user)}}, [user])
    // useEffect(()=>{if (clientSelected) {console.log('clientSelected in AddProfile : ', clientSelected)}}, [clientSelected])
    // useEffect(()=>{if (listFollowed) {console.log('listFollowed in AddProfile : ', listFollowed)}}, [listFollowed])
    // useEffect(()=>{if (listByRisks) {console.log('listByRisks in AddProfile : ', listByRisks)}}, [listByRisks])
    // useEffect(()=>{if (listByProcesses) {console.log('listByProcesses in AddProfile : ', listByProcesses)}}, [listByProcesses])
    // useEffect(()=>{if (stateSelected) {console.log('stateSelected in AddProfile : ', stateSelected)}}, [stateSelected])
    // useEffect(()=>{if (current) {console.log('current in AddProfile : ', current)}}, [current])

    const handleChangeEmail = (e) => {setCurrent({...current, email: e.target.value})}    
    const handleChangeHabilitation = (value) => {setCurrent({...current, habilitations: [{client_hab: clientSelected._id, type_hab: value}]})}

    const successSave = () => {
        message.success('Profil enregistré avec succès 👍');
      };


    const handleSaveProfile = async () => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/new-user`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: current.email,
                habilitations: current.habilitations,
                clientId: clientSelected._id
            })
        })
        const json = await data.json()

        if (json.result) {
            //console.log(json)
            successSave()
            setCurrent({})
        }
    }

    return (
        <>                  
            <div className='containersuivi' style={{justifyContent: 'space-evenly', height: '30%'}}>

            {/* email */}
            <Form.Item style={{ width: '48%' }} tooltip="Email" name="Email" label="Email" hasFeedback validateStatus={current.email ? "success" : ''} help=""
                rules={[
                    {
                        required: true,
                        message: 'Désolé, obligatoire 😁',
                        whitespace: true
                    },
                ]}
            >
            <Input placeholder="Entrer l'e-mail" prefix={<UserOutlined />} onChange={handleChangeEmail}/>
            </Form.Item>

        {/* habilitation */}
            <Form.Item style={{ width: '48%' }} tooltip="Habilitation" name="Habilitation" label="Habilitation" hasFeedback validateStatus={current.habilitations ? "success" : ''} help=""
                rules={[
                    {
                        required: true,
                        message: 'Désolé, obligatoire 😁',
                        whitespace: true
                    },
                ]}
            >
                <Select disabled={clientSelected && clientSelected.type_hab != 'Risk management' ? true : false} style={{ width: '100%'}} placeholder="Sélectionner un type d'habilitation" onChange={handleChangeHabilitation}>
                    {['Risk management', 'Contribution métier', 'Gouvernance', 'Audit'].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                </Select>
            </Form.Item>

            {/* Button */}
            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '50%'}}>
                <div style={{ width: '10vw', marginTop: '5%' }}><Link to={`/create/profile`}><Button specif={'cancel'} texte={'Retour'} onClick={() => { setCurrent({}); setCrea(!crea) }}></Button></Link></div>
                <div style={{ width: '10vw', marginTop: '5%' }}><Button texte={'Enregistrer'} onClick={() => { handleSaveProfile(); setCrea(!crea) }} /></div>
            </div>

            </div>        
        </>
    )
    }
    export default AddProfile;