

// BRUT TO NET

const calculNetSmooth = (proba, impact, min = 1, max = 6) => {

      // Récupérer les valeurs d'impact et de probabilité
      const impactR = parseFloat(impact); // Convertir en nombre
      const probaR = parseFloat(proba); // Convertir en nombre

            // Calculer la plus grande valeur (max) et la plus petite valeur (min) entre l'impact et la probabilité
            const maxValue = Math.max(impactR, probaR);
            const minValue = Math.min(impactR, probaR);

      // Vérifier si la somme de l'impact et de la probabilité est inférieure ou égale à MAX-MIN
      if (impactR + probaR <= Math.round(max-min)) {
            // Retourner 1 si vrai
            return minValue;
      } else {

            // Vérifier si la plus grande valeur est supérieure à la plus petite valeur
            if (maxValue > minValue) {
                  // Si vrai, retourner la plus petite valeur + 1
                  return minValue + 1;
            } else {
                  // Sinon, retourner la plus petite valeur
                  return minValue;
            }
      }

}

module.exports = calculNetSmooth