import React, { useState, useEffect } from 'react'
import '../public/styles/liste.css'

//moment
import moment from "moment/min/moment-with-locales"

//REDUX___________________________________________________
import { useDispatch, useSelector } from 'react-redux'

//antd
import { Button, Input } from "antd";
import "antd/dist/antd.css";

moment.locale('fr')

const Liste = ({stateSelected, data, toggleSelection, otherDatas, dataTitle, typeOfList = 'people', styleAdded}) => {

    //redux
    const dispatch = useDispatch()
    
//    const stateSelected = useSelector(({ stateSelected }) => planSelected)
    //

    const [incidentSelected, setIncidentSelected] = useState()
    const [planSelected, setPlanSelected] = useState()

    const toggleIncident = (i) => {
        setIncidentSelected(i)
    }

    const togglePlan = (i) => {
        setPlanSelected(i)
    }

    //useEffect(()=>{console.log({userInList: otherDatas?.userSelected})}, [otherDatas?.userSelected])

    useEffect(() => {
        //console.log('planSelected in Liste : ', planSelected)
//        if (!planSelected) { dispatch({ type: 'selectstate', state: stateSelected }) }
        if (planSelected) { dispatch({ type: 'selectplan', plan: planSelected }) }
    }, [planSelected])

    useEffect(() => {
        //console.log('incidentSelected in Liste : ', incidentSelected)
        if (incidentSelected) { dispatch({ type: 'selectincident', incident: incidentSelected }) }
    }, [incidentSelected])

    useEffect(()=>{setPlanSelected(); setIncidentSelected()}, [stateSelected])

    return (
        <>
        {typeOfList === 'people' &&
            <div className='list' style={{...styleAdded}}>
                <div className='title'>{dataTitle}</div>
                <div className='listpeople'>
                    {                        
                        data.map((el, i)=>{
                            console.log(data)
                            if ( otherDatas.user?._id === el._id || otherDatas.user?.habilitations.find( e=>e.client_hab?._id === otherDatas.clientSelected?._id ).type_hab === 'Risk management') {
                                return <span key={i} className={otherDatas?.userSelected?._id === el._id ? 'selected' : 'notselected'} onClick={()=>{toggleSelection(el)}}><img className='avatar' src={el.avatar}/> {el.firstname} {el.lastname} </span>
                            }
                        })
                    }
                </div>
            </div>           
        }
        {typeOfList === 'actions' &&
            <div className='list' style={{...styleAdded}}>
                <div className='title'>{dataTitle}</div>
                <div className='listpeople'>
                    {
                        data.slice(0).reverse().map((el, i)=>{return <span key={i} className={planSelected === el ? 'selected' : 'notselected'} onClick={()=>togglePlan(el)}>· {el.objet ? ` ${el.objet}` : ''} {el.status === 'closed' ? `- clos` : `- ouvert le ${moment(el.date_creation).format('LL')}`}</span>})
                    }
                </div>
            </div>           
        }
        {typeOfList === 'incidents' &&
            <div className='list' style={{...styleAdded}}>
                <div className='title'>{dataTitle}</div>
                <div className='listpeople'>
                    {
                        data.slice(0).reverse().map((el, i)=>{return <span key={i} className={incidentSelected === el ? 'selected' : 'notselected'} onClick={()=>toggleIncident(el)}>· Incident enregistré le {moment(el.date_creation).format('LL')} {el.objet ? `- ${el.objet}` : ''}</span>})
                    }
                </div>
            </div>           
        } 
        </>
    )
    }
    export default Liste;