import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import user from './reducers/user.reducer'
import clientSelected from './reducers/clientSelected.reducer'
import listFollowed from './reducers/listFollowed.reducer'
import listByRisks from './reducers/listByRisks.reducer'
import listByProcesses from './reducers/listByProcesses.reducer'
import stateSelected from './reducers/stateSelected.reducer'
import clientUsers from './reducers/clientUsers.reducer'
import allProcessesAndFamilies from './reducers/allProcessesAndFamilies.reducer'
import allRisksAndFamilies from './reducers/allRisksAndFamilies.reducer'
import incidentSelected from './reducers/incidentSelected.reducer'
import planSelected from './reducers/planSelected.reducer'
import auditSelected from './reducers/auditSelected.reducer'
import stateId from './reducers/stateId.reducer'
import audits from './reducers/audits.reducer'

//REDUX PERSIST
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import { PersistGate } from 'redux-persist/integration/react';



// const rootReducer = combineReducers({
//   user,
//   clientSelected,
//   listFollowed,
//   listByRisks,
//   listByProcesses,
//   stateSelected,
//   clientUsers,
//   allProcessesAndFamilies,
//   allRisksAndFamilies,
//   incidentSelected,
//   planSelected,

//});

// Configuration de Redux Persist
// const persistConfig = {
//   key: 'root',
//   storage,
// };

// Création du reducer persistant
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(combineReducers({ audits, stateId, auditSelected, user, planSelected, incidentSelected, clientSelected, listFollowed, listByRisks, listByProcesses, stateSelected, clientUsers, allProcessesAndFamilies, allRisksAndFamilies }))

// Création du store persistant
// const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <App />
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
