import React, { useState, useEffect } from 'react'
import '../public/styles/habilitations.css'
import { useNavigate } from 'react-router-dom'

//REDUX___________________________________________________
import { useDispatch, useSelector } from 'react-redux'

//components
import Liste from './Liste';

//antd
import { Button, Input } from "antd";
import "antd/dist/antd.css";
import AddProfile from './AddProfile';
import ManageProfile from './ManageProfile';

const Habilitations = ({crea, setCrea}) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const clientUsers = useSelector(({ clientUsers }) => clientUsers)
    const clientSelected = useSelector(({ clientSelected }) => clientSelected)
    const user = useSelector(({ user }) => user)

    const [userSelected, setUserSelected] = useState(clientUsers.find(e=>e._id === user._id) ? clientUsers.find(e=>e._id === user._id) : clientUsers[0])

    useEffect(()=>{
        //console.log({userSelected})
        //console.log('fetching users')
        const fetchUsers = async (client) => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/get-users/${client._id}`)
            const json = await data.json()
                if (json.result) {
                    //console.log({json: json.result})
                    if ( !userSelected ) { /*console.log({userSelected})*/; setUserSelected(json.usersFound.find(e=>e._id === user._id)) }
                    dispatch({type: 'clientusers', users: json.usersFound})
                }
        }
        if( clientSelected ) { fetchUsers(clientSelected) }
    }, [userSelected, clientSelected])

    const toggleSelection = (i) => {
        setUserSelected(i)
    }

    return (
        <>
            <div className='containerparam' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>


                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '30%', height: '80%', marginBottom: '5%', marginLeft: '2%', }}>
                        <Liste
                            styleAdded={{width: '100%', height: '100%'}}
                            data={clientUsers.filter((e)=>e._id != "62699350bf7c7ded37e4dfdb")}
                            otherDatas={{userSelected: userSelected, user: user, clientSelected: clientSelected}}
                            toggleSelection={toggleSelection}
                            dataTitle={'Personnes'}
                        />
 
                    {clientSelected?.type_hab === 'Risk management' &&
                        <div className='link' style={{ width: '15%', position: 'absolute', left: '22.5%', bottom: '15.5%'}} onClick={()=>{setCrea(!crea)}}>Nouvel utilisateur</div>
                    }
                </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '60%', marginBottom: '5%', marginRight: '3%', height: '80%', outline: '1px solid #726468'}}>
                    {userSelected &&
                    <>
                        <ManageProfile user={user} clientSelected={clientSelected} userSelected={userSelected} setUserSelected={setUserSelected}/>
                    
                    </>
                    }
                    </div>

                    <div style={{ position: 'absolute', bottom: '9.5%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '44vw' }}>
                            <div style={{ width: '10vw' }}><Button specif={'cancel'} texte={'Retour'} onClick={() => { navigate(-1) }}></Button></div>
                    </div>


            </div>
        </>
    )
    }
    export default Habilitations;