

// FORMAT CODES OF FAMILIES, PROCESSES AND RISKS


// FORMAT CODES OF FAMILIES, PROCESSES AND RISKS

const formatCodeNew = (process, family, number) => {
            
      let code;

      let pro = null;
      let fam = null;

      if (process === 'Moyens généraux') {pro = 'GEN'}
      else if (process === `Système d'information` || process === `Systèmes d'information`) {pro = 'SI'}
      else if (process === 'Ressources humaines') {pro = 'RH'}
      else if (process === 'Comptabilité et finances' || process === 'Comptabilité et finance') {pro = 'FIN'}
      else if (process === 'Suivi et traitement des dossiers') {pro = 'REA'}
      else if (process === 'Partenariats extérieurs') {pro = 'PAR'}
      else if (process === 'Circuit décisionnel') {pro = 'DEC'}
      else if (process === 'Opérationnel - Gestion') {pro = 'OP'}
      else if (process === 'Gouvernance') {pro = 'GOV'}
      else if (process === 'Règlementaire - Conformité') {pro = 'REG'}
      else {pro = process?.slice(0,3).toUpperCase()}

      if (family === 'Moyens généraux') {fam = 'GEN'}
      else if (family === `Système d'information` || family === `Systèmes d'information`) {fam = 'SI'}
      else if (family === `Ressources humaines`) {fam = 'RH'}
      else if (family === `Suivi et traitement des dossiers`) {fam = 'REA'}
      else if (family === `Partenariats extérieurs`) {fam = 'PAR'}
      else if (family === `Gouvernance`) {fam = 'GOV'}
      else if (family === `Pécuniaires`) {fam = 'PEC'}
      else if (family === `Fraude`) {fam = 'FRA'}
      else if (family === `Majeur et d'image`) {fam = 'MAJ'}
      else if (family === `Europe`) {fam = 'EUR'}
      else {fam = family?.slice(0,3).toUpperCase()}


      if (fam === pro || family === process || fam === process || pro === family) {
            code = `${pro}·${number >= 100 ? number : number >= 10 ? '0'+number : '00'+number}`
      } else {
            code = `${pro ? pro : process.slice(0,3).toUpperCase()}·${fam ? fam : family.slice(0,3).toUpperCase()}·${number >= 100 ? number : number >= 10 ? '0'+number : '00'+number}`
      }

      return code     
  
}

    module.exports = formatCodeNew