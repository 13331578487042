import React, { useState, useEffect, useRef } from 'react'
import Xarrow from "react-xarrows";
import '../public/styles/farmergraph.css'
import { formatCodeNew, short } from "../helpers"
import { CloseOutlined } from '@ant-design/icons';


const FarmerGraph = ({ toggleNum, setStateSelected, clientSelected, dataSet, handleChangeDataSet, sliderBest, brut, net, listFollowed, familyFilter, processFilter, filters }) => {

    const [hover, setHover] = useState('')

    const [aleaGraphs, setAleaGraphs] = useState({})

    const [alterEgo, setAlterEgo] = useState('')
    //useEffect(()=>{console.log({alterEgo})}, [alterEgo])

    useEffect(()=>{
        let temp = {}
        let aleaA = (Math.round(Math.random()) * 2 - 1)*(Math.random()*3.5);
            temp=({...temp, a: aleaA})
        let aleaB = (Math.round(Math.random()) * 2 - 1)*(Math.random()*3.5);
            temp=({...temp, b: aleaB})
        setAleaGraphs(temp)
    }, [])

    //useEffect(()=>{console.log({aleaGraphs})}, [aleaGraphs])

//LISTENER dataSet
    //useEffect(()=>{console.log({dataSet})}, [dataSet])

    useEffect(()=>{
        
        const filterData = async () => {

            let temp = [...listFollowed];

        //1 - je vais en base pour les 3 derniers filtres

            // const data = await fetch(`${process.env.REACT_APP_BACK_END}/states/filter`, {
            //     method: 'PUT',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({filters: filters, clientId: clientSelected?._id})
            // })
            // const json = await data.json()

            // if(json.result) {
            //     //console.log({resultatBack: json.statesToSendFront})
            //     temp = json.statesToSendFront
            // }
        

        //1 bis je prends dans listFollowed pour avoir les populates pour la suite

        if ( filters.objectif ) { temp = temp.filter(e=>e.objectif === filters.objectif) }
        
        if ( filters.modes_intervention ) { temp = temp.filter(e=>e.modes_intervention.find(f=>f === filters.modes_intervention)) }

        if ( filters.domaines_competence ) { temp = temp.filter(e=>e.domaines_competence.find(f=>f === filters.domaines_competence)) }

        
        //console.log({temp1 : temp})

        //2 - je me sers de listByProcess et listByFamily pour filtrer les deux premiers filtres

        if ( familyFilter ) { temp = temp.filter(e=>e.risk?.family?.libelle === familyFilter) }

        if ( processFilter ) { temp = temp.filter(e=>e.process?.libelle === processFilter) }

        //console.log({temp2 : temp})

        //3 - Je sort puis slice en fonction des attentes des sliders
        
        temp.sort( (a, b) => { return (a.lvl_impact.net+a.lvl_proba.net-a.lvl_maitrise) - (b.lvl_impact.net+b.lvl_proba.net-b.lvl_maitrise) } )

        if ( sliderBest ) { temp = temp.reverse().slice(0, sliderBest) }

        //console.log({temp3 : temp})

        //4- Je renvois au setter
        handleChangeDataSet(temp) 

        }
        filterData()

    }, [filters, familyFilter, processFilter, sliderBest])

    //function display data for each grid 6x6
    const displayData = (data, proba, impact) => {
        return data.map((e, i)=>{
            if(e.lvl_impact?.brut === impact && e.lvl_proba?.brut === proba && e.lvl_impact?.net === impact && e.lvl_proba?.net === proba) {
                return <div className='point' onMouseEnter={()=>{setHover(e._id)}} onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseLeave={()=>{setHover('')}} etat='NET' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`} key={e._id+'both'} style={{position: 'relative', opacity: hover === e._id ? 0.8 : 1, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 5, backgroundColor: '#CCC', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: 3, padding: '0px 5px 0px 5px', color: '#222'}}>{e.intitule.length > 50 ? e.intitule.slice(0,50)+'...' : e.intitule}</div>
            } else if(e.lvl_impact?.brut === impact && e.lvl_proba?.brut === proba) {
                return <div id={`${e.intitule}`+'b'} className='point' onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseEnter={()=>{setHover(e._id)}} onMouseLeave={()=>{setHover('')}} etat='BRUT' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.brut} impact={e.lvl_impact.brut} name={`${e.intitule}`} key={e._id+'brut'} style={{position: 'relative', opacity: brut ? hover === e._id ? 0.8 : 1 : 0, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 1, backgroundColor: '#FFF', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: 3, padding: '0px 5px 0px 5px', color: '#222'}}>{e.intitule.length > 50 ? e.intitule.slice(0,50)+'...' : e.intitule}</div>
            } else if(e.lvl_impact?.net === impact && e.lvl_proba?.net === proba) {
                return (<><div id={`${e.intitule}`+'n'} className='point' onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseEnter={()=>{setHover(e._id)}} onMouseLeave={()=>{setHover('')}} etat='NET' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`} key={e._id+'net'} style={{position: 'relative', opacity: net ? hover === e._id ? 0.8 : 1 : 0, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 1, backgroundColor: '#CCC', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: 3, padding: '0px 5px 0px 5px', color: '#222'}}>{e.intitule.length > 50 ? e.intitule.slice(0,50)+'...' : e.intitule}</div>
                {/* {brut && net &&
                <Xarrow
                start={`${e.intitule}`+'b'}
                end={`${e.intitule}`+'n'}
                color='#111'
                strokeWidth={1}
                headShape={'arrow1'}
                curveness={0}
                dashness={true}
                path={'straight'}
                animateDrawing={true}
                />
                } */}
            </>)
            }
        })
    }

    const displayDataDots = (data) => {
        return data.map((e, i)=>{
            //console.log(data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id))

            if(e.lvl_impact?.brut === e.lvl_impact?.net && e.lvl_proba?.brut === e.lvl_proba?.net) {
                return <div className='pointsup' onMouseEnter={()=>{setHover(e._id)}} onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseLeave={()=>{setHover('')}} etat='NET' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`} key={e._id+'both'}
                    style={{justifyContent: 'center', position: 'absolute', opacity: hover === e._id ? 0.8 : 1, width: 20, height: 20, left: `${e.lvl_impact.net/6*95+(data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24) > 95 ? 95 : e.lvl_impact.net/6*95+data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24}%`, bottom: `${e.lvl_proba.net/6*95+data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24 > 95 ? 95 : e.lvl_proba.net/6*95+data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24}%`, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 5, backgroundColor: '#CCC', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: '50%', padding: '0px 5px 0px 5px', color: '#222'}}>
                    <span style={{position: 'absolute', right: '25px', top: 2}}>{formatCodeNew(e.process.libelle, e.risk?.family.libelle, e.risk.code)}</span>
                </div>
            } else {
                return (
                <>
                        <div id={`${e.intitule}`+'b'} className='pointsup' onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseEnter={()=>{setHover(e._id)}} onMouseLeave={()=>{setHover('')}} etat='BRUT' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.brut} impact={e.lvl_impact.brut} name={`${e.intitule}`} key={e._id+'brut'}
                            style={{justifyContent: 'center', position: 'absolute', opacity: brut ? hover === e._id ? 0.8 : 1 : 0, width: 20, height: 20, left: `${e.lvl_impact.brut/6*95+data.filter(f=>e.lvl_impact.brut === f.lvl_impact.brut && e.lvl_proba.brut === f.lvl_proba.brut).findIndex(g=>g._id === e._id)*2-100/24 > 95 ? 95 : e.lvl_impact.brut/6*95+data.filter(f=>e.lvl_impact.brut === f.lvl_impact.brut && e.lvl_proba.brut === f.lvl_proba.brut).findIndex(g=>g._id === e._id)*2-100/24}%`, bottom: `${e.lvl_proba.brut/6*95+data.filter(f=>e.lvl_impact.brut === f.lvl_impact.brut && e.lvl_proba.brut === f.lvl_proba.brut).findIndex(g=>g._id === e._id)*2-100/24 > 95 ? 95 : e.lvl_proba.brut/6*95+data.filter(f=>e.lvl_impact.brut === f.lvl_impact.brut && e.lvl_proba.brut === f.lvl_proba.brut).findIndex(g=>g._id === e._id)*2-100/24}%`, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 1, backgroundColor: '#FFF', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: '50%', padding: '0px 5px 0px 5px', color: '#222'}}>
                            <span style={{position: 'absolute', right: '25px', top: 2}}>{formatCodeNew(e.process.libelle, e.risk?.family.libelle, e.risk.code)}</span>
                        </div>
                        
                        <div id={`${e.intitule}`+'n'} className='pointsup' onClick={()=>{ toggleNum(0); setStateSelected(e) }} onMouseEnter={()=>{setHover(e._id)}} onMouseLeave={()=>{setHover('')}} etat='NET' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`} key={e._id+'net'}
                            style={{justifyContent: 'center', position: 'absolute', opacity: net ? hover === e._id ? 0.8 : 1 : 0, width: 20, height: 20, left: `${e.lvl_impact.net/6*95+(data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24) > 95 ? 95 : e.lvl_impact.net/6*95+data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24}%`, bottom: `${e.lvl_proba.net/6*95+data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24 > 95 ? 95 : e.lvl_proba.net/6*95+data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id)*2-100/24}%`, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 1, backgroundColor: '#CCC', outline: hover === e._id ? '1px solid #222' : '0px solid #222', borderRadius: '50%', padding: '0px 5px 0px 5px'}}>
                            <span style={{position: 'absolute', right: '25px', top: 2}}>{formatCodeNew(e.process.libelle, e.risk?.family.libelle, e.risk.code)}</span>
                        </div>
                </>    
            )
            }
        })
    }
    
    const displayDataDotsBig = (data) => {
        return data.map((e, i)=>{
            //console.log(data.filter(f=>e.lvl_impact.net === f.lvl_impact.net && e.lvl_proba.net === f.lvl_proba.net).findIndex(g=>g._id === e._id))

                //calculs liés au point brut
                let listFilteredForPointBrut = data.filter(f=>e.lvl_proba?.brut === f.lvl_proba?.brut && e.lvl_impact?.brut === f.lvl_impact?.brut)
                let isTheFirstBrut = listFilteredForPointBrut.findIndex(g=>g._id === e._id) === 0 ? true : false
                //console.log({listFilterdForPointBrut: listFilteredForPointBrut.length, isTheFirstBrut})

                //calculs liés au point net
                let listFilteredForPointNet = data.filter(f=>e.lvl_proba?.net === f.lvl_proba?.net && e.lvl_impact?.net === f.lvl_impact?.net)
                let isTheFirstNet = listFilteredForPointNet.findIndex(g=>g._id === e._id) === 0 ? true : false
                //console.log({listFilterdForPointNet: listFilteredForPointNet.length, isTheFirstNet})

                return (
                <>
                {isTheFirstBrut &&
                <div className={hover.length === 1 ? 'pointsup' : 'pointsimple'} onClick={()=>{ if(hover.length === 1) {toggleNum(0); setStateSelected(e)} }} name={`${e.intitule}`} etat='Brut' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.brut} impact={e.lvl_impact.brut} code={`${formatCodeNew(e.process.libelle, e.risk?.family.libelle, e.risk.code)}`} onMouseEnter={()=>{setHover(listFilteredForPointBrut)}} key={`${e._id}-brut`} style={{ position: 'absolute', width: 20+listFilteredForPointBrut.length*3, height: 20+listFilteredForPointBrut.length*3, left: `${e.lvl_impact?.brut/6*95+data.filter(f=>e.lvl_proba?.brut === f.lvl_proba?.brut && e.lvl_impact?.brut === f.lvl_impact?.brut).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.a*(i%2 === 0 ? -1 : 1)) > 95 ? 95 : e.lvl_impact?.brut/6*95+data.filter(f=>e.lvl_proba?.brut === f.lvl_proba?.brut && e.lvl_impact?.brut === f.lvl_impact?.brut).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.a*(i%2 === 0 ? -1 : 1))}%`, bottom: `${e.lvl_proba?.brut/6*95+data.filter(f=>e.lvl_proba?.brut === f.lvl_proba?.brut && e.lvl_impact?.brut === f.lvl_impact?.brut).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.b*(i%2 === 0 ? -1 : 1)) > 95 ? 95 : e.lvl_proba?.brut/6*95+data.filter(f=>e.lvl_proba?.brut === f.lvl_proba?.brut && e.lvl_impact?.brut === f.lvl_impact?.brut).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.b*(i%2 === 0 ? -1 : 1))}%`, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 5, backgroundColor: '#FFF', borderRadius: '50%', padding: '0px 5px 0px 5px', color: '#222', display: brut ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{fontSize: 7+listFilteredForPointBrut.length}}>{listFilteredForPointBrut.length > 1 ? listFilteredForPointBrut.length : ''}</span>
                    {hover.length > 1 && hover[0].lvl_proba?.brut === e.lvl_proba?.brut && hover[0].lvl_impact?.brut === e.lvl_impact?.brut &&
                    <div style={{zIndex: 50, width: '4.5vw', borderRadius: 5, padding: 7, backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', right: 25+listFilteredForPointBrut.length*3}}>
                        <CloseOutlined style={{ cursor: 'pointer', position: 'absolute', top: `calc('50%-8px')`, left: 3, fontSize: 8, color: '#111'}} onClick={()=>{setHover([])}}/>
                        {hover.sort((a, b)=>{return a.risk.code-b.risk.code}).map((d)=>{    
                            return (
                            <span onMouseEnter={()=>{ if (d.lvl_impact?.brut !== d.lvl_impact?.net || d.lvl_proba?.brut !== d.lvl_proba?.brut) { setAlterEgo(d) } } } onMouseLeave={()=>{setAlterEgo()}} style={{padding: '0px 3px'}} key={d._id} name={`${d.intitule}`} etat='Brut' maitrise={d.lvl_maitrise} probabilité={d.lvl_proba.brut} impact={d.lvl_impact.brut} code={`${formatCodeNew(d.process.libelle, d.risk?.family.libelle, d.risk.code)}`} className='pointsup' onClick={()=>{ toggleNum(0); setStateSelected(d) }}>{formatCodeNew(d.process.libelle, d.risk?.family.libelle, d.risk.code)}</span>
                            )
                        })
                        }
                    </div>
                    }                    
                </div>
                }
                
                {isTheFirstNet &&
                <div className={hover.length === 1 ? 'pointsup' : 'pointsimple'} onClick={()=>{ if(hover.length === 1) {toggleNum(0); setStateSelected(e)} }} name={`${e.intitule}`} etat='Net' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} code={`${formatCodeNew(e.process.libelle, e.risk?.family.libelle, e.risk.code)}`} onMouseEnter={()=>{setHover(listFilteredForPointNet)}} key={`${e._id}-net`} style={{ position: 'absolute', width: 20+listFilteredForPointNet.length*3, height: 20+listFilteredForPointNet.length*3, left: `${e.lvl_impact?.net/6*95+data.filter(f=>e.lvl_proba?.net === f.lvl_proba?.net && e.lvl_impact?.net === f.lvl_impact?.net).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.a*(i%2 === 0 ? -1 : 1)) > 95 ? 95 : e.lvl_impact?.net/6*95+data.filter(f=>e.lvl_proba?.net === f.lvl_proba?.net && e.lvl_impact?.net === f.lvl_impact?.net).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.a*(i%2 === 0 ? -1 : 1))}%`, bottom: `${e.lvl_proba?.net/6*95+data.filter(f=>e.lvl_proba?.net === f.lvl_proba?.net && e.lvl_impact?.net === f.lvl_impact?.net).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.b*(i%2 === 0 ? -1 : 1)) > 95 ? 95 : e.lvl_proba?.net/6*95+data.filter(f=>e.lvl_proba?.net === f.lvl_proba?.net && e.lvl_impact?.net === f.lvl_impact?.net).findIndex(g=>g._id === e._id)*2-100/24+(aleaGraphs.a*(i%2 === 0 ? -1 : 1))}%`, cursor: 'pointer', textAlign: 'center', fontSize: 10, margin: 5, backgroundColor: '#CCC', borderRadius: '50%', padding: '0px 5px 0px 5px', color: '#222', display: net ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{fontSize: 7+listFilteredForPointNet.length}}>{listFilteredForPointNet.length > 1 ? listFilteredForPointNet.length : ''}</span>
                    {hover.length > 1 && hover[0].lvl_proba?.net === e.lvl_proba?.net && hover[0].lvl_impact?.net === e.lvl_impact?.net &&
                    <div style={{zIndex: 50, width: '4.5vw', borderRadius: 5, padding: 7, backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', right: 25+listFilteredForPointNet.length*3}}>
                        <CloseOutlined style={{cursor: 'pointer', position: 'absolute', top: `calc('50%-8px')`, left: 3, fontSize: 8, color: '#111'}} onClick={()=>{setHover([])}}/>
                        {hover.sort((a, b)=>{return a.risk.code-b.risk.code}).map((d)=>{    
                            return (
                            <span onMouseEnter={()=>{ if (d.lvl_impact?.brut !== d.lvl_impact?.net || d.lvl_proba?.brut !== d.lvl_proba?.brut) { setAlterEgo(d) } } } onMouseLeave={()=>{setAlterEgo()}} style={{padding: '0px 3px'}} key={d._id} name={`${d.intitule}`} etat='Net' maitrise={d.lvl_maitrise} probabilité={d.lvl_proba.net} impact={d.lvl_impact.net} code={`${formatCodeNew(d.process.libelle, d.risk?.family.libelle, d.risk.code)}`} className='pointsup' onClick={()=>{ toggleNum(0); setStateSelected(d) }}>{formatCodeNew(d.process.libelle, d.risk?.family.libelle, d.risk.code)}</span>
                            )
                        })
                        }
                    </div>
                    }
                </div>
                }
                </>
                )
        
            
            
        })
    } 

return (
    <>
        <div style={{position: 'relative', width:'100%', height: '100%'}}>

            {/* fonds */}
            <div className='fond'>

                <div className='one'></div>
                <div className='two'></div>
                <div className='three'></div>
                <div className='four'></div>
                <div className='five'></div>
                <div className='six'></div>
                <div className='seven'></div>
                <div className='eight'></div>
                <div className='nine'></div>
                <div className='ten'></div>
                <div className='eleven'></div>
                <div className='twelve'></div>
                <div className='thirteen'></div>
                <div className='fourteen'></div>
                <div className='fifteen'></div>
                <div className='sixteen'></div>

            </div>

            {/* grid names if < */}
            {dataSet.length < 10 &&
            <div className='board'>

                <div className='cell11'>{displayData(dataSet, 1, 1)}</div><div className='cell12'>{displayData(dataSet, 1, 2)}</div><div className='cell13'>{displayData(dataSet, 1, 3)}</div><div className='cell14'>{displayData(dataSet, 1, 4)}</div><div className='cell15'>{displayData(dataSet, 1, 5)}</div><div className='cell16'>{displayData(dataSet, 1, 6)}</div>
                <div className='cell21'>{displayData(dataSet, 2, 1)}</div><div className='cell22'>{displayData(dataSet, 2, 2)}</div><div className='cell23'>{displayData(dataSet, 2, 3)}</div><div className='cell24'>{displayData(dataSet, 2, 4)}</div><div className='cell25'>{displayData(dataSet, 2, 5)}</div><div className='cell26'>{displayData(dataSet, 2, 6)}</div>
                <div className='cell31'>{displayData(dataSet, 3, 1)}</div><div className='cell32'>{displayData(dataSet, 3, 2)}</div><div className='cell33'>{displayData(dataSet, 3, 3)}</div><div className='cell34'>{displayData(dataSet, 3, 4)}</div><div className='cell35'>{displayData(dataSet, 3, 5)}</div><div className='cell36'>{displayData(dataSet, 3, 6)}</div>
                <div className='cell41'>{displayData(dataSet, 4, 1)}</div><div className='cell42'>{displayData(dataSet, 4, 2)}</div><div className='cell43'>{displayData(dataSet, 4, 3)}</div><div className='cell44'>{displayData(dataSet, 4, 4)}</div><div className='cell45'>{displayData(dataSet, 4, 5)}</div><div className='cell46'>{displayData(dataSet, 4, 6)}</div>
                <div className='cell51'>{displayData(dataSet, 5, 1)}</div><div className='cell52'>{displayData(dataSet, 5, 2)}</div><div className='cell53'>{displayData(dataSet, 5, 3)}</div><div className='cell54'>{displayData(dataSet, 5, 4)}</div><div className='cell55'>{displayData(dataSet, 5, 5)}</div><div className='cell56'>{displayData(dataSet, 5, 6)}</div>
                <div className='cell61'>{displayData(dataSet, 6, 1)}</div><div className='cell62'>{displayData(dataSet, 6, 2)}</div><div className='cell63'>{displayData(dataSet, 6, 3)}</div><div className='cell64'>{displayData(dataSet, 6, 4)}</div><div className='cell65'>{displayData(dataSet, 6, 5)}</div><div className='cell66'>{displayData(dataSet, 6, 6)}</div>

            </div>
            }
            {/* dots if > */}
            {dataSet.length >= 10 &&
                <div className='board'>
                    {displayDataDotsBig(dataSet)}
                    {alterEgo && brut &&
                    <div style={{position: 'absolute', outline: '1px solid #222', borderRadius: '50%', backgroundColor: '#CCC', height: 30, width: 30, left: `${alterEgo.lvl_impact?.net/6*95 > 95 ? 95 : alterEgo.lvl_impact?.net/6*95}%`, bottom: `${alterEgo.lvl_proba?.net/6*95 > 95 ? 95 : alterEgo.lvl_proba?.net/6*95}%`}}></div>
                    }
                    {alterEgo && net &&
                    <div style={{position: 'absolute', outline: '1px solid #222', borderRadius: '50%', backgroundColor: '#FFF', height: 30, width: 30, left: `${alterEgo.lvl_impact?.brut/6*95 > 95 ? 95 : alterEgo.lvl_impact?.brut/6*95}%`, bottom: `${alterEgo.lvl_proba?.brut/6*95 > 95 ? 95 : alterEgo.lvl_proba?.brut/6*95}%`}}></div>
                    }
                </div>    
            }
            {/* points */}
            {/* <div>

            {dataSet.map((risk, i)=>{   
                return (
                    <>
                    {risk.lvl_proba.brut && risk.lvl_impact.brut &&
                    <>
                        {brut && dataSet.filter(e=>e.lvl_impact.brut === risk.lvl_impact.brut && e.lvl_proba.brut === risk.lvl_proba.brut).length === 1 &&
                        <div id={`${risk.intitule}`+'b'} etat='Brut' maitrise={risk.lvl_maitrise} probabilité={risk.lvl_proba.brut} impact={risk.lvl_impact.brut} name={`${risk.intitule}`+' - Brut'} className='point' style={{fontSize: 10, maxWidth: 80, margin: 2, fontWeight: 'normal', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#FFF', border: '0px solid #DDD',
                        position: 'relative', left: `${risk.lvl_impact.brut/6*96}%`, bottom: `${(risk.lvl_proba.brut/6*95)}%`,
                        borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222'}}>{risk.intitule}</div>
                        }
                        {brut && dataSet.filter(e=>e.lvl_impact.brut === risk.lvl_impact.brut && e.lvl_proba.brut === risk.lvl_proba.brut).length > 1 &&
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative',
                        fontSize: 10, maxWidth: 80, margin: 2, fontWeight: 'normal', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#FFF', border: '0px solid #DDD', borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222',
                        left: `${risk.lvl_impact.brut/6*96}%`, bottom: `${(risk.lvl_proba.brut/6*95)}%`,}}>
                        {dataSet.filter(e=>e.lvl_impact.brut === risk.lvl_impact.brut && e.lvl_proba.brut === risk.lvl_proba.brut).map((e)=>{return <span id={`${e.intitule}`+'b'} etat='Brut' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.brut} impact={e.lvl_impact.brut} name={`${e.intitule}`+' - Brut'} className='point'>· {risk.intitule}</span>})
                        }
                        </div>
                        }
                        {net && dataSet.filter(e=>e.lvl_impact.net === risk.lvl_impact.net && e.lvl_proba.net === risk.lvl_proba.net).length === 1 &&
                        <div id={`${risk.intitule}`+'n'} etat='Net' maitrise={risk.lvl_maitrise} probabilité={risk.lvl_proba.net} impact={risk.lvl_impact.net} name={`${risk.intitule}`+' - Net'} className='point' style={{fontSize: 10, maxWidth: 80, fontWeight: 'bold', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#CCC', border: '0px solid #222',
                        position: 'relative', left: `${risk.lvl_impact.net/6*96}%`, bottom: `${(risk.lvl_proba.net/6*95)}%`,
                        borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222'}}>{risk.intitule}</div>
                        }
                        {net && dataSet.filter(e=>e.lvl_impact.net === risk.lvl_impact.net && e.lvl_proba.net === risk.lvl_proba.net).length > 1 &&
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative',
                        fontSize: 10, maxWidth: 80, margin: 2, fontWeight: 'normal', zIndex: 'auto', textAlign: 'center', color: '#222', cursor: 'pointer', backgroundColor: '#CCC', border: '0px solid #222', borderRadius: 3, padding: '0px 5px 0px 5px', boxShadow: '0px 1px 1px 1px #222',
                        left: `${risk.lvl_impact.net/6*96}%`, bottom: `${(risk.lvl_proba.net/6*95)}%`,}}>
                        {dataSet.filter(e=>e.lvl_impact.net === risk.lvl_impact.net && e.lvl_proba.net === risk.lvl_proba.net).map((e)=>{return <span id={`${e.intitule}`+'b'} etat='Brut' maitrise={e.lvl_maitrise} probabilité={e.lvl_proba.net} impact={e.lvl_impact.net} name={`${e.intitule}`+' - Brut'} className='point'>· {risk.intitule}</span>})
                        }
                        </div>
                        }
                        {(risk.lvl_impact.net != risk.lvl_impact.brut || risk.lvl_proba.net != risk.lvl_proba.brut) && (brut && net) &&
                        <Xarrow
                            start={`${risk.intitule}`+'b'}
                            end={`${risk.intitule}`+'n'}
                            color='#111'
                            strokeWidth={1}
                            curveness={0}
                            dashness={true}
                        />                        
                        }
                    </>
                    }
                    </>
                )
            })
            }

            </div> */}


        </div>
    </>
)
}
export default FarmerGraph;