import React, { useState, useEffect } from 'react'
import '../public/styles/score.css'

const ScoreDotsParametrable = ({/*maitriseDots,*/ clientSelected, value, length = 40, width = 30, height = 30, cursorSize = 8, police = 10, editMode = false, reverse = false, toggleScore, start = 1, end = 6 }) => {


    //if value isInteger()

    //console.log({clientSelected}, clientSelected?.start_amplitude, clientSelected?.end_amplitude)

    //reverse ? "linear-gradient(90deg, #49BC17 0%, rgba(150,249,7,1) 25%, rgba(249,230,8,1) 50%, rgba(250,144,6,1) 75%, rgba(247,32,5,1) 100%)" : "linear-gradient(90deg, rgba(247,32,5,1) 0%, rgba(250,144,6,1) 25%, rgba(249,230,8,1) 50%, rgba(150,249,7,1) 75%, #49BC17 100%)"

    if (clientSelected?.start_amplitude) { start = clientSelected?.start_amplitude }
    if (clientSelected?.end_amplitude) { end = clientSelected?.end_amplitude }

    ////GESTION REVERT ICI//// if(clientSelected.revert_maitrise_scale && maitriseDots) { reverse = true }
    //// PLUS ADD DANS TOUS LES SPANS LE ///
    // PAS DE GESTION REVERSE TOTALE (SCORE + COULEUR) DANS LA MESURE OU LES CALCULS ENSUITE SE FERONT 

    //console.log(start, end)

    if (Math.round(value) < start) { value = start }
    if (Math.round(value) > end) { value = end }

    const [hover, setHover] = useState()

    const spans = () => {
        let array = [];

        for (let i = start - 1; i < end; i++) {
            array.push(
                <div key={i} title={value} style={{ marginTop: 10, width: length, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                    <span key={i} style={{ cursor: 'pointer', fontWeight: 'bolder', color: '#FFF', fontSize: String(Math.round(value)) == String(Math.round(i + 1)) ? 22 : 16, textAlign: 'center', lineHeight: String(Math.round(value)) == String(Math.round(i + 1)) ? `${height * 1.2}px` : `${height}px`, width: String(Math.round(value)) == String(Math.round(i + 1)) ? width * 1.2 : width, height: String(Math.round(value)) == String(Math.round(i + 1)) ? height * 1.2 : height, borderRadius: '50%', backgroundColor: `${determineColor(i)}`, margin: `0px ${length / ((end + 1 - start) * width)}px`, outline: `${String(Math.round(value)) == String(Math.round(i + 1)) ? '2px solid #999' : ''}` }} >{i + 1}</span>
                </div>

            )
        }

        return array

    }

    const editableSpans = () => {

        let array = [];

        for (let i = start - 1; i < end; i++) {
            array.push(
                <div key={i} title={value} style={{ marginTop: 10, width: length, height: 50, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                    <span key={i} onMouseEnter={() => { setHover(i) }} onMouseLeave={() => { setHover() }} onClick={() => { toggleScore(i) }} style={{ opacity: hover === i ? 0.7 : 1, cursor: 'pointer', fontWeight: 'bolder', color: '#FFF', fontSize: String(Math.round(value)) == String(Math.round(i + 1)) ? 17 : 14, textAlign: 'center', lineHeight: String(Math.round(value)) == String(Math.round(i + 1)) ? `${height * 1.2}px` : `${height}px`, width: String(Math.round(value)) == String(Math.round(i + 1)) ? width * 1.2 : width, height: String(Math.round(value)) == String(Math.round(i + 1)) ? height * 1.2 : height, borderRadius: '50%', backgroundColor: `${determineColor(i)}`, margin: `0px ${length / ((end + 1 - start) * width)}px`, outline: `${String(Math.round(value)) == String(Math.round(i + 1)) ? '2px solid #999' : ''}` }} >{i + 1}</span>
                </div>
            )
        }

        return array

    }

    const determineColor = (i) => {

        if (clientSelected?.end_amplitude - clientSelected?.start_amplitude === 3) {
            // console.log(i)
            if (!reverse) {
                if (i + 1 <= 1) {
                    return '#D4111E'
                } else if (i + 1 <= 2) {
                    return '#f48c06'
                } else if (i + 1 <= 3) {
                    return '#FEED34'
                } else {
                    return '#49BC17'
                }
            } else if (reverse) {
                if (i + 1 <= 0.25 * ((end - start) + start)) {
                    return '#49bc17'
                } else if (i + 1 <= 0.50 * ((end - start) + start)) {
                    return '#FEED34'
                } else if (i + 1 <= 0.75 * ((end - start) + start)) {
                    return '#F48C06'
                } else {
                    return '#D4111E'
                }
            }

        } else {

            if (!reverse) {
                if (i <= 0.16 * ((end - start) + start)) {
                    return '#d4111e'
                    // } else if (i <= 2*(end+1-start)/10) {
                    //     return '#d71f22' 
                    // } else if (i <= 3*(end+1-start)/10) {
                    //     return '#d92c25'
                } else if (i <= 0.32 * ((end - start) + start)) {
                    return '#dd462b'
                } else if (i <= 0.48 * ((end - start) + start)) {
                    return '#f48c06'
                } else if (i <= 0.64 * ((end - start) + start)) {
                    return '#feed34'
                    // } else if (i <= 7*(end+1-start)/10) {
                    //     return '#cbda42'
                } else if (i <= 0.80 * ((end - start) + start)) {
                    return '#a0d034'
                    // } else if (i <= 9*(end+1-start)/10) {
                    //     return '#75c626'
                } else {
                    return '#49bc17'
                }
            } else if (reverse) {
                if (i <= 0.16 * ((end - start) + start)) {
                    return '#49bc17'
                    //        } else if (i <= 2/(end+1-start)) {
                    //            return '#75c626'
                } else if (i <= 0.32 * ((end - start) + start)) {
                    return '#a0d034'
                    //        } else if (i <= 4/(end+1-start)) {
                    //            return '#cbda42'
                } else if (i <= 0.48 * ((end - start) + start)) {
                    return '#feed34'
                } else if (i <= 0.64 * ((end - start) + start)) {
                    return '#f48c06'
                } else if (i <= 0.80 * ((end - start) + start)) {
                    return '#dd462b'
                    //        } else if (i <= 8/(end+1-start)) {
                    //          return '#d92c25'
                    //        } else if (i <= 9/(end+1-start)) {
                    //            return '#d71f22'
                } else {
                    return '#d4111e'
                }
            }
        }
    }

    return (
        <>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {!editMode &&
                    spans()
                }
                {editMode &&
                    editableSpans()
                }
            </div>

        </>
    )


}

export default ScoreDotsParametrable;