import { FilePdfTwoTone } from '@ant-design/icons';
import React, { useState, useEffect } from 'react'
import '../public/styles/farmer.css'
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { ReactPDF, PDFDownloadLink } from '@react-pdf/renderer';


const Rapport = ({ divid, listFollowed, toggleDrawerReport }) => {

//////////////JSPDF

    // const generatePDF = () => {
    //     const report = new JsPDF('portrait','pt','a4');
    //     report.html(document.querySelector(`#${divid}`)).then(() => {
    //         report.save('report.pdf');
    //     });
    // }

//////////////JSPDF + HTML2CANVA

    const generatePDF = () =>{
    
        html2canvas(document.querySelector(`#${divid}`)).then(canvas => {
          let dataURL = canvas.toDataURL('image/png');
    
            const pdf = new JsPDF({
              orientation: "landscape",
              unit: "in",
              format: [10, 14]
            });
    
            pdf.addImage(dataURL, 'PNG', 1, 1);
            pdf.save(`${divid}.pdf`);
    
        });
     }


//////////////REACT PDF

    //        <Page size="A4" style={styles.page}>
    //         <View style={styles.section}>
    //           <Text>Section #1</Text>
    //         </View>
    //         <View style={styles.section}>
    //           <Text>Section #2</Text>
    //         </View>
    //       </Page>

    // Create styles
    const styles = StyleSheet.create({
        page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
        },
        section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
        }
    });
  
  // Create Document Component
    const MyDocument = () => (
        <Document>

            {
            listFollowed.map((risk, i)=>{
              
                return(
                    <Page key={i} size="A4" style={styles.page}>                
                        <View style={styles.section}>
                            <Text>{risk.intitule}</Text>
                        </View>
                    </Page>  
                )
            })
            }
          
        </Document>
    )


return (
    <>
        <div onClick={()=>{generatePDF()}} style={{outline: '3px dotted #CCC', cursor: 'pointer', borderRadius: 3, position: 'absolute', right: '7.5%', bottom: '50%', width: '7.5%', height: '15%', backgroundColor: '#FFF', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

            <span style={{marginBottom: 20, fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Export graphique</span>
            <FilePdfTwoTone twoToneColor={'red'} style={{fontSize: 50}}/>

        </div>

        <div onClick={()=>{toggleDrawerReport()}} style={{outline: '3px dotted #CCC', cursor: 'pointer', borderRadius: 3, position: 'absolute', right: '7.5%', bottom: '30%', width: '7.5%', height: '15%', backgroundColor: '#FFF', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            
            <span style={{marginBottom: 20, fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Rapport complet</span>
            <FilePdfTwoTone twoToneColor={'red'} style={{fontSize: 50}}/>

        </div>

    </>
)
}
export default Rapport;