import React, { useState } from 'react'
import '../public/styles/parametrage.css'
import Nav from '../components/Nav'
import ToggleSlide from '../components/ToggleSlide'
import Risques from '../components/Risques'
import Habilitations from '../components/Habilitations'
//ant
import { LaptopOutlined } from '@ant-design/icons';

const Parametrage = (user) => {

    const [ready, setReady] = useState(true)

    const [toggle, setToggle] = useState(0)

    const toggleNum = (i) => {
        setToggle(i)
    }

    const [isDesktop, setIsDesktop] = useState(true)

    return (
        <>

            {!isDesktop &&
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 50000, position: 'absolute', textAlign: 'center', fontSize: 20, height: '100%', width: '100%', backgroundColor: '#333', opacity: 0.99, color: '#FFF', fontWeight: 'bold'}}>
                    <LaptopOutlined style={{fontSize: 200, color: "#EDB30D", marginBottom: 40}}/>
                    <span style={{margin: '20px 0px'}}>Votre écran est un peu petit : cela risque d'empêcher le rendu de certains graphiques et de données modélisées.</span>
                    <span style={{margin: '20px 0px'}}>Agrandissez la fenêtre du navigateur ou installez-vous sur un écran de taille supérieure pour profiter pleinement de l'application 😀</span> 
                </div>
            }

            <Nav ready={ready} setReady={setReady} isDesktop={isDesktop} setIsDesktop={setIsDesktop}  />

            <div className="global">
                <div className='legend'> 
                    <ToggleSlide
                        defaultValue={0}
                        toggleNum={toggleNum}
                        toggle={toggle}
                        values={["Risques", "Habilitations"]}
                    />
                </div>

                {toggle === 0 &&
                    <Risques/>
                }

                {toggle === 1 &&
                    <Habilitations/>
                }

            </div>

        </>
    )
    

}

export default Parametrage;