

// BRUT TO NET

const calculNet = (who, proba, impact, maitrise, dispositif, min = 1, max = 6) => {
      let operation;
      switch (dispositif) {
            case 'Mixte':
                  if (who === 'proba') {
                        return (maitrise - ((impact + proba) / 2) >= 1 ? proba - 1 : maitrise - proba >= 0 ? proba - 0.5 : proba) < 0 ? 0 : maitrise - (impact + proba) / 2 >= 1 ? proba - 1 : maitrise - proba >= 0 ? proba - 0.5 : proba
                  } else if (who === 'impact') {
                        return (maitrise - ((impact + proba) / 2) >= 1 ? impact - 1 : maitrise - impact >= 0 ? impact - 0.5 : impact) < 0 ? 0 : maitrise - (impact + proba) / 2 >= 1 ? impact - 1 : maitrise - impact >= 0 ? impact - 0.5 : impact
                  } else {
                        break
                  }
            case 'Préventif':
                  if (who === 'proba') {
                        return (maitrise - proba >= 1 ? proba - 1 : maitrise - proba >= 0 ? proba - 1 : proba) < 0 ? 0 : maitrise - proba >= 1 ? proba - 2 : maitrise - proba >= 0 ? proba - 1 : proba
                  } else {
                        return impact
                  }
            case 'Curatif':
                  if (who === 'impact') {
                        return (maitrise - impact >= 1 ? impact - 1 : maitrise - impact >= 0 ? impact - 1 : impact) < 0 ? 0 : maitrise - impact >= 1 ? impact - 2 : maitrise - impact >= 0 ? impact - 1 : impact
                  } else {
                        return proba
                  }
            case 'Détectif':
                  if (who === 'impact') {
                        return impact                        
                  } else {
                        return proba                        
                  }
            default:
                  if (who === 'impact') {
                        return impact
                  } else if (who === 'proba') {
                        return proba
                  }

      }

      const arr = (ope) => {
            if (ope < min) {
                  return min
            } else if (ope > max) {
                  return max
            } else {
                  return ((ope) * 1) / 1
            }
      }

      return arr(operation)


}

export default calculNet