import React, { useEffect } from 'react'
import '../public/styles/togglesimple.css'

const ToggleSimple = ({defaultValue, toggleNum, toggle}) => {

    useEffect(()=>{toggleNum(defaultValue)},[])    

    return (
        <>

            <div className={toggle === false ? "back-false" : "back-true"} onClick={()=>toggleNum()}>
                    
                    <div className={toggle === false ? "false" : "true"} onClick={()=>toggleNum()}></div>
                
            </div>

        </>
    )
    

}

export default ToggleSimple;