import React, { useState, useEffect } from 'react'
import { Gradient } from 'react-gradient';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const ScoreSliderRapportParametrable = ({ value, length, width = 30, height = 30, cursorSize = 8, police = 10, editMode = false, reverse = false, toggleScore, start = -12, end = 12 }) => {

    //console.log({value})

    if (value <= start) { value = start }
    if (value >= end) { value = end }

    const [hover, setHover] = useState()

    let newValue = value > 0 ? Math.round((value) / ((end - start) / 2) * 100) : Math.round((value) / ((end - start) / 2) * 100)
    //console.log({newValue})

    const potentialDots = () => {
        let array = [];
        for (let i = -101; i < 101; i++) {
            //console.log(newValue, i, value)
            array.push(
                <>

                    <div key={i} style={{ display: 'flex', justifyContent: 'center', position: 'relative', width: '0.5%', height: 25, borderRadius: '50%' }}>
                        <div style={{ position: 'absolute', width: 25, height: 25, borderRadius: '50%', backgroundColor: newValue === i ? "rgba(245, 245, 245, 0.99)" : "", boxShadow: newValue === i ? '0px 0px 5px 0px #222' : '', textAlign: 'center', fontWeight: 'bold', lineHeight: '25px', fontSize: 10 }}>{newValue === i && value}</div>
                    </div>
                </>
            )
        }
        return array
    }

    const spans = () => {
        let array = [];

        for (let i = start - 1; i < end; i++) {
            array.push(
                <>
                    <div key={i + value} title={value} style={{ marginTop: 10, width: length, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                        <span style={{ cursor: 'pointer', fontWeight: 'bolder', color: '#FFF', fontSize: String(Math.round(value)) == String(Math.round(i + 1)) ? 22 : 16, textAlign: 'center', lineHeight: String(Math.round(value)) == String(Math.round(i + 1)) ? `${height * 1.2}px` : `${height}px`, width: String(Math.round(value)) == String(Math.round(i + 1)) ? width * 1.2 : width, height: String(Math.round(value)) == String(Math.round(i + 1)) ? height * 1.2 : height, borderRadius: '0%', backgroundColor: `${determineColor(i)}` }} >{i + 1}</span>
                    </div>
                </>
            )
        }

        return array

    }

    const editableSpans = () => {

        let array = [];

        for (let i = start - 1; i < end; i++) {

            array.push(
                <>
                    <div key={i + value} title={value} style={{ marginTop: 10, width: length, height: 50, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                        <span onMouseEnter={() => { setHover(i) }} onMouseLeave={() => { setHover() }} onClick={() => { toggleScore(i) }} style={{ opacity: hover === i ? 0.7 : 1, cursor: 'pointer', fontWeight: 'bolder', color: '#FFF', fontSize: String(Math.round(value)) == String(Math.round(i + 1)) ? 17 : 14, textAlign: 'center', lineHeight: String(Math.round(value)) == String(Math.round(i + 1)) ? `${height * 1.2}px` : `${height}px`, width: String(Math.round(value)) == String(Math.round(i + 1)) ? width * 1.2 : width, height: String(Math.round(value)) == String(Math.round(i + 1)) ? height * 1.2 : height, borderRadius: '50%', backgroundColor: `${determineColor(i)}`, margin: `0px ${length / ((end + 1 - start) * width)}px`, outline: `${String(Math.round(value)) == String(Math.round(i + 1)) ? '2px solid #999' : ''}` }} >{i + 1}</span>
                    </div>
                </>
            )
        }

        return array

    }

    const determineColor = (i) => {

        if (!reverse) {
            if (i <= 0) {
                return '#d4111e'
                // } else if (i <= 2*(end+1-start)/10) {
                //     return '#d71f22' 
                // } else if (i <= 3*(end+1-start)/10) {
                //     return '#d92c25'
            } else if (i <= 1) {
                return '#dd462b'
            } else if (i <= 2) {
                return '#f48c06'
            } else if (i <= 3) {
                return '#feed34'
                // } else if (i <= 7*(end+1-start)/10) {
                //     return '#cbda42'
            } else if (i <= 4) {
                return '#a0d034'
                // } else if (i <= 9*(end+1-start)/10) {
                //     return '#75c626'
            } else {
                return '#49bc17'
            }
        } else if (reverse) {
            if (i <= 0) {
                return '#49bc17'
                //        } else if (i <= 2/(end+1-start)) {
                //            return '#75c626'
            } else if (i <= 1) {
                return '#a0d034'
                //        } else if (i <= 4/(end+1-start)) {
                //            return '#cbda42'
            } else if (i <= 2) {
                return '#feed34'
            } else if (i <= 3) {
                return '#f48c06'
            } else if (i <= 4) {
                return '#dd462b'
                //        } else if (i <= 8/(end+1-start)) {
                //          return '#d92c25'
                //        } else if (i <= 9/(end+1-start)) {
                //            return '#d71f22'
            } else {
                return '#d4111e'
            }
        }
    }

    return (
        <>
            <div style={{ position: 'relative', marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '70%', height: 25, borderRadius: '10px 5px 10px 5px', background: "linear-gradient(90deg, #004EE8 0%, #30A9DD 10%, #A4E3F7 20%, #DAF0F6 30%, #DAF0F6 40%, #49BC17 50%, #49BC17 60%, rgba(150,249,7,1) 70%, rgba(249,230,8,1) 80%, rgba(250,144,6,1) 90%, rgba(247,32,5,1) 100%)" }}>
                <div style={{ position: 'absolute', top: '-80%', width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <ArrowLeftOutlined style={{ color: '#999', fontSize: 10, marginRight: 5 }} />
                        <span style={{ color: '#999', fontSize: 13 }}>Sur-maîtrise</span>
                    </div>
                    <div>
                        <span style={{ color: '#999', fontSize: 13 }}>Sous-maîtrise</span>
                        <ArrowRightOutlined style={{ color: '#999', fontSize: 10, marginLeft: 5 }} />
                    </div>
                </div>
                {
                    potentialDots()
                }
            </div>

            {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {!editMode &&
                    spans()
                    }
                    {editMode &&
                    editableSpans()    
                    }
            </div> */}

        </>
    )


}

export default ScoreSliderRapportParametrable;