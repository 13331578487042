import React, { useState } from 'react'
import '../public/styles/informations.css'
import { Link } from 'react-router-dom';

import moment from "moment/min/moment-with-locales"

//ant_____________________
import {
    Input,
    Upload,
    Select,
    Form,
    message
} from 'antd';

import ImgCrop from 'antd-img-crop';

import {
    UserOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
    LockOutlined
} from '@ant-design/icons';
import Button from './Button';

moment.locale('fr')

const { Option } = Select

const AddProfile = ({crea, setCrea, user, clientSelected, listFollowed, listByProcesses, listByRisks, stateSelected, setStateSelected}) => {

    const [current, setCurrent] = useState({})

//LISTENERS CHECK
    // useEffect(()=>{if (user) {console.log('user in AddProfile : ', user)}}, [user])
    // useEffect(()=>{if (clientSelected) {console.log('clientSelected in AddProfile : ', clientSelected)}}, [clientSelected])
    // useEffect(()=>{if (listFollowed) {console.log('listFollowed in AddProfile : ', listFollowed)}}, [listFollowed])
    // useEffect(()=>{if (listByRisks) {console.log('listByRisks in AddProfile : ', listByRisks)}}, [listByRisks])
    // useEffect(()=>{if (listByProcesses) {console.log('listByProcesses in AddProfile : ', listByProcesses)}}, [listByProcesses])
    // useEffect(()=>{if (stateSelected) {console.log('stateSelected in AddProfile : ', stateSelected)}}, [stateSelected])
    // useEffect(()=>{if (current) {console.log('current in AddProfile : ', current)}}, [current])

    const handleChangeLastname = (e) => {setCurrent({...current, lastname: e.target.value})}
    const handleChangeFirstname = (e) => {setCurrent({...current, firstname: e.target.value})}    
    const handleChangeEmail = (e) => {setCurrent({...current, email: e.target.value})}    

    const handleChangeRole = (e) => {setCurrent({...current, role: e.target.value})}    
    const handleChangePassword = (e) => {setCurrent({...current, password: e.target.value})}    
    const handleChangeHabilitation = (value) => {setCurrent({...current, habilitations: [{client_hab: clientSelected._id, type_hab: value}]})}

    const successSave = () => {
        message.success('Profil enregistré avec succès 👍');
      };

//Upload antd
const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
 
    setFileList(newFileList);
    //console.log(newFileList[0]?.status)
  
     if (newFileList[0]?.status === 'uploading') {
         //console.log(newFileList[0]?.name, ' uploading');
     }
     if (newFileList[0]?.status === 'done') {
         message.success(`${newFileList[0]?.name} file uploaded successfully.`)
         //console.log( newFileList[0] )
         setCurrent({ ...current, avatar: newFileList[0].response.cloudResult })

     } else if (newFileList[0]?.status === 'error') {
         message.error(`${newFileList[0].name} file upload failed.`);
     }

  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };


//end dragndrop

    const handleSaveProfile = async () => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/new-user`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                firstname: current.firstname,
                lastname: current.lastname,
                role: current.role,
                email: current.email,
                password: current.password,
                avatar: current.avatar,
                habilitations: current.habilitations,
            })
        })
        const json = await data.json()

        if (json.result) {
            //console.log(json)
            successSave()
            setCurrent({})
        }
    }

    return (
        <>                  
            <div className='containersuivi' style={{justifyContent: 'space-evenly'}}>

            <div style={{borderRadius: '50%', height: 500, display: 'flex', paddingLeft: '45%', width: '100%', justifyContent: 'center', alignItems: 'center'}} >
                <ImgCrop rotate>
                    <Upload
                        action={`${process.env.REACT_APP_BACK_END}/upload-user-avatar`}
                        listType="picture-card"
                        fileList={fileList}
                        multiple={false}
                        onChange={onChange}
                        onPreview={onPreview}
                        maxCount={1}
                    >
                        {fileList.length < 1 && '+ Avatar'}
                    </Upload>
                </ImgCrop>
            </div>

            {/* Nom */}
            <Form.Item style={{ width: '48%' }} tooltip="Nom" name="Nom" label="Nom" hasFeedback validateStatus={current.lastname ? "success" : ''} help=""
                rules={[
                    {
                        required: true,
                        message: 'Désolé, obligatoire 😁',
                        whitespace: true
                    },
                ]}
            >
                <Input disabled={clientSelected && clientSelected.type_hab != 'Risk management' ? true : false} placeHolder={current.lastname} value={current.lastname} onChange={handleChangeLastname} />
            </Form.Item>

           {/* Prénom */}
           <Form.Item style={{ width: '48%' }} tooltip="Prénom" name="Prénom" label="Prénom" hasFeedback validateStatus={current.firstname ? "success" : ''} help=""
                rules={[
                    {
                        required: true,
                        message: 'Désolé, obligatoire 😁',
                        whitespace: true
                    },
                ]}
            >
                <Input disabled={clientSelected && clientSelected.type_hab != 'Risk management' ? true : false} placeHolder={current.firstname} value={current.firstname} onChange={handleChangeFirstname} />
            </Form.Item>


           {/* Role */}
           <Form.Item style={{ width: '48%' }} tooltip="Fonction au sein de la structure" name="Fonction" label="Fonction" hasFeedback validateStatus={current.role ? "success" : ''} help=""
                rules={[
                    {
                        required: true,
                        message: 'Désolé, obligatoire 😁',
                        whitespace: true
                    },
                ]}
            >
                <Input disabled={clientSelected && clientSelected.type_hab != 'Risk management' ? true : false} placeHolder={current.role} value={current.role} onChange={handleChangeRole} />
            </Form.Item>

            {/* email */}
            <Form.Item style={{ width: '48%' }} tooltip="Email" name="Email" label="Email" hasFeedback validateStatus={current.email ? "success" : ''} help=""
                rules={[
                    {
                        required: true,
                        message: 'Désolé, obligatoire 😁',
                        whitespace: true
                    },
                ]}
            >
            <Input placeholder="Entrer votre e-mail" prefix={<UserOutlined />} onChange={handleChangeEmail}/>
            </Form.Item>

            {/* password */}
            <Form.Item style={{ width: '48%' }} tooltip="Mot de passe" name="Mot de passe" label="Mot de passe" hasFeedback validateStatus={current.password ? "success" : ''} help=""
                rules={[
                    {
                        required: true,
                        message: 'Désolé, obligatoire 😁',
                        whitespace: true
                    },
                ]}
            >
            <Input.Password
            autoComplete="new-password"
            placeholder="Entrer votre mot de passe"
            prefix={<LockOutlined />}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={handleChangePassword}
            />
            </Form.Item>

        {/* habilitation */}
            <Form.Item style={{ width: '48%' }} tooltip="Habilitation" name="Habilitation" label="Habilitation" hasFeedback validateStatus={current.habilitations ? "success" : ''} help="">
                <Select disabled={clientSelected && clientSelected.type_hab != 'Risk management' ? true : false} style={{ width: '100%'}} placeholder="Sélectionner un type d'habilitation" onChange={handleChangeHabilitation}>
                    {['Risk management', 'Contribution métier', 'Gouvernance', 'Audit'].map((e, i) => { return <Option key={e}>{e}</Option> })} {/* change also enum array in model */}
                </Select>
            </Form.Item>

        {/* avatar, habilitations avec type_hab parmi & client_hab : clientSelected._id */}

            {/* Button */}
            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '50%'}}>
                <div style={{ width: '10vw', marginTop: '5%' }}><Link to={`/create/profile`}><Button specif={'cancel'} texte={'Retour'} onClick={() => { setCurrent({}); setCrea(!crea) }}></Button></Link></div>
                <div style={{ width: '10vw', marginTop: '5%' }}><Button texte={'Enregistrer'} onClick={() => { handleSaveProfile(); setCrea(!crea) }} /></div>
            </div>

            </div>        
        </>
    )
    }
    export default AddProfile;