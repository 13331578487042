import React, { useState, useEffect } from 'react'
import '../public/styles/score.css'

const ScoreDots = ({value, length = 40, width = 30, height = 30, cursorSize = 8, police = 10, editMode = false, reverse = false, toggleScore, start = 1, end = 6}) => {

    if(Math.round(value) < 1) {value = 1}
    if(Math.round(value) > 6) {value = 6}

    const [hover, setHover] = useState()

    const spans = () => {
        let array = [];

        for(let i=start-1;i<end;i++){
            array.push(
                <>
                    <div key={i+value} title={value} style={{marginTop: 10, width: length, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                        <span key={i+value} style={{cursor: 'pointer', fontWeight: 'bolder', color: '#FFF', fontSize: String(Math.round(value)) == String(Math.round(i+1)) ? 22 : 16, textAlign: 'center', lineHeight: String(Math.round(value)) == String(Math.round(i+1)) ? `${height*1.2}px` : `${height}px`, width: String(Math.round(value)) == String(Math.round(i+1)) ? width*1.2 : width, height: String(Math.round(value)) == String(Math.round(i+1)) ? height*1.2 : height, borderRadius: '50%', backgroundColor: `${determineColor(i)}`, margin: `0px ${length/((end+1-start)*width)}px`, outline: `${String(Math.round(value)) == String(Math.round(i+1)) ? '2px solid #999' : ''}`}} >{i+1}</span>
                    </div>
                </>
            )
        }
    
        return array

    }

    const editableSpans = () => {

        let array = [];

        for(let i=start-1;i<end;i++){

            array.push(
                <>
                    <div key={i+value} title={value} style={{marginTop: 10, width: length, height: 50, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                        <span key={i+value} onMouseEnter={()=>{setHover(i)}} onMouseLeave={()=>{setHover()}} onClick={()=>{toggleScore(i)}} style={{opacity: hover === i ? 0.7 : 1, cursor: 'pointer', fontWeight: 'bolder', color: '#FFF', fontSize: String(Math.round(value)) == String(Math.round(i+1)) ? 17 : 14, textAlign: 'center', lineHeight: String(Math.round(value)) == String(Math.round(i+1)) ? `${height*1.2}px` : `${height}px`, width: String(Math.round(value)) == String(Math.round(i+1)) ? width*1.2 : width, height: String(Math.round(value)) == String(Math.round(i+1)) ? height*1.2 : height, borderRadius: '50%', backgroundColor: `${determineColor(i)}`, margin: `0px ${length/((end+1-start)*width)}px`, outline: `${String(Math.round(value)) == String(Math.round(i+1)) ? '2px solid #999' : ''}`}} >{i+1}</span>
                    </div>
                </>
            )
        }
    
        return array

    }

    const determineColor = (i) => {

    if(!reverse) {
        if(i <= 0) {
            return '#d4111e'
        // } else if (i <= 2*(end+1-start)/10) {
        //     return '#d71f22' 
        // } else if (i <= 3*(end+1-start)/10) {
        //     return '#d92c25'
        } else if (i <= 1) {
            return '#dd462b'
        } else if (i <= 2) {
            return '#f48c06'
        } else if (i <= 3) {
            return '#feed34'
        // } else if (i <= 7*(end+1-start)/10) {
        //     return '#cbda42'
        } else if (i <= 4) {
            return '#a0d034'
        // } else if (i <= 9*(end+1-start)/10) {
        //     return '#75c626'
        } else {
            return '#49bc17'
        }
    } else if (reverse) {
        if(i <= 0) {
            return '#49bc17'
//        } else if (i <= 2/(end+1-start)) {
//            return '#75c626'
        } else if (i <= 1) {
            return '#a0d034'
//        } else if (i <= 4/(end+1-start)) {
//            return '#cbda42'
        } else if (i <= 2) {
            return '#feed34'
        } else if (i <= 3) {
            return '#f48c06'
        } else if (i <= 4) {
            return '#dd462b'
//        } else if (i <= 8/(end+1-start)) {
//          return '#d92c25'
//        } else if (i <= 9/(end+1-start)) {
//            return '#d71f22'
        } else {
            return '#d4111e'
        }
    }
    } 

    return (
        <>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {!editMode &&
                    spans()
                    }
                    {editMode &&
                    editableSpans()    
                    }
            </div>

        </>
    )
    

}

export default ScoreDots;