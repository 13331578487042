import React, { useState, useEffect } from 'react'
import '../public/styles/risques.css'

const Risques = (user) => {

    return (
        <>
            <div className='containerparam'>

                RISQUES

            </div>
        </>
    )
    }
    export default Risques;