import React, { useState, useEffect, useRef } from 'react'
import Xarrow from "react-xarrows"
import RadianAxe from './RadianAxe'
import '../public/styles/radiangraph.css'

import { short } from '../helpers'
import { RadarChartOutlined } from '@ant-design/icons'

const RadianGraphMacro = ({ swap, toggle, setToggle, hover, setHover, spider, selection, setSelection, clientSelected, dataSet, handleChangeDataSet, sliderBest, sliderWorst, brut, net, listFollowed, listByRisks, listByProcesses, familyFilter, setFamilyFilter, processFilter, setProcessFilter }) => {

// console.log({dataSet})
// console.log({listByRisks})
// console.log(listByProcesses.map((e, i) => { return e.family.processes }).flat())
//console.log({listByProcesses})

let x=75;
let y=100;

const fond = [
    {backgroundColor: '#d4111e', color: '#333', width: `${x*(25/25)}%`, height: `${y*(25/25)}%`},
    {backgroundColor: '#d4111e', color: 'rgba(0,0,0,0.1)', width: `${x*(24/25)}%`, height: `${y*(24/25)}%`},
    {backgroundColor: '#d4111e', color: '#333', width: `${x*(24/25)}%`, height: `${y*(24/25)}%`},
    {backgroundColor: '#dd462b', color: '#333', width: `${x*(23/25)}%`, height: `${y*(23/25)}%`},
    {backgroundColor: '#dd462b', color: '#333', width: `${x*(22/25)}%`, height: `${y*(22/25)}%`},
    {backgroundColor: '#f48c06', color: '#333', width: `${x*(21/25)}%`, height: `${y*(21/25)}%`},
    {backgroundColor: '#f48c06', color: '#333', width: `${x*(20/25)}%`, height: `${y*(20/25)}%`},
    {backgroundColor: '#feed34', color: '#333', width: `${x*(19/25)}%`, height: `${y*(19/25)}%`},
    {backgroundColor: '#feed34', color: '#333', width: `${x*(18/25)}%`, height: `${y*(18/25)}%`},
    {backgroundColor: '#a0d034', color: '#333', width: `${x*(17/25)}%`, height: `${y*(17/25)}%`},
    {backgroundColor: '#a0d034', color: '#333', width: `${x*(16/25)}%`, height: `${y*(16/25)}%`},
    {backgroundColor: '#75c626', color: '#333', width: `${x*(15/25)}%`, height: `${y*(15/25)}%`},
    {backgroundColor: '#75c626', color: '#333', width: `${x*(14/25)}%`, height: `${y*(14/25)}%`},
    {backgroundColor: '#49bc17', color: '#333', width: `${x*(13/25)}%`, height: `${y*(13/25)}%`},
    {backgroundColor: '#EEE', color: '#333', width: `${x*(12/25)}%`, height: `${y*(12/25)}%`},
    {backgroundColor: '#EEE', color: '#333', width: `${x*(11/25)}%`, height: `${y*(11/25)}%`},
    {backgroundColor: '#EEE', color: '#333', width: `${x*(10/25)}%`, height: `${y*(10/25)}%`},
    {backgroundColor: '#DDD', color: '#333', width: `${x*(9/25)}%`, height: `${y*(9/25)}%`},
    {backgroundColor: '#DDD', color: '#333', width: `${x*(8/25)}%`, height: `${y*(8/25)}%`},
    {backgroundColor: '#CCC', color: '#333', width: `${x*(7/25)}%`, height: `${y*(7/25)}%`},
    {backgroundColor: '#CCC', color: '#333', width: `${x*(6/25)}%`, height: `${y*(6/25)}%`},
    {backgroundColor: '#BBB', color: '#333', width: `${x*(5/25)}%`, height: `${y*(5/25)}%`},
    {backgroundColor: '#BBB', color: '#333', width: `${x*(4/25)}%`, height: `${y*(4/25)}%`},
    {backgroundColor: '#AAA', color: '#333', width: `${x*(3/25)}%`, height: `${y*(3/25)}%`},
    {backgroundColor: '#AAA', color: '#333', width: `${x*(2/25)}%`, height: `${y*(2/25)}%`},
    {backgroundColor: '#999', color: '#333', width: `${x*(1/25)}%`, height: `${y*(1/25)}%`},
    {backgroundColor: '#222', color: '#333', width: `${x*(0.2/25)}%`, height: `${y*(0.2/25)}%`},

]

const displayFond = () => {

    return (
        fond.map((el, i)=>{
            return <div key={i} title={12-i > -13 ? 12-i : -12} style={{opacity: i === 0 ? 0.9 : 0.5, position: 'absolute', borderRadius: '50%', backgroundColor: `${el.backgroundColor}`, border: `1px solid ${el.color}`, height: el.height, width: el.width, color: el.color}}></div>
        })
    )
}


const displayAxes = () => {

{/* <div style={{transformOrigin: 'left', transform: 'rotate(90deg)', position: 'absolute', width: '75%', height: 1, backgroundColor: '#333', ouline: '1px solid #333'}}></div> */}

    return (
        <>
        {!swap &&
        listByRisks.map((el, index)=>{
            let degree = (360/listByRisks.length)*index
            //console.log({degree})
            //console.log({el})
            //console.log({liste_states_in_family: el.family.risks.map((item)=>{return item.states}).map((st)=>{return st}).flat()})
            let listeStatesInFamily = el.family.risks.map((item)=>{return item.states}).map((st)=>{return st}).flat()
            return (
                    <div key={index} style={{display: 'flex', flexDirection: '', justifyContent: 'center', alignItems: 'center', transformOrigin: 'left', transform: `rotate(${degree}deg)`, position: 'absolute', left: '50%', width: `${x/2}%`, height: 1, backgroundColor: '#333', ouline: '1px solid #333'}}>
                        {
                        [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n)=>{
                            //console.log({list_filter_for_point: listeStatesInFamily.filter(i=>Math.round(i.rapport)*2 === n)})
                            let listFilteredForPoint = listeStatesInFamily.filter(i=>Math.round(i.rapport*2) === n)
                            return (
                                <span title={n} onClick={()=>{ if(Math.round(listFilteredForPoint[0]?.rapport*2) === n) { setSelection(listFilteredForPoint) } else {setSelection({})}}} onMouseEnter={()=>{ if(Math.round(listFilteredForPoint[0]?.rapport*2) === n) { /*console.log({listFilteredForPoint});*/ setHover(listFilteredForPoint) } }} onMouseLeave={()=>{  if(Math.round(listFilteredForPoint[0]?.rapport*2) === n) { setHover({}) } }} className={listFilteredForPoint.length > 0 ? 'pointr':''} id={Math.round(listFilteredForPoint[0]?.rapport*2) === n ? `${el.intitule}-ok` : `${el.intitule}n`} key={n} style={{zIndex: selection.length > 0 && selection?.find(e=>e === listFilteredForPoint[0]) ? 1000 : 0, outline: selection.length > 0 && selection?.find(e=>e === listFilteredForPoint[0]) ? '3px solid #FFF' : listFilteredForPoint.length > 0 ? '1px solid #FFF' : '', cursor: 'pointer', position: 'relative', backgroundColor: listFilteredForPoint.length > 0 ? "#333": "", borderRadius: '0%', height: listFilteredForPoint.length > 1 ? 8+6 : 8, width: listFilteredForPoint.length > 1 ? 8+6 : 8, minHeight: 9, minWidth: 9}}></span>
                            )
                        })
                        }
                        <span onClick={()=>{ setProcessFilter(); setFamilyFilter( listByRisks.findIndex(e=>e.family.libelle === el.family.libelle) ); setToggle(!toggle) }} title={el.family.libelle} style={{textAlign: 'center', transform: `rotate(${degree > 0 && degree < 180 ? 90+180 : 90}deg)`, cursor: 'pointer', position: 'absolute', left: '40%', width: '135%'}}>{short(el.family.libelle)}</span>
                    </div>
                    )
        
        })
        }
        {swap &&
        listByProcesses.map((el, index)=>{
            let degree = (360/listByProcesses.length)*index
            //console.log({degree})
            //console.log({el})
            //console.log({liste_states_in_family: el.family.processes.map((item)=>{return item.states}).map((st)=>{return st}).flat()})
            let listeStatesInFamily = el.family.processes.map((item)=>{return item.states}).map((st)=>{return st}).flat()
            return (
                    <div key={index} style={{display: 'flex', flexDirection: '', justifyContent: 'center', alignItems: 'center', transformOrigin: 'left', transform: `rotate(${degree}deg)`, position: 'absolute', left: '50%', width: `${x/2}%`, height: 1, backgroundColor: '#333', ouline: '1px solid #333'}}>
                        {
                        [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n)=>{
                            //console.log({list_filter_for_point: listeStatesInFamily.filter(i=>Math.round(i.rapport)*2 === n)})
                            let listFilteredForPoint = listeStatesInFamily.filter(i=>Math.round(i.rapport*2) === n)
                            return (
                                <span title={n} onClick={()=>{ if(Math.round(listFilteredForPoint[0]?.rapport*2) === n) { setSelection(listFilteredForPoint) } else {setSelection({})}}} onMouseEnter={()=>{ if(Math.round(listFilteredForPoint[0]?.rapport*2) === n) { /*console.log({listFilteredForPoint});*/ setHover(listFilteredForPoint) } }} onMouseLeave={()=>{  if(Math.round(listFilteredForPoint[0]?.rapport*2) === n) { setHover({}) } }} className={listFilteredForPoint.length > 0 ? 'pointr':''} id={Math.round(listFilteredForPoint[0]?.rapport*2) === n ? `${el.intitule}-ok` : `${el.intitule}n`} key={n} style={{zIndex: selection.length > 0 && selection?.find(e=>e === listFilteredForPoint[0]) ? 1000 : 0, outline: selection.length > 0 && selection?.find(e=>e === listFilteredForPoint[0]) ? '3px solid #FFF' : listFilteredForPoint.length > 0 ? '1px solid #FFF' : '', cursor: 'pointer', position: 'relative', backgroundColor: listFilteredForPoint.length > 0 ? "#333": "", borderRadius: '0%', height: listFilteredForPoint.length > 1 ? 8+6 : 8, width: listFilteredForPoint.length > 1 ? 8+6 : 8, minHeight: 9, minWidth: 9}}></span>
                            )
                        })
                        }
                        <span onClick={()=>{ setFamilyFilter(); setProcessFilter( listByProcesses.findIndex(e=>e.family.libelle === el.family.libelle) ); setToggle(!toggle) }} title={el.family.libelle} style={{textAlign: 'center', transform: `rotate(${degree > 0 && degree < 180 ? 90+180 : 90}deg)`, cursor: 'pointer', position: 'absolute', left: '40%', width: '135%'}}>{short(el.family.libelle)}</span>
                    </div>
                    )
        
        })
        }
        </>
    )
}


const displayArrows = () => {

    return (
        
        dataSet.map((el, i)=>{
            if(i >= 1) {
                let prec = dataSet[i-1].intitule
                return (
                    <Xarrow
                    key={i}
                    start={`${el.intitule}-ok`}
                    end={`${prec}-ok`}
                    color='#FFF'
                    strokeWidth={4}
                    showHead={false}
                    curveness={0}
                    dashness={false}
                    path={'straight'}
                    />
                )
            } else {
                // console.log(`${dataSet[0].intitule}-ok`)
                // console.log(`${dataSet[dataSet.length-1].intitule}-ok`)
                return (
                    <Xarrow
                    key={i}
                    end={`${dataSet[0].intitule}-ok`}
                    start={`${dataSet[dataSet.length-1].intitule}-ok`}
                    color='#FFF'
                    strokeWidth={4}
                    showHead={false}
                    curveness={0}
                    dashness={false}
                    path={'straight'}
                    />
                )
            }
            }
        )
    )

}

return (
        <div id="Radian" style={{position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                <div style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>

            {/* fond */}

                    {displayFond()}

            {/* axes */}

                    {displayAxes()}

            {/* diagramme araignée */}
            {spider &&
                    displayArrows()
            }
                </div>

        </div>
)
}
    export default RadianGraphMacro;