import React, { useState, useEffect } from 'react'
import Button from 'react'
import '../public/styles/choice.css'
import Nav from './Nav'
import { Link } from 'react-router-dom';

const Choice = ({ready, user, clientSelected}) => {

    //useEffect(()=>{console.log('user in Choice : ', user)}, [user])
    //useEffect(()=>{console.log('clientSelected in Choice : ', clientSelected)}, [clientSelected])

    return (
        <>
                <div className={user ? 'container-shown' : 'container-hidden'} style={{marginBottom: 20, minHeight: '10%', height: '10%', padding: 0}}>
                    <h1 style={{margin: 0}}>Bonjour {user.firstname}, {ready ? 'que souhaitez-vous faire ?' : 'les données chargent un instant.'}</h1>
                </div>
                
                {ready &&
                <>
                { clientSelected && clientSelected.type_hab === 'Risk management' &&
                <div className={clientSelected && clientSelected.type_hab === 'Risk management' ? 'container-shown' : 'container-hidden'}>

                    <>
                        <span className='text-inter'>Gérer mon portefeuille de risques</span>
                        <Link className='link' to={'/create/profile'} > Gérer et ajouter des profils </Link>
                        <Link className='link' to={'/create/risk'} > Créer ma cartographie des risques </Link>
                        <h1></h1>
                        <span className='text-inter'>Evaluer ma situation</span>
                        <Link className='link' to={'/suivi'}> Visualiser et actualiser le pilotage des risques </Link>
                        <Link className='link' to={'/suivi/graph'}> Analyser et générer un rapport de maîtrise </Link>
                    </>
                
                </div>
                }
               { clientSelected && clientSelected.type_hab === 'Contribution métier' &&
                <div className={clientSelected && clientSelected.type_hab === 'Contribution métier' ? 'container-shown' : 'container-hidden'}>

                    <>
                        <Link className='link' to={'/create/profile'}> Gérer mon profil </Link>
                        <Link className='link' to={'/suivi'}> Gérer mon portefeuille de risques </Link>
                        <Link className='link' to={'/suivi/incident'}> Signaler un incident </Link>
                        <Link className='link' to={'/suivi/action'}> Enregistrer un plan d'action </Link>
                    </>
                
                </div>
                }
               { clientSelected && clientSelected.type_hab === 'Gouvernance' &&
                <div className={clientSelected && clientSelected.type_hab === 'Gouvernance' ? 'container-shown' : 'container-hidden'}>

                    <>
                        <Link className='link' to={'/create/profile'}> Gérer mon profil </Link>
                        <Link className='link' to={'/suivi/risque'}> Suivi et traitement du risque </Link>
                        <Link className='link' to={'/suivi/graph'}> Visualiser et actualiser le pilotage des risques </Link>
                        <Link className='link' to={'/suivi/graphradian'}> Editer un rapport d'avancement </Link>
                    </>
                
                </div>
                }
               { clientSelected && clientSelected.type_hab === 'Audit' &&
                <div className={clientSelected && clientSelected.type_hab === 'Audit' ? 'container-shown' : 'container-hidden'}>

                    <>
                        <Link className='link' to={'/suivi'}> Rendre compte d’une mission de contrôle ou d’audit menée sous ma supervision </Link>

                    </>
                
                </div>
                }
            </>
            }
        </>
    )
    

}

export default Choice;