
import React, { useState, useEffect } from 'react'
import '../public/styles/toggleslide.css'

const ToggleSlide = ({notif, defaultValue, toggleNum, toggle, values}) => {

    const [notification, setNotification] = useState([])

    useEffect(()=>{
        //setNotification([1, 3]) // TEST
        toggleNum(defaultValue)
        if(notification) {deleteNotif(defaultValue)}
    },[])

    const deleteNotif = (i) => {
        if(notification.includes(i)) {
            let array = [...notification]
        //    console.log('before', array, i)
            
                array.splice(array.indexOf(i), 1)
            
            setNotification(array)
        //    console.log('after', array)
        }
    }

    return (

        <>

            <div className="back">
                
                {values.map((el, i)=>{
                     
                    return <>
                        <span
                            className={toggle === i ? "item-selected" : "item-uns"}
                            style={{position: 'relative'}}
                            onClick={()=>{toggleNum(i); deleteNotif(i)}}>{values[i]}
                            {notification.includes(i) && toggle !== i && <div style={{position: 'absolute', top: -10, right: 0, backgroundColor: 'red', width: 15, height: 15, borderRadius: '50%'}}></div>}
                        </span>
                        </>    
                })
                }
            </div>

        </>
    )
    

}

export default ToggleSlide;