///// AJOUT MOT DE PASSE OUBLIE... /////

import React, { useState, useEffect } from 'react'
import '../public/styles/connexion.css'

//REDUX_______________________
import { useDispatch, useSelector } from 'react-redux'

//NAVIGATION___________________
import { Navigate, useParams, Link } from 'react-router-dom'

//components______________
import Button from '../components/Button'
import ToggleSimple from './ToggleSimple';

//COOKIES_________________
import Cookie from 'universal-cookie'

//ant_____________________
import {
    Input,
    Select,
    message
} from 'antd';

import {
    CloseOutlined,
    UserOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined,
    LockOutlined
} from '@ant-design/icons';

const { Option } = Select

const cookies = new Cookie()

//const [cookies, setCookie, removeCookie] = useCookies([])

const Connexion = ({errors, setErrors, actualisation, ready, setReady, toggleShowConnexion, showConnexion, setClientSelected, clients, setClients}) => {
    //console.log({readyInConnexion: ready})
    const {typo, stepFromSuivi, clientId, tokenFromUrl} = useParams()
//    console.log('in connexion : ', {typo, stepFromSuivi, clientId, tokenFromUrl})

    const dispatch = useDispatch()

    const user = useSelector (({ user }) => user)
    const clientSelected = useSelector (({ clientSelected })=> clientSelected)
    const stateSelected = useSelector (({ stateSelected })=> stateSelected)
    const listFollowed = useSelector (({ listFollowed }) => listFollowed)


    //LOGIN HOOKS    

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [token, setToken] = useState('')

    const [forgot, setForgot] = useState(false)

    //TOGGLE FOR COOKIE TOKEN    
    const [acceptCookies, setAcceptCookies] = useState(stateSelected ? true : false)

    const toggleNum = () => {
        setAcceptCookies(!acceptCookies)
    }

//LISTENERS
useEffect(() => {console.log('Errors in connexion : ', errors)}, [errors])
useEffect(() => {console.log('Token found : ', token)}, [token])
useEffect(() => {console.log('ACTUALISATION DEMANDEE : ', actualisation)}, [actualisation])

//HOOK TOKEN WHEN LOADING
    useEffect(() => {
    
    //FETCH USER
        const fetchUser = async () => {
            console.log('fetchUser : ', token, tokenFromUrl)
            let tok = tokenFromUrl ? tokenFromUrl : token            
            //console.log('fetching user with : ', tok)
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/account`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: tok })
                })

            const json = await data.json()
            
            if (json.result) {

                setToken(tok)
//                console.log({user: json.user})
                dispatch({type: 'loginUser', user: json.user})

                const tabClients = json.user.habilitations.map(hab=>({...hab.client_hab, type_hab: hab.type_hab}))
                console.log(json.user.habilitations, {tabClients})
                setClients(tabClients)
                dispatch({type: 'keepClients', clients: clients})    
                //setClientSelected(tabClients[0])

                if (!clientSelected) { await fetchClient(tabClients[0]._id, tabClients[0].type_hab) }
            
            } else { setErrors(json.errors) }
        }

        let token = cookies.get('token')
        console.log({token}, {user})
        if (token) { //&& !user
            fetchUser()
        }        

    }, [])


//useEffect pour relancer le fetchStates(clientSelected) quand n'importe quoi est modifié en front


//HOOK RISKS, FAMILIES AND PROCESSES WHEN CLIENT SELECTED SET

    useEffect(() => {        
        if ( clientSelected ) {
            console.log('ACTUALISATION EN COURS - FETCH STATES : ', listFollowed)
        //  console.log('USE EFFECT STATES AND USERS ACTIVATED BY CLIENT SELECTED')
            fetchStates( clientSelected )
            fetchUsers( clientSelected )
            
        }
    }, [clientSelected, actualisation]) //actualisation deleted


//message réinitialisation mdp
    const successForgot = () => {
        message.success('Mail envoyé avec succès 👍');
      };

//FETCH ONE CLIENT
    const fetchClient = async (id, hab) => {
 
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/clients/${id}`)
        const json = await data.json()
      
            if (json.result) {
                console.log('CLIENT CHANGED : ', {...json.clientFound, type_hab: hab})
                dispatch({type: 'selectClient', client: {...json.clientFound, type_hab: hab}})
                //fetchUsers(json.clientFound)
                setClientSelected({...json.clientFound, type_hab: hab})
            }
}

const fetchStates = async (obj) => {
    // console.log('*** FETCH STATES CONNEXION *** : ', clientSelected.followed_risks, obj.followed_risks.map(e=>{return e._id}))

    // console.log('STATE SELECTED IN CONNEXION', stateSelected)
    if ( stateSelected && clientSelected?.followed_risks.includes(stateSelected._id) ) { setReady( true ) }  //XXREADY
    if ( !stateSelected || !clientSelected?.followed_risks.includes(stateSelected._id)) { 
        // console.log('obj.follwed_risks ____________________________________ ', obj, obj.followed_risks)
    const followed = obj.followed_risks//.map(el=>{ return el._id })

    const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/get-list`, {
        // mode: 'no-cors',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ followed, type_criticity: obj.type_criticity })
        })

    const json = await data.json()
    console.log('______________________________________________ ', json.result)
    if (json.result) {
        console.log( 'json.listFollowed : ', json.listFollowedWithIndicateurs.length, json.listFollowedWithIndicateurs);
//        console.log( 'json.listByProcesses : ', json.listByProcesses);
//        console.log( 'json.listByRisks : ', json.listByRisks);
        dispatch({type: 'listFollowed', listFollowed: json.listFollowedWithIndicateurs});        
        dispatch({type: 'listByProcesses', listByProcesses: json.listByProcesses});
        dispatch({type: 'listByRisks', listByRisks: json.listByRisks});
        if ( !ready ) { setReady( true ) }
        console.log('FETCH STATES TERMINE !!!')
        console.log('STATE SELECTED IN CONNEXION 2', stateSelected)
    }
    }
    console.log('*** FIN FETCH STATES CONNEXION ***')

}

const fetchUsers = async (client) => {
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/get-users/${client._id}`)
        const json = await data.json()
            if (json.result) {
          //      console.log('USERS OF CLIENT SELECTED : ', json.usersFound)
                dispatch({type: 'clientusers', users: json.usersFound})
                console.log('FETCH USERS TERMINE !!!')

            }
}

//HANDLE SIGN IN
    const handleSignIn = async () => {
        //console.log(process.env.BACK_END)
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/account`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({token, password, email})
        })

        const json = await data.json()

        if (json.result) {
            let days = 60;
            if (acceptCookies) cookies.set('token', json.user.token, { path: '/', maxAge: days * 24 * 60 * 60 })

            if(json.user) dispatch({type: 'loginUser', user: json.user})

            const tabClients = json.user.habilitations.map(hab=>({...hab.client_hab, type_hab: hab.type_hab}))

            setClients(tabClients)
            dispatch({type: 'keepClient', clients: clients})    
            setClientSelected(tabClients[0])

            await fetchClient(tabClients[0]._id, tabClients[0].type_hab)
        } else {setErrors(json.error)}
    }

    // Déconnexion
    const deconnect = () => {
        dispatch({type: 'logoutUser', user: null})
        cookies.remove('token')
        setToken('')
    }

    //forgot password
    
        //Retirer input password et ajouter 'pour définir un nouveau mdp, entrez votre email' , générer un envoi mail create/profile sur la route users, if json.result afficher ok email de modification envoyé 

        const handleForgot = async () => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/users/forgot/${email}`)
            const json = await data.json()

             if ( json.result ) {
                successForgot()
             }
        }

    return (

        <>
            {!user ?

            <div className={showConnexion ? 'show' : 'hide'} style={{zIndex: 5}}>
                <div className='triangle'></div>

                {!forgot ?
                <CloseOutlined title='Fermer' style={{cursor: 'pointer', position: 'absolute', top: 10, right: 10}} onClick={()=>toggleShowConnexion()}/>
                :
                <CloseOutlined title='Annuler' onClick={()=>{setForgot(!forgot)}} style={{cursor: 'pointer', position: 'absolute', top: 10, right: 10}} />
                }
                    <div className='invisible'>
                    <Input placeholder="Entrer votre e-mail" prefix={<UserOutlined />} onChange={(e)=>setEmail(e.target.value)}/>
                    {!forgot ?
                    <>
                    <Input.Password
                    placeholder="Entrer votre mot de passe"
                    prefix={<LockOutlined />}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={(e)=>setPassword(e.target.value)}
                    />
                    <span className='mdp' onClick={()=>{setForgot(!forgot)}}style={{fontSize: 10, cursor: 'pointer', alignSelf: 'start', paddingLeft: 5}}>Mot de passe oublié ?</span>
                    </>
                    :
                    <>
                    <span onClick={()=>{setForgot(!forgot)}} style={{margin: '10px 0px', fontSize: 13, cursor: 'pointer', alignSelf: 'start', paddingLeft: 5}}>Entrez votre adresse mail pour recevoir un lien de modification de votre mot de passe...</span>                    
                    </>
                    }

                    {!forgot ?
                    <>
                    <div className='text-italic-inline' style={{margin: 0}}>Se souvenir de moi <ToggleSimple 
                        defaultValue={true}
                        toggleNum={toggleNum}
                        toggle={acceptCookies}
                        values={["", ""]}
                    /></div>
                    <Button texte={'Connexion'} onClick={()=>{handleSignIn(); toggleShowConnexion()}}/>
                    </>
                    :
                    <>
                    <Button texte={'Envoyer'} onClick={()=>{handleForgot(); setForgot(!forgot); toggleShowConnexion()}}/>
                    </>
                    }
                    </div>
            </div>
            
            :

            <div className={showConnexion ? 'show' : 'hide'} style={{zIndex: 5}}>
                <CloseOutlined style={{cursor: 'pointer', position: 'absolute', top: 10, right: 10}} onClick={()=>{toggleShowConnexion()}}/>

                <div className='invisible'>

                    {clients.length > 1 &&
                        <>
                        <span>Changer de structure :</span>
                        <Select placeholder={`${clientSelected?.raison_sociale} - ${clientSelected?.type_hab}`} style={{ width: '100%', marginBottom: 30 }}
                        onChange={(value)=>{
                            fetchClient(value, clients.find(e=>e._id === value).type_hab);
                            console.log('clients.find : ', value, clients.find(e=>e._id === value).type_hab)
                            }
                        }>
                            {
                                clients.map((cli, i)=>{return <Option key={i} id={i} value={cli._id}>{cli.raison_sociale} - {cli.type_hab}</Option>})
                            }
                        </Select> 
                        </>
                    }

                <span style={{textAlign: 'center'}} >{user.firstname}, désirez-vous vous déconnecter ? </span>
                <Link to='/'><Button texte={'Déconnexion'} onClick={()=>{deconnect(); toggleShowConnexion()}}/></Link>
               
                </div>
            </div>
            }





        
        </>
    )
    }
    export default Connexion;